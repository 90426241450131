import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import env from '@frontend/env';
import Axios from 'axios';

const axios = Axios.create();

const apiClient = feathers();
const restClient = rest(env.apiBaseUrl);

apiClient.configure(restClient.axios(axios));

export default apiClient;

export const authorizeApiClient = (accessToken: string) => {
  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  });
  /* eslint-enable no-param-reassign */
};
