import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import Link from '@frontend/components/Link';
import { spacings } from '@frontend/styles/variables';
import { History } from 'history';
import React, { MouseEventHandler, ReactNode } from 'react';
import { MenuItem, MenuItemProps } from 'reakit';

const style = css`
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.6rem;
  padding: 0.8rem ${spacings.md};

  &:last-child {
    border-bottom: 0;
  }

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  }
`;

type Props = {
  children: ReactNode;
  className?: string;
  to?: History.LocationDescriptor;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
};

const DropdownMenuItem = ({ className, children, to, ...props }: Props) => {
  const theme = useTheme();
  const themedStyle = css`
    ${style};
    border-bottom: 1px solid ${theme.border1};
    color: ${theme.link};

    &:hover,
    &:focus {
      background: ${theme.primary};
      color: ${theme.textContrast};
    }
  `;
  const menuItemStyle = cx(themedStyle, className);

  if (to) {
    return (
      <MenuItem
        {...(props as MenuItemProps)}
        as={Link}
        to={to}
        className={menuItemStyle}
      >
        {children}
      </MenuItem>
    );
  }

  return (
    <MenuItem {...(props as MenuItemProps)} className={menuItemStyle}>
      {children}
    </MenuItem>
  );
};

export default DropdownMenuItem;
