import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import ErrorMessage from '@frontend/components/ErrorMessage';
import { inputGradientStyle } from '@frontend/styles/gradients';
import { smallBoxShadow } from '@frontend/styles/shadows';
import { fonts, spacings } from '@frontend/styles/variables';
import React, { ReactNode, useCallback } from 'react';
import ReactSwitch from 'react-switch';

const fieldStyle = css`
  ${smallBoxShadow()};
  align-items: center;
  border-radius: 33px;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacings.md};
  padding: 0.8rem 1.2rem 0.6rem;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    font-family: ${fonts.heading};
    line-height: 1.3;
    padding-right: 0.2rem;
    width: 100%;
  }
`;

export interface FormToggleProps {
  checked: boolean;
  className?: string;
  disabled?: boolean;
  error?: string;
  id: string;
  label: string | ReactNode;
  name: string;
  onChange?: (checked: boolean, value: string, id: string) => void;
  value: string;
}

const FormToggle = ({
  checked,
  className,
  disabled,
  error,
  id,
  label,
  name,
  onChange,
  value,
}: FormToggleProps) => {
  const theme = useTheme();
  const themedFieldStyle = css`
    ${fieldStyle};
    background: ${theme.background};
    border: 1px solid ${theme.border};

    label {
      color: ${theme.text2};
    }
  `;

  const checkedStyle = css`
    ${inputGradientStyle(theme)};
    border-color: ${theme.secondary};

    label {
      color: ${theme.inputColor};
    }
  `;

  const errorStyle = css`
    border: 1px solid ${theme.error};
    margin-bottom: ${spacings.sm};
  `;

  const handleChange = useCallback(
    nextChecked => {
      if (onChange) {
        onChange(nextChecked, value, id);
      }
    },
    [id, onChange, value],
  );

  return (
    <>
      <div
        className={cx(
          themedFieldStyle,
          className,
          checked && checkedStyle,
          error && errorStyle,
        )}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={id}>{label}</label>

        <ReactSwitch
          activeBoxShadow={`0 0 2px 6px ${theme.primaryHighlight}`}
          checked={checked}
          disabled={disabled}
          handleDiameter={18}
          id={id}
          name={name}
          onColor={theme.primaryAlternate}
          offColor={theme.linkDisabled}
          width={60}
          value={value}
          onChange={handleChange}
        />
      </div>

      {error && <ErrorMessage id={`${id}-error`}>{error}</ErrorMessage>}
    </>
  );
};

export default FormToggle;
