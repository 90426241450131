import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@emotion/react';
import Tooltip from '@frontend/components/Tooltip';
import { themedFocusOutline } from '@frontend/styles/focus';
import spacingStyles from '@frontend/styles/spacings';
import { lighten } from 'polished';
import React, { forwardRef, ReactNode, Ref } from 'react';
import { FaEdit, FaTimes } from 'react-icons/fa';
import {
  Button,
  ButtonProps,
  TooltipReference,
  useTooltipState,
  VisuallyHidden,
} from 'reakit';
import { iconButtonStyle } from './DismissButton';

interface Props extends ButtonProps {
  colour?: keyof Theme;
  children: ReactNode;
  showText?: boolean;
}

const EditButton = (
  { colour = 'text1', className, children, showText = false, ...props }: Props,
  ref: Ref<HTMLButtonElement>,
) => {
  const tooltip = useTooltipState();
  const theme = useTheme();

  const themedStyle = css`
    ${iconButtonStyle};
    ${themedFocusOutline(theme)};
    color: ${theme[colour]};

    &:hover {
      color: ${lighten(0.1, theme[colour])};
    }
  `;

  return (
    <>
      {showText ? (
        <Button {...props} className={cx(themedStyle, className)} ref={ref}>
          <span className={spacingStyles.marginRight.xs}>{children}</span>

          <FaTimes
            className={css`
              font-size: 1.4rem;
            `}
          />
        </Button>
      ) : (
        <>
          <TooltipReference
            {...props}
            {...tooltip}
            as={Button}
            className={cx(themedStyle, className)}
            ref={ref}
          >
            <VisuallyHidden>{children}</VisuallyHidden>

            <FaEdit
              className={css`
                font-size: 1.4rem;
              `}
            />
          </TooltipReference>
          <Tooltip {...tooltip}>{children}</Tooltip>
        </>
      )}
    </>
  );
};

export default forwardRef<HTMLButtonElement, Props>(EditButton);
