import { ServiceResource } from '@common/types/apiResources';
import { SmhgFindParams } from '@common/types/smhg';
import { cx } from '@emotion/css';
import SortDropdown from '@frontend/components/SortDropdown';
import useAsyncRetry from '@frontend/hooks/useAsyncRetry';
import usePaginationQuery from '@frontend/hooks/usePaginationQuery';
import FavouriteAddCard from '@frontend/pages/favourites/components/FavouriteAddCard';
import FavouritesAddPage from '@frontend/pages/favourites/components/FavouritesAddPage';
import ServiceFiltersForm, {
  ServiceFiltersFormValues,
} from '@frontend/pages/favourites/components/ServiceFiltersForm';
import favouriteService from '@frontend/services/favouriteService';
import serviceService from '@frontend/services/serviceService';
import flexStyles from '@frontend/styles/flex';
import spacingStyles from '@frontend/styles/spacings';
import tryAsyncToast from '@frontend/utils/tryAsyncToast';
import produce from 'immer';
import React, { useCallback, useState } from 'react';

const FavouritesServicesPage = () => {
  const [filters, setFilters] = useState<ServiceFiltersFormValues>({
    searchTerm: '',
  });
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const { $limit, $skip } = usePaginationQuery({
    limit: 10,
  });

  const { searchTerm } = filters;

  const {
    value: paginated,
    isLoading,
    setValue: setPaginated,
  } = useAsyncRetry(() => {
    const params: SmhgFindParams = {};

    if (searchTerm) {
      params.search = searchTerm;
    }

    params.page = $skip === 0 ? 1 : $skip / $limit + 1;
    // eslint-disable-next-line @typescript-eslint/camelcase
    params.per_page = $limit;

    return serviceService.find({ query: params });
  }, [$limit, $skip, searchTerm, sortDirection]);

  const handleAdd = useCallback(
    async (service: ServiceResource) => {
      if (!paginated) {
        return;
      }

      await tryAsyncToast(
        async () => {
          const favourite = await favouriteService.create({
            type: 'service',
            typeId: service.id,
          });

          setPaginated(
            produce(paginated, draft => {
              const number = draft.data.findIndex(
                item => item.id === favourite.typeId,
              );
              draft.data[number].favouriteId = favourite.id;
            }),
          );
        },
        {
          success: (
            <strong>
              Service added to favourites.
              <br />
              This can now be set as a goal.
            </strong>
          ),
          error: 'Could not add to favourites',
        },
      );
    },
    [paginated, setPaginated],
  );

  const handleDelete = useCallback(
    async (service: ServiceResource) => {
      if (!paginated) {
        return;
      }

      await tryAsyncToast(
        async () => {
          if (!service.favouriteId) {
            return;
          }

          await favouriteService.remove(service.favouriteId);

          setPaginated(
            produce(paginated, draft => {
              draft.data[
                draft.data.findIndex(
                  item => item.favouriteId === service.favouriteId,
                )
              ].favouriteId = undefined;
            }),
          );
        },
        {
          success: 'Service deleted from favourites',
          error: 'Could not delete from favourites',
        },
      );
    },
    [paginated, setPaginated],
  );

  return (
    <FavouritesAddPage<ServiceResource>
      title="Add favourite services"
      emptyText="No services found"
      addCard={item => (
        <FavouriteAddCard
          resource={item}
          onAdd={handleAdd}
          onDelete={handleDelete}
        >
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: item.excerpt,
            }}
          />
        </FavouriteAddCard>
      )}
      filterForm={<ServiceFiltersForm onSubmit={setFilters} />}
      sortDropdown={
        <div className={cx(flexStyles.flex, flexStyles.justifyEnd)}>
          <SortDropdown
            aria-label="Sort services"
            className={spacingStyles.marginBottom.md}
            options={[
              {
                value: 'asc',
                label: 'Sort by title (A - Z)',
              },
              {
                value: 'desc',
                label: 'Sort by title (Z - A)',
              },
            ]}
            onChange={option => {
              setSortDirection(option.value as 'asc' | 'desc');
            }}
          />
        </div>
      }
      paginated={paginated}
      loading={isLoading}
    />
  );
};

export default FavouritesServicesPage;
