import { EventResource } from '@common/types/apiResources';
import { isRequired } from '@common/validations';
import validateFields from '@common/validations/validateFields';
import FormField from '@frontend/components/form/field/FormField';
import FormFieldDateTime from '@frontend/components/form/field/FormFieldDateTime';
import FormGroup from '@frontend/components/form/FormGroup';
import FormInput from '@frontend/components/form/FormInput';
import FormRadios from '@frontend/components/form/FormRadios';
import FormTime from '@frontend/components/form/FormTime';
import { Form, Formik } from 'formik';
import React, { ReactNode } from 'react';
import RRule, { Frequency } from 'rrule';

const required = isRequired();

interface CalendarEventFormValues {
  title: string;
  startDate: Date;
  endDate: Date;
  startTime: string;
  endTime: string;
  recurrence: 'daily' | 'weekly' | 'monthly' | '';
}

interface Props {
  buttons: ReactNode;
  id: string;
  initialValues?: EventResource;
  onSubmit: (value: CalendarEventFormValues) => void;
}

// Touch everything as the modal will immediately
// focus the first input meaning it will be touched
// and cause some weird behaviour when submitting.
const initialTouched: { [key in keyof CalendarEventFormValues]: boolean } = {
  title: true,
  startDate: true,
  endDate: true,
  startTime: true,
  endTime: true,
  recurrence: true,
};

const recurrenceMap: { [key in Frequency]?: string } = {
  [Frequency.DAILY]: 'daily',
  [Frequency.WEEKLY]: 'weekly',
  [Frequency.MONTHLY]: 'monthly',
};

const CalendarEventForm = ({ buttons, id, initialValues, onSubmit }: Props) => {
  const {
    title = '',
    startDate = null,
    endDate = null,
    startTime = '',
    endTime = '',
    rrule = '',
  } = (initialValues || {}) as Partial<EventResource>;

  return (
    <Formik
      enableReinitialize
      initialValues={
        {
          title,
          startTime,
          endTime,
          startDate: startDate ? new Date(startDate) : null,
          endDate: endDate ? new Date(endDate) : null,
          recurrence: rrule
            ? recurrenceMap[RRule.fromString(rrule).options.freq]
            : '',
        } as CalendarEventFormValues
      }
      onSubmit={onSubmit}
      initialTouched={initialTouched}
      validate={values =>
        validateFields<CalendarEventFormValues>({
          title: required,
          startDate: required,
          endDate: value => {
            if (!value) {
              return required(value);
            }

            if (!values.startDate) {
              return '';
            }

            return value < values.startDate
              ? 'End date must be after or same as the start date'
              : '';
          },
        })(values)
      }
    >
      <Form>
        <FormGroup>
          <FormField
            as={FormInput}
            id={`${id}-title`}
            name="title"
            label="Title"
            labelSize="xs"
            placeholder="e.g. Clean my room"
          />

          <FormFieldDateTime
            id={`${id}-startDate`}
            dateFormat="EEEE do MMMM yyyy"
            label="Start date"
            labelSize="xs"
            name="startDate"
            showTime={false}
          />

          <FormFieldDateTime
            id={`${id}-endDate`}
            dateFormat="EEEE do MMMM yyyy"
            label="End date"
            labelSize="xs"
            name="endDate"
            showTime={false}
          />

          <FormField
            as={FormTime}
            type="select"
            id={`${id}-startTime`}
            label="Start time"
            labelSize="xs"
            name="startTime"
          />

          <FormField
            as={FormTime}
            type="select"
            id={`${id}-endTime`}
            label="End time"
            labelSize="xs"
            name="endTime"
          />

          <FormField
            as={FormRadios}
            id={`${id}-recurrence`}
            name="recurrence"
            label="Repeats?"
            labelSize="xs"
            type="radio"
            options={[
              {
                value: '',
                label: 'Does not repeat',
              },
              {
                value: 'daily',
                label: 'Every day',
              },
              {
                value: 'weekly',
                label: 'Every week',
              },
              {
                value: 'monthly',
                label: 'Every month',
              },
            ]}
          />
        </FormGroup>

        {buttons}
      </Form>
    </Formik>
  );
};

export default CalendarEventForm;
