import { isRequired } from '@common/validations';
import passwordRuleset from '@common/validations/rulesets/passwordRuleset';
import validateFields from '@common/validations/validateFields';
import Button from '@frontend/components/Button';
import FormField from '@frontend/components/form/field/FormField';
import FormGroup from '@frontend/components/form/FormGroup';
import FormInput from '@frontend/components/form/FormInput';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import useFormSubmit from '@frontend/hooks/useFormSubmit';
import flexStyles from '@frontend/styles/flex';
import headingStyles from '@frontend/styles/headings';
import { cx } from '@emotion/css';
import { Form, Formik } from 'formik';
import React from 'react';
import FormFieldToggle from '@frontend/components/form/field/FormFieldToggle';

const required = isRequired();

interface FormValues {
  firstName: string;
  lastName: string;
  allowMailingList: boolean;
  password: string;
  passwordConfirm: string;
}

interface Props {
  initialValues?: {
    firstName: string;
    lastName: string;
    allowMailingList?: boolean;
  };
  onSubmit: (values: FormValues) => void;
}

const AccountForm = ({
  initialValues = {
    firstName: '',
    lastName: '',
    allowMailingList: false,
  },
  onSubmit,
}: Props) => {
  const { fieldErrors, handleSubmit, isSubmitting } = useFormSubmit(onSubmit, {
    success: 'Account details saved',
    error: 'Could not save account',
  });

  return (
    <Formik<FormValues>
      initialValues={{
        firstName: initialValues?.firstName,
        lastName: initialValues?.lastName,
        allowMailingList: initialValues?.allowMailingList === true,
        password: '',
        passwordConfirm: '',
      }}
      initialErrors={fieldErrors}
      validate={values =>
        validateFields({
          firstName: required,
          lastName: required,
          password: values.password ? passwordRuleset() : () => '',
          passwordConfirm: value => {
            if (!values.password) {
              return '';
            }

            return values.password !== value ? 'Passwords do not match' : '';
          },
        })(values)
      }
      onSubmit={handleSubmit}
    >
      <Form>
        <div className={cx(flexStyles.flex, flexStyles.justifySpaceBetween)}>
          <h1 className={headingStyles.md}>Change account details</h1>

          <div>{isSubmitting && <LoadingSpinner />}</div>
        </div>

        <FormGroup>
          <FormField
            name="firstName"
            as={FormInput}
            id="accountForm-firstName"
            label="First name"
            labelSize="xs"
          />

          <FormField
            name="lastName"
            as={FormInput}
            id="accountForm-lastName"
            label="Last name"
            labelSize="xs"
          />

          <FormField
            name="password"
            as={FormInput}
            type="password"
            id="accountForm-password"
            label="Password"
            labelSize="xs"
          />

          <FormField
            name="passwordConfirm"
            as={FormInput}
            type="password"
            id="accountForm-passwordConfirm"
            label="Confirm password"
            labelSize="xs"
          />

          <FormField
            name="allowMailingList"
            as={FormFieldToggle}
            id="accountForm-allowMailingList"
            label="Receive Mailing list emails"
            type="checkbox"
          />
        </FormGroup>

        <Button type="submit">Save account</Button>
      </Form>
    </Formik>
  );
};

export default AccountForm;
