import { spacings } from '@frontend/styles/variables';
import { css, cx } from '@emotion/css';
import { linearGradient } from 'polished';
import React, { ReactNode } from 'react';
import { useTheme } from '@emotion/react';

const listStyle = css`
  list-style: none;
  padding-left: 0;
  position: relative;
`;

const itemStyle = css`
  align-items: center;
  display: flex;
  margin-bottom: ${spacings.md};
  overflow-wrap: anywhere;
  padding-left: 3.4rem;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    border-radius: 50%;
    content: '';
    height: 2.5rem;
    left: 0;
    margin-right: ${spacings.sm};
    position: absolute;
    width: 2.5rem;
  }
`;

const completedStyle = css`
  &::before {
    border: 0;
    height: 2.5rem;
    width: 2.5rem;
  }
`;

interface Props {
  emptyText?: string;
  goals: {
    id: string;
    completedAt: string | null;
    name: ReactNode | string;
  }[];
}

const GoalList = ({ goals, emptyText = 'You have no goals yet.' }: Props) => {
  const theme = useTheme();
  const themedItemStyle = css`
    ${itemStyle}
    &::before {
      border: 6px solid ${theme.secondary};
    }
  `;
  const themedCompletedStyle = css`
    ${completedStyle}
    &::before {
      ${linearGradient({
        colorStops: [
          `${theme.secondary} 0%`,
          `${theme.secondaryHighlight} 100%`,
        ],
        toDirection: '316deg',
        fallback: theme.secondary,
      })};
    }
  `;

  return goals.length > 0 ? (
    <ul className={listStyle}>
      {goals.map(goal => (
        <li
          key={goal.id}
          className={cx(
            themedItemStyle,
            goal.completedAt && themedCompletedStyle,
          )}
        >
          {goal.name}
        </li>
      ))}
    </ul>
  ) : (
    <p>{emptyText}</p>
  );
};

export default GoalList;
