import { useMaxWidthMedia } from '@frontend/hooks/useMedia';
import flexStyles from '@frontend/styles/flex';
import { widthStyles } from '@frontend/styles/utils';
import { css, cx } from '@emotion/css';
import React, { ReactNode } from 'react';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useTheme } from '@emotion/react';
import { omit } from 'lodash';

const carouselStyle = css`
  .slick-dots {
    bottom: 3px !important;
    li {
      width: 4rem;
      button {
        width: 100%;
        &::before {
          content: '' !important;
        }
      }
    }
  }
  .slick-slider {
    width: 100%;
  }

  .slick-prev {
    left: -10px;
  }

  .slick-next {
    right: -10px;
  }

  .slick-prev,
  .slick-next {
    height: 40px;
    width: 40px;
    z-index: 999;
  }
`;

const sliderDots = css`
  ${flexStyles.flexCenterAll};
  justify-content: space-evenly;
  padding: 0;
`;

const SlickWrapArrow = ({
  component: Component,
  className,
  ...props
}: {
  className?: string;
  component: React.ComponentType<JSX.IntrinsicElements['svg']>;
}) => {
  const disabled = className?.includes('slick-disabled');

  const cssVisibility = disabled
    ? css`
        display: none;
      `
    : '';

  return (
    <Component
      {...omit(props, ['currentSlide', 'slideCount', 'slidesToShow'])}
      aria-hidden={disabled}
      aria-disabled={disabled}
      className={cx(className, cssVisibility)}
      style={{ display: disabled ? 'none' : '' }}
    />
  );
};

const defaultSliderSettings: Settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SlickWrapArrow component={FaChevronCircleRight} />,
  prevArrow: <SlickWrapArrow component={FaChevronCircleLeft} />,
  // eslint-disable-next-line react/display-name
  appendDots: (dots: ReactNode) => (
    <div className={widthStyles.full}>
      <ul className={sliderDots}>{dots}</ul>
    </div>
  ),
};

interface Props {
  children: ReactNode;
  className?: string;
  alwaysShowSlider?: boolean;
  sliderSettings?: Partial<Settings>;
}

const ResponsiveCarousel = ({
  children,
  className,
  alwaysShowSlider,
  sliderSettings,
}: Props) => {
  const theme = useTheme();
  const themedCarouselStyle = css`
    ${carouselStyle}
    .slick-dots {
      li {
        border-bottom: 2px solid ${theme.secondary};
      }
      .slick-active {
        border-bottom: 2px solid ${theme.primary} !important;
      }
    }

    .slick-prev,
    .slick-next {
      color: ${theme.primary};
    }

    .slick-prev:hover,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
      color: ${theme.primary};
    }
  `;

  const finalSliderSettings = {
    ...defaultSliderSettings,
    ...(sliderSettings ?? {}),
  };

  const mediaMatches = useMaxWidthMedia('tablet');

  const showSlider = alwaysShowSlider || mediaMatches;

  return (
    <>
      {showSlider ? (
        <div className={cx(widthStyles.full, themedCarouselStyle, className)}>
          <Slider {...finalSliderSettings}>{children}</Slider>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default ResponsiveCarousel;
