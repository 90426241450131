import React from 'react';

const EasyReadKeyItemLogo = ({ size }: { size?: string }) => {
  return (
    <svg
      stroke="none"
      fill="currentColor"
      width={size}
      height={size}
      viewBox="4 4 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M33.557 37.9386C30.9893 37.8288 28.5315 38.1293 26.1778 39.0423C25.8424 39.1753 25.5127 39.3313 25.2005 39.6723C26.0217 39.6723 26.8429 39.6723 27.664 39.6723C29.237 39.6723 30.8043 39.6723 32.3773 39.6723C33.9676 39.6723 34.5343 39.1175 34.5343 37.5629C34.5343 33.5119 34.5343 29.4666 34.5343 25.4155C34.5343 25.1959 34.4707 24.9474 34.5575 24.7625C34.6558 24.5544 34.8871 24.2655 35.0606 24.2655C35.2457 24.2655 35.5637 24.5487 35.5869 24.7336C35.6563 25.323 35.6216 25.9241 35.6216 26.5193C35.6216 30.6339 35.6216 34.7428 35.6216 38.8574C35.6216 40.3831 35.0548 40.9494 33.5281 40.9494C30.9199 40.9494 28.3118 40.9263 25.7094 40.9725C25.3046 40.9783 24.813 41.1863 24.5065 41.458C23.4598 42.3942 22.0776 42.3884 21.0424 41.4348C20.7648 41.1748 20.3138 40.9725 19.9379 40.9667C17.2661 40.9263 14.6001 40.9494 11.9283 40.9436C10.4941 40.9436 10.0199 40.4698 10.0199 39.0366C10.0199 34.4249 10.0199 29.8075 10.0257 25.1959C10.0257 24.8029 9.81748 24.2308 10.5519 24.2308C11.2575 24.2308 11.1071 24.774 11.1071 25.1901C11.1129 29.3337 11.1071 33.4772 11.1071 37.6207C11.1071 39.0481 11.7317 39.6723 13.1601 39.678C15.5774 39.678 17.9947 39.678 20.4294 39.5914C17.8444 38.1928 15.028 37.8634 12.0844 37.927V22.3873C15.161 22.1446 18.1393 22.5376 20.8111 23.9708C22.2916 24.7625 23.3325 24.774 24.813 23.9708C27.4848 22.526 30.4688 22.1619 33.557 22.37V37.9386ZM23.24 39.4411C26.253 37.6727 29.3816 37.0139 32.6433 36.9908V23.2368C29.3816 23.1733 26.4091 23.8956 23.6911 25.5773C23.4713 25.716 23.2631 26.0743 23.2631 26.3286C23.2342 30.6224 23.2458 34.9162 23.2458 39.4411H23.24ZM12.9808 37.0255C16.3119 36.985 19.3596 37.7247 22.2048 39.314C22.2684 39.1406 22.3205 39.0597 22.3205 38.973C22.3263 34.737 22.3436 30.501 22.3147 26.2708C22.3147 26.005 22.0429 25.6236 21.7942 25.5022C20.6839 24.9532 19.562 24.3984 18.388 23.9997C16.6531 23.4044 14.8545 23.0981 12.9808 23.2946V37.0313V37.0255Z" />
      <path d="M21.2853 8.15369C22.3552 6.8014 23.3325 5.38555 24.5007 4.15463C25.611 2.98728 26.9238 1.99907 28.1614 0.953074C28.5257 0.646788 29.0462 0.484977 29.2544 1.02242C29.3816 1.34605 29.2544 2.02219 29.0057 2.19556C26.0043 4.27021 23.7836 6.99789 22.0718 10.1821C21.7364 10.8005 21.0308 10.8814 20.6376 10.3266C19.9031 9.28637 19.2034 8.22303 18.5673 7.12503C18.4458 6.91698 18.5673 6.40843 18.7523 6.22351C18.9085 6.06747 19.4694 6.0328 19.614 6.17727C20.2212 6.78407 20.7417 7.48332 21.2911 8.14791L21.2853 8.15369Z" />
      <path d="M27.5137 17.6832C26.7792 17.9144 26.2877 17.7583 25.9291 17.088C25.6631 16.5852 25.3682 15.9784 24.5354 16.3656C24.5123 16.7701 24.4891 17.2036 24.4602 17.7005H23.454V12.9213C24.3272 12.9213 25.2005 12.7769 25.9927 12.9676C26.5421 13.1005 27.1783 13.5975 27.4154 14.1002C27.7855 14.892 27.3055 15.5854 26.5132 16.0709C26.8544 16.6199 27.1783 17.1342 27.5137 17.6774V17.6832ZM24.5296 15.5334C25.2352 15.3023 25.8019 15.152 26.3224 14.9093C26.4033 14.8688 26.3744 14.2389 26.2646 14.1927C25.7383 13.9789 25.1658 13.8691 24.5238 13.7015V15.5334H24.5296Z" />
      <path d="M39.4789 12.7595H40.4678V17.6659C39.768 17.7121 39.103 17.7352 38.4379 17.7988C37.6572 17.8681 37.102 17.5561 36.7955 16.8453C36.4659 16.0824 36.5006 15.3312 37.0153 14.6435C37.4953 14.002 38.1546 13.9442 38.8659 14.1118C39.0394 14.1523 39.1955 14.2621 39.4037 14.3603C39.4268 13.7882 39.45 13.2739 39.4789 12.7595ZM39.6119 15.701C39.2765 15.4352 38.97 15.0018 38.5998 14.9382C37.9753 14.8342 37.5647 15.3196 37.7093 15.9206C37.8018 16.3136 38.195 16.8626 38.5305 16.9262C39.1088 17.0302 39.4731 16.5679 39.6177 15.701H39.6119Z" />
      <path d="M4.95392 17.7179V12.9849H8.41798C8.43533 13.2103 8.45268 13.4645 8.48159 13.8113H5.92548C5.90234 14.1522 5.88499 14.4181 5.85608 14.7879H8.20979C8.23292 15.1405 8.25605 15.3947 8.27919 15.7704C7.70666 15.7704 7.2151 15.7993 6.72354 15.7646C5.93126 15.701 5.69994 16.0362 5.96596 16.8799C6.57896 16.8799 7.23245 16.903 7.88015 16.8741C8.50473 16.8453 8.67822 17.0938 8.46424 17.7179H4.95392Z" />
      <path d="M31.4751 17.0129C30.7059 17.9202 29.6072 18.03 28.8958 17.5214C28.1672 17.0013 27.9301 15.9669 28.3291 15.0827C28.7166 14.2158 29.4915 13.8575 30.5093 14.0656C31.3247 14.2332 31.7642 14.9787 31.7642 16.2847H29.1676C29.4973 17.0533 29.931 17.1573 30.5614 16.9782C30.839 16.8973 31.1686 16.9955 31.4751 17.0129ZM30.6539 15.5681L30.7985 15.2965C30.5151 15.1289 30.2259 14.8284 29.9426 14.8342C29.6823 14.8342 29.4279 15.1694 29.1734 15.3543C29.2139 15.4236 29.2544 15.493 29.2949 15.5681H30.6597H30.6539Z" />
      <path d="M32.6722 14.84C32.9382 14.5395 33.1059 14.1523 33.3373 14.1118C34.6384 13.898 35.9339 14.002 35.8182 16.0015C35.7835 16.5621 35.8182 17.1227 35.8182 17.6774C34.997 17.7179 34.2336 17.7815 33.4645 17.7757C32.7936 17.7757 32.4582 17.4058 32.4467 16.7124C32.4351 16.0073 32.8226 15.6721 33.4356 15.5739C33.8577 15.5045 34.2972 15.5623 34.8409 15.5623C34.384 14.5163 33.5281 15.1578 32.6838 14.8342L32.6722 14.84ZM34.783 16.1287C34.3377 16.2385 33.8982 16.3425 33.4529 16.4523C33.4992 16.6372 33.4876 16.932 33.5975 16.9897C34.1816 17.3018 34.6038 17.088 34.783 16.1287Z" />
      <path d="M16.7514 18.4923C17.3297 18.2149 17.6998 18.0415 18.1393 17.8335C17.6882 16.643 17.2198 15.4063 16.7167 14.0945C18.6309 13.8633 17.8212 15.7473 18.6598 16.2905C19.4463 15.7877 18.6424 13.8922 20.5335 14.0713C19.9957 15.5045 19.5157 16.8973 18.9489 18.2496C18.6309 19.0066 17.96 19.0991 16.7514 18.4923Z" />
      <path d="M12.6165 17.6832C11.83 17.7237 11.1186 17.7872 10.4073 17.793C9.71336 17.7988 9.28541 17.4867 9.26806 16.7297C9.24493 15.9726 9.69601 15.6606 10.3495 15.5739C10.7485 15.5219 11.1591 15.5623 11.6102 15.5623C11.4772 14.7417 10.8526 15.0307 10.3958 14.9382C10.1413 14.8862 9.9042 14.7244 9.66131 14.6146C9.89263 14.4296 10.1066 14.1118 10.3553 14.0771C11.7374 13.9038 12.79 14.21 12.628 16.0246C12.5818 16.5794 12.6222 17.1458 12.6222 17.6774L12.6165 17.6832ZM11.7201 16.4118C11.1476 15.9842 10.6618 15.9669 10.2859 16.5101C10.2223 16.6025 10.3495 16.9724 10.4709 17.0244C11.0492 17.2556 11.4945 17.0995 11.7201 16.4118Z" />
      <path d="M16.1789 14.7417C15.8781 14.7937 15.5832 14.8515 15.2825 14.892C15.0338 14.9209 14.7794 14.9266 14.5249 14.944C14.71 15.1405 14.8545 15.4236 15.0801 15.5219C16.3697 16.0709 16.8092 17.0302 15.9186 17.4983C15.3634 17.7872 14.5596 17.6139 13.8714 17.6081C13.7153 17.6081 13.5591 17.4289 13.4146 17.3423C13.4955 16.7933 13.7211 16.6025 14.23 16.8857C14.415 16.9897 14.6406 17.0533 14.8488 17.0475C14.9991 17.0475 15.1495 16.9088 15.2998 16.8337C15.2015 16.7066 15.1263 16.5274 14.9991 16.4581C14.7562 16.3309 14.3861 16.3367 14.23 16.1518C13.9408 15.805 13.536 15.3254 13.5996 14.9729C13.6632 14.6204 14.1895 14.1869 14.5885 14.0887C15.0512 13.9731 15.5948 14.1812 16.1037 14.2447C16.1268 14.4065 16.1499 14.5683 16.1789 14.7359V14.7417Z" />
      <path d="M32.1113 34.1129C31.9089 34.2573 31.718 34.5001 31.504 34.5232C29.3007 34.7543 27.1609 35.2051 25.1658 36.2338C25.027 36.3031 24.7725 36.1528 24.3388 36.043C26.8429 34.5347 29.3816 34.188 31.9089 33.8239C31.9782 33.9222 32.0419 34.0146 32.1113 34.1129Z" />
      <path d="M24.5817 30.1543C26.8718 28.8424 29.3816 28.3743 31.9609 28.1547C32.0014 28.2414 32.0418 28.3339 32.0823 28.4206C31.9204 28.5188 31.7758 28.6691 31.5965 28.7095C30.0351 29.0736 28.4621 29.3972 26.9065 29.7902C26.3744 29.9231 25.8829 30.2236 25.3566 30.4085C25.1253 30.4894 24.8535 30.4663 24.599 30.4894C24.599 30.3796 24.5874 30.2698 24.5817 30.16V30.1543Z" />
      <path d="M32.0592 31.2292C31.9724 31.3332 31.8973 31.5066 31.7989 31.5239C29.4105 31.8995 27.0742 32.4428 24.8361 33.3905C24.7899 33.4078 24.6684 33.246 24.4891 33.0842C26.8486 31.6857 29.3874 31.287 31.9551 30.9865C31.9898 31.0674 32.0245 31.1483 32.0592 31.2292Z" />
      <path d="M31.9262 25.5947C29.5378 26.2766 27.132 26.9643 24.7147 27.6404C24.6858 27.6462 24.6164 27.5017 24.5643 27.4266C26.2703 26.0339 31.5734 24.8376 31.9262 25.5947Z" />
      <path d="M14.4439 30.813C16.4506 31.4314 18.4863 32.0498 20.5104 32.7143C20.7243 32.7837 20.8573 33.1073 21.025 33.3096C20.759 33.2807 20.4872 33.2865 20.2385 33.2171C18.4169 32.7259 16.601 32.2173 14.7851 31.7261C14.6058 31.6799 14.3976 31.7261 14.2357 31.651C14.0622 31.5759 13.9292 31.4141 13.7731 31.2927C13.9466 31.1713 14.1201 31.05 14.4497 30.8188L14.4439 30.813Z" />
      <path d="M14.3398 33.7083C16.2772 34.2573 18.2145 34.8006 20.1403 35.3611C20.3774 35.4305 20.6145 35.5518 20.7938 35.7079C20.921 35.8177 20.9499 36.0373 21.0193 36.2106C20.8574 36.2222 20.6665 36.3031 20.5451 36.2395C18.5557 35.2109 16.4218 34.7601 14.2184 34.5232C14.0623 34.5058 13.9292 34.24 13.7847 34.0955C13.935 33.9915 14.0854 33.8875 14.3398 33.7141V33.7083Z" />
      <path d="M21.1003 27.5595C20.9094 27.5884 20.7013 27.6866 20.5335 27.6346C18.2955 26.9527 16.069 26.2477 13.8425 25.5484C13.8772 25.5311 14.0739 25.3461 14.2531 25.3577C16.6473 25.5715 18.9663 26.0685 21.0772 27.259C21.0829 27.3572 21.0887 27.4555 21.1003 27.5537V27.5595Z" />
      <path d="M21.1003 30.3912C20.9094 30.4259 20.6665 30.553 20.5335 30.4837C18.5094 29.4319 16.2887 29.1025 14.0854 28.7153C13.9697 28.6979 13.8656 28.6517 13.7384 28.617C14.282 27.7964 18.8738 28.7904 21.1003 30.397V30.3912Z" />
    </svg>
  );
};

export default EasyReadKeyItemLogo;
