import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import DropdownMenuItem from '@frontend/components/DropdownMenuItem';
import { useThemeContext } from '@frontend/context/ThemeContext';
import { smallBoxShadow } from '@frontend/styles/shadows';
import { spacings } from '@frontend/styles/variables';
import themes from '@frontend/themes';
import { startCase } from 'lodash';
import React, { forwardRef, Ref } from 'react';
import { Menu, MenuButton, MenuProps, useMenuState } from 'reakit';

const menuStyle = css`
  ${smallBoxShadow()};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-right: ${spacings.md};
  margin-top: ${spacings.xs};
  outline: 0;
  z-index: 10;
`;

const menuItemStyle = css`
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.6rem;
  padding: 0.8rem ${spacings.md};

  &:last-child {
    border-bottom: 0;
  }

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  }
`;

const ThemeMenu = (props: MenuProps, ref: Ref<HTMLButtonElement>) => {
  const menu = useMenuState();
  const theme = useTheme();

  const themedMenuStyle = css`
    ${menuStyle};
    background: ${theme.background};
    border: 1px solid ${theme.border};
  `;

  const themedMenuItemStyle = css`
    ${menuItemStyle};
    border-bottom: 1px solid ${theme.border1};
    color: ${theme.link};

    &:hover,
    &:focus {
      background: ${theme.primary};
      color: ${theme.textContrast};
    }
  `;

  const { setThemeKey } = useThemeContext();

  return (
    <>
      <MenuButton
        {...menu}
        {...props}
        ref={ref}
        className={themedMenuItemStyle}
      >
        Theme
      </MenuButton>
      <Menu {...menu} className={themedMenuStyle}>
        {Object.keys(themes).map(themeKey => (
          <DropdownMenuItem
            key={themeKey}
            {...menu}
            onClick={() => {
              setThemeKey(themeKey);
            }}
          >
            {startCase(themeKey)}
          </DropdownMenuItem>
        ))}
      </Menu>
    </>
  );
};

export default forwardRef(ThemeMenu);
