import { css } from '@emotion/css';
import { Theme, useTheme } from '@emotion/react';
import { spacings } from '@frontend/styles/variables';
import React, { ReactNode } from 'react';

const timelineStyle = css`
  background: none;
  list-style: none;
  margin-bottom: ${spacings.lg};
  margin-left: 1.5rem;
  padding-left: 0;
`;

interface TimelineProps {
  children: ReactNode;
  lineColour?: keyof Theme;
}

const Timeline = ({
  children,
  lineColour = 'primaryHighlight',
}: TimelineProps) => {
  const theme = useTheme();
  const themedTimelineStyle = css`
    ${timelineStyle};
    border-left: 2px solid ${theme[lineColour]};
  `;

  return <ol className={themedTimelineStyle}>{children}</ol>;
};

export default Timeline;
