import React, { useState } from 'react';
import { css, cx } from '@emotion/css';
import headingStyles from '@frontend/styles/headings';
import spacingStyles from '@frontend/styles/spacings';
import { FaChevronDown, FaChevronUp } from 'react-icons/all';
import { breakpoints, spacings } from '@frontend/styles/variables';
import { noButtonStyle } from '@frontend/styles/buttons';

const headingContainerStyle = css`
  align-items: center;
  display: flex;
  margin-bottom: ${spacings.md};

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    text-align: center;
  }
`;

const hide = css`
  display: none;
`;

const collapse = css`
  margin-right: ${spacings.sm};
`;

const extNoButtonStyle = cx(
  noButtonStyle,
  css`
    cursor: default;
    line-height: inherit;
    padding: 0;
  `,
);

interface CollapseProps {
  title: string;
  header?: string | React.ReactNode;
  children: React.ReactNode | ((collapsed: boolean) => React.ReactNode);
  collapsed?: boolean;
}

const Collapse = ({
  title,
  header,
  children,
  collapsed: initialState = true,
}: CollapseProps) => {
  const [collapsed, setCollapsed] = useState(initialState);

  return (
    <>
      <div className={headingContainerStyle}>
        <button
          className={cx(
            extNoButtonStyle,
            css`
              cursor: pointer;
            `,
          )}
          type="button"
          onClick={() => setCollapsed(!collapsed)}
        >
          <h2 className={cx(headingStyles.md, spacingStyles.marginBottom.none)}>
            <span className={collapse}>
              <FaChevronDown className={cx(collapsed ? '' : hide)} />
              <FaChevronUp className={cx(collapsed ? hide : '')} />
            </span>
            {title}
          </h2>
        </button>

        {header && !collapsed ? header : <></>}
      </div>

      {typeof children === 'function' ? children(collapsed) : { children }}
    </>
  );
};

export default Collapse;
