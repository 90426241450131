import ErrorMessage from '@frontend/components/ErrorMessage';
import FormSelect, { SelectOption } from '@frontend/components/form/FormSelect';
import { headingSizes } from '@frontend/styles/variables';
import { addMinutes, format, startOfDay } from 'date-fns/esm';
import range from 'lodash/range';
import React, { ChangeEventHandler, FocusEventHandler, useMemo } from 'react';

const startTime = startOfDay(new Date());

export interface FormTimeProps {
  className?: string;
  disabled?: boolean;
  error?: string;
  id: string;
  label: string;
  labelSize?: keyof typeof headingSizes;
  name: string;
  placeholder?: string;
  showLabel?: boolean;
  showTime?: boolean;
  timeFormat?: string;
  timeInterval?: number;
  value: string;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  onChange: ChangeEventHandler<HTMLSelectElement>;
}

const FormTime = ({
  className,
  error,
  id,
  label,
  labelSize,
  name,
  placeholder = 'e.g. 13:00',
  showLabel = true,
  timeFormat = 'HH:mm',
  timeInterval = 15,
  value,
  onBlur,
  onChange,
}: FormTimeProps) => {
  const options: SelectOption[] = useMemo(() => {
    return range(0, 1440, timeInterval).map(timeInMinutes => {
      const time = format(addMinutes(startTime, timeInMinutes), timeFormat);

      return {
        value: time,
        label: time,
      };
    });
  }, [timeFormat, timeInterval]);

  return (
    <>
      <FormSelect
        className={className}
        id={id}
        label={label}
        labelSize={labelSize}
        name={name}
        options={options}
        placeholder={placeholder}
        showLabel={showLabel}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />

      {error && <ErrorMessage id={`${id}-error`}>{error}</ErrorMessage>}
    </>
  );
};

export default FormTime;
