import { GoalResource } from '@common/types/apiResources';
import { Paginated } from '@feathersjs/feathers';
import { useAuth0Context } from '@frontend/context/Auth0Context';
import useAsyncRetry from '@frontend/hooks/useAsyncRetry';
import goalService from '@frontend/services/goalService';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';

export interface CompletedGoalsState {
  completedGoals: number;
  isLoading: boolean;
  refresh: () => void;
}

const CompletedGoalsContext = createContext<CompletedGoalsState | undefined>(
  undefined,
);

interface Props {
  children: ReactNode;
}

export const CompletedGoalsContextProvider = ({ children }: Props) => {
  const { isAuthenticated } = useAuth0Context();
  const { value: completedGoals, isLoading, retry } = useAsyncRetry<
    Paginated<GoalResource> | undefined
  >(async () => {
    if (!isAuthenticated) {
      return undefined;
    }

    return goalService.find({
      query: {
        completedAt: {
          $ne: 'null',
        },
        $limit: 0,
      },
    });
  }, []);

  const state: CompletedGoalsState = useMemo(() => {
    return {
      completedGoals: completedGoals?.total ?? 0,
      isLoading,
      refresh: retry,
    };
  }, [completedGoals, isLoading, retry]);

  return (
    <CompletedGoalsContext.Provider value={state}>
      {children}
    </CompletedGoalsContext.Provider>
  );
};

export function useCompletedGoalsContext() {
  const context = useContext(CompletedGoalsContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'Must have a CompletedGoalsContextProvider further up the component tree',
    );
  }

  return context;
}
