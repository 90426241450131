import { Theme } from '@emotion/react';
import { darken, lighten } from 'polished';
import { baseColours } from '../styles/variables';

const themeColours = {
  purpleDark: '#462673',
  purpleLight: '#8A6DF2',
  blue: '#52ABF2',
  purple: '#683CA6',
  greyDark: '#1f1f1f',
};

const indigoDark: Theme = {
  background: baseColours.black,
  background1: themeColours.greyDark,
  background2: baseColours.greyLight,
  border: baseColours.grey,
  border1: baseColours.greyLight,
  noticeBackground: darken(0.15, themeColours.purpleDark),
  noticeLinkColor: themeColours.blue,
  disabledButtonBackground: baseColours.greyWhite,
  disabledButtonBorder: baseColours.grey,
  disabledButtonColor: baseColours.white,
  error: baseColours.red,
  feedbackBubbleColor: themeColours.blue,
  inputColor: baseColours.white,
  link: themeColours.blue,
  linkHover: lighten(0.1, themeColours.blue),
  linkDisabled: baseColours.grey,
  modalDismissBackground: baseColours.greyDark,
  modalDismissColor: baseColours.white,
  primary: themeColours.purpleLight,
  primaryHighlight: lighten(0.1, themeColours.purpleLight),
  primaryAlternate: darken(0.1, themeColours.purpleLight),
  secondary: themeColours.purple,
  secondaryHighlight: themeColours.blue,
  success: baseColours.green,
  tertiary: baseColours.white,
  text: baseColours.white,
  text1: baseColours.greyWhite,
  text2: baseColours.grey,
  textContrast: baseColours.white,
  textPrimary: themeColours.purpleDark,
  warning: baseColours.orange,
};
export default indigoDark;
