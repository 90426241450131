import { isRequired, isUrl } from '@common/validations';
import validateFields from '@common/validations/validateFields';
import { css } from '@emotion/css';
import Button from '@frontend/components/Button';
import FormField from '@frontend/components/form/field/FormField';
import FormGroup from '@frontend/components/form/FormGroup';
import FormInput from '@frontend/components/form/FormInput';
import Column from '@frontend/components/grid/Column';
import flexStyles from '@frontend/styles/flex';
import { Form, Formik } from 'formik';
import React from 'react';

export interface GoalAddFormValues {
  name: string;
  link: string;
}

interface Props {
  categoryId: number;
  initialValues?: GoalAddFormValues;
  onSubmit: (values: GoalAddFormValues) => void;
}

const required = isRequired();

const GoalAddForm = ({
  categoryId,
  initialValues = {
    name: '',
    link: '',
  },
  onSubmit,
}: Props) => {
  return (
    <Formik<GoalAddFormValues>
      initialValues={initialValues}
      validateOnBlur={false}
      validate={validateFields({
        name: required,
        link: isUrl,
      })}
      onSubmit={async (values, form) => {
        await onSubmit(values);
        form.resetForm();
      }}
    >
      <Form>
        <FormGroup>
          <FormField
            as={FormInput}
            name="name"
            label="Your goal"
            labelColor="primaryAlternate"
            id={`goalForm-${categoryId}-name`}
            placeholder="e.g. Do the gardening"
          />
        </FormGroup>

        <FormGroup
          className={css`
            ${flexStyles.flex}
          `}
        >
          <Column
            size={10}
            className={css`
              padding: 0;
            `}
          >
            <FormField
              label="Add a link to your goal (optional)"
              labelSize="xxs"
              labelColor="primaryAlternate"
              id={`goalForm-${categoryId}-link`}
              as={FormInput}
              name="link"
              placeholder="http://"
            />
          </Column>
        </FormGroup>

        <FormGroup
          className={css`
            ${flexStyles.flex}
          `}
        >
          <Button type="submit">Add goal</Button>
        </FormGroup>
      </Form>
    </Formik>
  );
};

export default GoalAddForm;
