import { fonts, spacings } from '@frontend/styles/variables';
import { css } from '@emotion/css';
import { Theme } from '@emotion/react';

const themedTableStyle = (theme: Theme) => css`
  border-spacing: 0;
  margin-bottom: ${spacings.md};
  width: 100%;

  th,
  td {
    padding: 0.8rem 0.8rem 0.8rem 0;
  }

  td {
    border-bottom: 1px solid ${theme.border1};
  }

  th {
    border-bottom: 1px solid ${theme.border};
    color: ${theme.tertiary};
    font-family: ${fonts.heading};
    text-align: left;
  }

  th:last-of-type,
  td:last-of-type {
    padding: 0.8rem 0 0.8rem 0;
  }

  tbody tr:last-of-type td {
    border-bottom: 0;
  }
`;

export default themedTableStyle;
