import { ActivityResource } from '@common/types/apiResources';
import apiClient from '@frontend/services/client/apiClient';
import { FrontendCrudService } from '@frontend/types/service';
import { SmhgActivityFindParams } from '@common/types/smhg';

const activityService: FrontendCrudService<
  ActivityResource,
  { query: SmhgActivityFindParams }
> = apiClient.service('/api/activities');

export default activityService;
