import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import AsideBox from '@frontend/components/AsideBox';
import ButtonLink from '@frontend/components/ButtonLink';
import flexStyles from '@frontend/styles/flex';
import headingStyles from '@frontend/styles/headings';
import spacingStyles from '@frontend/styles/spacings';
import { breakpoints, spacings } from '@frontend/styles/variables';
import React from 'react';
import { FaComment } from 'react-icons/fa';
import { MdFeedback } from 'react-icons/all';

const containerStyle = css`
  @media (max-width: ${breakpoints.desktop}) {
    text-align: center;
  }
`;

const bubbleStyle = css`
  border-radius: 50%;
  display: flex;
  padding: 2rem;
  position: relative;
`;

const commentStyle = css`
  height: 6rem;
  width: 6rem;
  z-index: 2;
`;

const commentShadowStyle = css`
  bottom: 10px;
  height: 6rem;
  left: calc(2rem + 10px);
  position: absolute;
  top: calc(2rem + 10px);
  width: 6rem;
  z-index: 1;
`;

const SurveyBox = () => {
  const theme = useTheme();
  const themedBubbleStyle = css`
    ${bubbleStyle};
    background: ${theme.primary};
  `;
  const themedCommentStyle = css`
    ${commentStyle};
    color: ${theme.feedbackBubbleColor};
  `;
  const themedCommentShadowStyle = css`
    ${commentShadowStyle};
    color: ${theme.primaryAlternate};
  `;

  return (
    <AsideBox>
      <div className={containerStyle}>
        <div
          className={cx(
            flexStyles.flex,
            flexStyles.justifyCenter,
            spacingStyles.marginBottom.md,
          )}
        >
          <div className={themedBubbleStyle}>
            <FaComment className={themedCommentStyle} />
            <FaComment className={themedCommentShadowStyle} />
          </div>
        </div>

        <h2 className={headingStyles.sm}>Your feedback</h2>

        <p>
          Once you’ve started using ‘My Toolkit’ we’d love to know what you
          think.
        </p>

        <ButtonLink
          to="https://forms.office.com/Pages/ResponsePage.aspx?id=Bzs_EfUikk-krWlerO0cu1JGeHyJjndGhkxYEseZNe1UQkFMRlJNTVlEQ0NDM0ZVQzFRMTFTUFdTRSQlQCN0PWcu"
          target="_blank"
          rel="noopener noreferrer"
          block
        >
          Go to survey
          <MdFeedback
            className={spacingStyles.marginLeft.xs}
            size={spacings.md}
          />
        </ButtonLink>
      </div>
    </AsideBox>
  );
};

export default SurveyBox;
