import { injectGlobal } from '@emotion/css';
import Toaster from '@frontend/components/Toaster';
import {
  Auth0ContextProvider,
  Auth0ContextProviderProps,
} from '@frontend/context/Auth0Context';
import { CompletedGoalsContextProvider } from '@frontend/context/CompletedGoalsContext';
import { UserContextProvider } from '@frontend/context/UserContext';
import Routes from '@frontend/Routes';
import { authorizeApiClient } from '@frontend/services/client/apiClient';
import globalResetStyles from '@frontend/styles/reset';
import globalTypographyStyles from '@frontend/styles/typography';
import React, { useCallback, useMemo } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ThemeContextWrapper from '@frontend/context/ThemeContext';
import { VentFormTextKey } from '@frontend/pages/components/VentForm';

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  ${globalResetStyles};
  ${globalTypographyStyles};
`;

const App: React.FC = () => {
  const handleAuthenticated: Auth0ContextProviderProps['onAuthenticated'] = useCallback(
    (_, token) => {
      authorizeApiClient(token);
    },
    [],
  );

  const options = useMemo(
    () => ({
      redirectUri: `${window.location.origin}/home`,
    }),
    [],
  );

  return (
    <>
      <ThemeContextWrapper>
        <BrowserRouter>
          <HelmetProvider>
            <Helmet titleTemplate="%s | My Toolkit" />

            <Auth0ContextProvider
              onAuthenticated={handleAuthenticated}
              onLogout={async () => {
                localStorage.removeItem(VentFormTextKey);
              }}
              options={options}
            >
              <UserContextProvider>
                <CompletedGoalsContextProvider>
                  <Routes />
                </CompletedGoalsContextProvider>
              </UserContextProvider>
            </Auth0ContextProvider>
          </HelmetProvider>

          <Toaster />
        </BrowserRouter>
      </ThemeContextWrapper>
    </>
  );
};

export default App;
