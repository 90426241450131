import { isRequired, isUrl } from '@common/validations';
import isMax from '@common/validations/isMax';
import validateFields from '@common/validations/validateFields';
import Button from '@frontend/components/Button';
import FormField from '@frontend/components/form/field/FormField';
import FormInput from '@frontend/components/form/FormInput';
import FormTextArea from '@frontend/components/form/FormTextArea';
import { Form, Formik } from 'formik';
import React from 'react';

interface FormValues {
  title: string;
  description: string;
  url: string;
}

interface LinkCreateFormProps {
  initialValues?: FormValues;
  onSubmit: (link: FormValues) => void;
}

const formId = 'createLinkForm';
const required = isRequired();

const LinkCreateForm = ({ initialValues, onSubmit }: LinkCreateFormProps) => {
  const isEditing = !!initialValues;

  return (
    <Formik<FormValues>
      initialValues={
        initialValues || {
          title: '',
          description: '',
          url: '',
        }
      }
      validate={validateFields({
        title: required,
        url: isUrl,
        description: isMax(255),
      })}
      onSubmit={async (link, { resetForm }) => {
        await onSubmit(link);
        resetForm();
      }}
    >
      <Form>
        <FormField
          name="url"
          as={FormInput}
          id={`${formId}-url`}
          label="Your link"
          labelSize="xs"
          placeholder="http://"
        />
        <FormField
          name="title"
          as={FormInput}
          id={`${formId}-title`}
          label="Title for your link"
          labelSize="xs"
          placeholder="Enter your link title"
        />
        <FormField
          name="description"
          as={FormTextArea}
          id={`${formId}-description`}
          label="Describe your link"
          showLabel={false}
          labelSize="xs"
          placeholder="Describe your link here"
        />

        <Button type="submit">{isEditing ? 'Update' : 'Create'} Link</Button>
      </Form>
    </Formik>
  );
};

export default LinkCreateForm;
