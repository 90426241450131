import { GoalResource } from '@common/types/apiResources';
import Column from '@frontend/components/grid/Column';
import Row from '@frontend/components/grid/Row';
import ResponsiveCarousel from '@frontend/components/ResponsiveCarousel';
import GoalAddButton from '@frontend/pages/toolkit/components/GoalAddButton';
import GoalCard from '@frontend/pages/toolkit/components/GoalCard';
import spacingStyles from '@frontend/styles/spacings';
import React from 'react';

interface Props {
  categoryId: number;
  goals?: GoalResource[];
  onCreate: (
    goal: Partial<GoalResource>,
    options?: { createLink: boolean },
  ) => void;
  onComplete: (goalId: string) => void;
  onDelete: (goalId: string) => void;
}

const GoalCategoryGroup = ({
  categoryId,
  goals = [],
  onCreate,
  onComplete,
  onDelete,
}: Props) => {
  return (
    <Row>
      <ResponsiveCarousel>
        {goals.map(goal => (
          <Column
            key={goal.id}
            grow={false}
            size={4}
            className={spacingStyles.marginBottom.md}
          >
            <GoalCard
              id={goal.id}
              name={goal.name}
              link={goal.link}
              completed={!!goal.completedAt}
              onComplete={onComplete}
              onDelete={onDelete}
            />
          </Column>
        ))}

        {goals.length < 3 && (
          <Column
            grow={false}
            size={4}
            className={spacingStyles.marginBottom.md}
          >
            <GoalAddButton categoryId={categoryId} onCreate={onCreate}>
              Add a new goal here
            </GoalAddButton>
          </Column>
        )}
      </ResponsiveCarousel>
    </Row>
  );
};

export default GoalCategoryGroup;
