import DismissButton from '@frontend/components/DismissButton';
import GoalList from '@frontend/pages/components/GoalList';
import JournalCard from '@frontend/pages/journal/components/JournalCard';
import flexStyles from '@frontend/styles/flex';
import { widthStyles } from '@frontend/styles/utils';
import { cx } from '@emotion/css';
import React, { MouseEventHandler, useCallback } from 'react';

interface Props {
  date: Date;
  achievements: {
    id: string;
    name: string;
  }[];
  onDelete: (id: string) => void;
}

const JournalAchievements = ({ date, achievements = [], onDelete }: Props) => {
  const handleDelete: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      const id = event.currentTarget.getAttribute('data-id');

      if (!id) {
        throw new Error('No ID for this achievement');
      }

      onDelete(id);
    },
    [onDelete],
  );

  const goals = achievements.map(achievement => {
    return {
      id: achievement.id,
      name: (
        <div
          className={cx(
            flexStyles.flex,
            flexStyles.justifySpaceBetween,
            flexStyles.alignItemsCenter,
            widthStyles.full,
          )}
        >
          <span>{achievement.name}</span>

          <DismissButton
            colour="text2"
            onClick={handleDelete}
            data-id={achievement.id}
          >
            Delete achievement
          </DismissButton>
        </div>
      ),
      completedAt: date.toISOString(),
    };
  });

  return (
    <JournalCard title="Achievements" date={date}>
      <GoalList goals={goals} emptyText="No achievements for today." />
    </JournalCard>
  );
};

export default JournalAchievements;
