import { ButtonProps } from '@frontend/components/Button';
import { noButtonStyle } from '@frontend/styles/buttons';
import flexStyles from '@frontend/styles/flex';
import { themedFocusOutline } from '@frontend/styles/focus';
import { fonts, headingSizes, spacings } from '@frontend/styles/variables';
import { css, cx } from '@emotion/css';
import React, { forwardRef, Ref } from 'react';
import { Button } from 'reakit';
import { useTheme } from '@emotion/react';

const buttonStyle = css`
  ${flexStyles.flexCenterAll};
  ${noButtonStyle};
  border-radius: 8px;
  flex-direction: column;
  font-family: ${fonts.heading};
  font-size: ${headingSizes.sm};
  height: 100%;
  padding: ${spacings.lg};
  width: 100%;
`;

const ButtonCard = (
  { className, children, ...props }: ButtonProps,
  ref: Ref<HTMLButtonElement>,
) => {
  const theme = useTheme();
  const themedButtonStyle = css`
    ${buttonStyle}
    ${themedFocusOutline(theme)}
    background: ${theme.background1};
    border: 1px solid ${theme.border1};
    color: ${theme.text2};
  `;

  return (
    <Button {...props} className={cx(themedButtonStyle, className)} ref={ref}>
      {children}
    </Button>
  );
};

export default forwardRef<HTMLButtonElement, ButtonProps>(ButtonCard);
