import { css } from '@emotion/css';

const globalResetStyles = css`
  html,
  body {
    box-sizing: border-box;
    height: 100%;
    margin: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }
`;

export default globalResetStyles;
