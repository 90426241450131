import Card from '@frontend/components/Card';
import DismissButton from '@frontend/components/DismissButton';
import headingStyles from '@frontend/styles/headings';
import { positionStyles } from '@frontend/styles/position';
import { spacings } from '@frontend/styles/variables';
import { cx } from '@emotion/css';
import React, { ReactNode, useCallback } from 'react';
import EditButton from '@frontend/components/EditButton';

interface Props {
  children?: ReactNode;
  id: string;
  title: string;
  url: string;
  itemName?: string;
  onDelete: (id: string) => void;
  onEdit?: () => void;
}

const FavouriteCard = ({
  children,
  id,
  title,
  url,
  itemName = 'favourite',
  onDelete,
  onEdit,
}: Props) => {
  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  return (
    <Card className={positionStyles.relative}>
      <DismissButton
        className={cx(
          positionStyles.absolute,
          positionStyles.top(spacings.sm),
          positionStyles.right(spacings.sm),
        )}
        onClick={handleDelete}
      >
        Delete {itemName}
      </DismissButton>
      {onEdit && (
        <EditButton
          className={cx(
            positionStyles.absolute,
            positionStyles.top(spacings.sm),
            positionStyles.right(spacings.xl),
          )}
          onClick={onEdit}
        >
          Edit {itemName}
        </EditButton>
      )}

      <h3 className={headingStyles.xs}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </h3>

      {children}
    </Card>
  );
};

export default FavouriteCard;
