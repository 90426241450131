import _slicedToArray from "/builds/hiveit/my-toolkit/node_modules/@babel/runtime/helpers/esm/slicedToArray";

/**
 * Validate a set of form fields using the given validators.
 *
 * Accepts validators in the form of {@see Validator} function
 * or a map of {@see Validator} functions.
 * This is mostly to be compatible with the previous form validation API,
 * but allows you to define validators without needing to use a single
 * validator function.
 */
export default function validateFields(fields) {
  return function (values) {
    return Object.entries(fields).reduce(function (errors, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          validator = _ref2[1];

      var newValue = values[key];
      var messages = [];

      if (typeof validator === 'function') {
        var message = validator(newValue);

        if (message) {
          messages = [message];
        }
      } else {
        messages = Object.values(validator).reduce(function (acc, validate) {
          var message = validate(newValue);

          if (message) {
            acc.push(message);
          }

          return acc;
        }, []);
      }

      if (messages.length > 0) {
        // eslint-disable-next-line no-param-reassign
        errors[key] = messages;
      }

      return errors;
    }, {});
  };
}