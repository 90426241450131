export default function isMax(maxValue) {
  return function (value) {
    if (Array.isArray(value)) {
      return value.length > maxValue ? "Must choose a maximum of ".concat(maxValue, " ").concat(maxValue === 1 ? 'option' : 'options') : '';
    }

    if (typeof value === 'string') {
      return value.length > maxValue ? "Must be ".concat(maxValue, " characters or less") : '';
    }

    return value > maxValue ? "Must not exceed ".concat(maxValue) : '';
  };
}