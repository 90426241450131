import useQuery from '@frontend/hooks/useQuery';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

export default function useScrollToTop() {
  const { pathname } = useLocation();
  const { page } = useQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, page]);
}
