import { css } from '@emotion/css';
import { linearGradient } from 'polished';
import { Theme } from '@emotion/react';

// eslint-disable-next-line import/prefer-default-export
export const inputGradientStyle = (theme: Theme) => css`
  ${linearGradient({
    colorStops: [`${theme.secondaryHighlight} 0%`, `${theme.secondary} 100%`],
    fallback: theme.secondaryHighlight,
    toDirection: '100deg',
  })};
`;
