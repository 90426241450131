import { GratitudeResource } from '@common/types/apiResources';
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import DismissButton from '@frontend/components/DismissButton';
import JournalCard from '@frontend/pages/journal/components/JournalCard';
import { inputGradientStyle } from '@frontend/styles/gradients';
import { fonts, spacings } from '@frontend/styles/variables';
import React, { MouseEventHandler, useCallback } from 'react';

const listStyle = css`
  list-style: none;
  padding-left: 0;
`;

const itemStyle = css`
  border-radius: 33px;
  display: flex;
  font-family: ${fonts.heading};
  justify-content: space-between;
  margin-bottom: ${spacings.md};
  padding: 1rem 1.4rem 0.8rem;
  text-align: center;
`;

type EntryKey = keyof Pick<GratitudeResource, 'entry1' | 'entry2' | 'entry3'>;

export interface JournalGratitudesProps {
  date: Date;
  gratitudes: {
    id: string;
    entry1: string;
    entry2: string;
    entry3: string;
  };
  onDelete: (id: string, entry: EntryKey) => void;
}

const JournalGratitudes = ({
  gratitudes,
  date,
  onDelete,
}: JournalGratitudesProps) => {
  const theme = useTheme();
  const themedItemStyle = css`
    ${itemStyle}
    ${inputGradientStyle(theme)};
    color: ${theme.inputColor};
  `;

  const handleDelete: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      const entry = event.currentTarget.getAttribute('data-entry');

      if (!entry) {
        throw new Error('No entry for this gratitude');
      }

      onDelete(gratitudes.id, entry as EntryKey);
    },
    [gratitudes.id, onDelete],
  );

  return (
    <JournalCard title="Gratitudes" date={date}>
      <ul className={listStyle}>
        {[gratitudes.entry1, gratitudes.entry2, gratitudes.entry3].map(
          (entry, index) => {
            if (!entry) {
              return null;
            }

            return (
              // eslint-disable-next-line react/no-array-index-key
              <li className={themedItemStyle} key={index}>
                <span />
                <span>{entry}</span>

                <DismissButton
                  onClick={handleDelete}
                  data-entry={`entry${index + 1}`}
                >
                  Delete gratitude
                </DismissButton>
              </li>
            );
          },
        )}
      </ul>
    </JournalCard>
  );
};

export default JournalGratitudes;
