import { shadow } from '@frontend/styles/variables';
import { css } from '@emotion/css';

// eslint-disable-next-line import/prefer-default-export
export const smallBoxShadow = (
  colour: string = shadow,
  important = true,
) => css`
  box-shadow: 0 3px 8px ${colour} ${important && '!important'};
`;
