import PublicPage from '@frontend/components/PublicPage';
import PrivacyNotice from '@frontend/components/PrivacyNotice';
import React from 'react';

const PrivacyPublicPage = () => {
  return (
    <PublicPage title="Privacy notice" logo>
      <PrivacyNotice />
    </PublicPage>
  );
};

export default PrivacyPublicPage;
