import { ThemeBase } from '@common/types/emotion';
import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import headingStyles from '@frontend/styles/headings';
import { srOnlyStyle } from '@frontend/styles/utils';
import { fonts, headingSizes, spacings } from '@frontend/styles/variables';
import React, { ReactNode } from 'react';

export const labelStyle = css`
  display: block;
  font-family: ${fonts.heading};
  font-size: ${headingSizes.sm};
  margin-bottom: ${spacings.xs};
`;

interface Props {
  children: ReactNode;
  id: string;
  color?: keyof ThemeBase;
  size?: keyof typeof headingSizes;
  show?: boolean;
}

const FormLabel = ({
  children,
  id,
  size = 'sm',
  show = true,
  color = 'tertiary',
}: Props) => {
  const theme = useTheme();
  const themedLabelStyle = css`
    ${labelStyle};
    color: ${theme[color]};
  `;
  return (
    <label
      htmlFor={id}
      className={cx(
        themedLabelStyle,
        headingStyles[size],
        !show && srOnlyStyle,
      )}
    >
      {children}
    </label>
  );
};

export default FormLabel;
