import { GoalCategoryResource, GoalResource } from '@common/types/apiResources';
import minDelay from '@common/utils/minDelay';
import AsideBox from '@frontend/components/AsideBox';
import Column from '@frontend/components/grid/Column';
import Row from '@frontend/components/grid/Row';
import Link from '@frontend/components/Link';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import Page from '@frontend/components/Page';
import Pagination from '@frontend/components/Pagination';
import Separator from '@frontend/components/Separator';
import useAsyncRetry from '@frontend/hooks/useAsyncRetry';
import usePaginationQuery from '@frontend/hooks/usePaginationQuery';
import GoalCard from '@frontend/pages/toolkit/components/GoalCard';
import GoalCategoryDescription from '@frontend/pages/toolkit/components/GoalCategoryDescription';
import GoalCategoryGroup from '@frontend/pages/toolkit/components/GoalCategoryGroup';
import goalCategoryService from '@frontend/services/goalCategoryService';
import goalService from '@frontend/services/goalService';
import headingStyles from '@frontend/styles/headings';
import spacingStyles from '@frontend/styles/spacings';
import tryAsyncToast from '@frontend/utils/tryAsyncToast';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useImmer } from 'use-immer';

export interface RouteParams {
  categoryId: string;
}

const ToolkitCompletedPage = ({
  history,
  match,
}: RouteComponentProps<RouteParams>) => {
  const [currentGoalCategory, updateCurrentGoalCategory] = useImmer<
    GoalCategoryResource | undefined
  >(undefined);
  const [goalCategories, setGoalCategories] = useState<GoalCategoryResource[]>(
    [],
  );
  const { $limit, $skip } = usePaginationQuery();

  const {
    value: completedGoals,
    isLoading: isCompletedGoalsLoading,
    retry: fetchCompletedGoals,
  } = useAsyncRetry(
    () =>
      minDelay(
        goalService.find({
          query: {
            categoryId: match.params.categoryId,
            completedAt: {
              $ne: 'null',
            },
            $sort: {
              completedAt: -1,
            },
            $limit,
            $skip,
          },
        }),
        400,
      ),
    [$limit, $skip, match.params.categoryId],
  );

  const handleCreate = useCallback(
    (goal: Partial<GoalResource>) =>
      tryAsyncToast(
        async () => {
          const savedGoal = await goalService.create(goal);

          updateCurrentGoalCategory(draft => {
            if (draft?.goals) {
              draft.goals.push(savedGoal);
            }
          });
        },
        {
          success: 'Successfully created goal',
          error: 'Could not create goal',
        },
      ),
    [updateCurrentGoalCategory],
  );

  const handleComplete = useCallback(
    async (goalId: string) => {
      await goalService.patch(goalId, {
        completedAt: new Date().toISOString(),
      });

      updateCurrentGoalCategory(draft => {
        if (draft?.goals) {
          const matchingGoalIndex = draft.goals.findIndex(
            goal => goal.id === goalId,
          );

          draft.goals.splice(matchingGoalIndex, 1);
        }
      });

      await fetchCompletedGoals();
    },
    [fetchCompletedGoals, updateCurrentGoalCategory],
  );

  const handleDelete = useCallback(
    async (goalId: string) => {
      await goalService.remove(goalId);

      updateCurrentGoalCategory(draft => {
        if (draft?.goals) {
          const matchingGoalIndex = draft.goals.findIndex(
            goal => goal.id === goalId,
          );

          draft.goals.splice(matchingGoalIndex, 1);
        }
      });
    },
    [updateCurrentGoalCategory],
  );

  const handleDeleteCompleted = useCallback(
    async (goalId: string) => {
      await goalService.remove(goalId);
      await fetchCompletedGoals();
    },
    [fetchCompletedGoals],
  );

  useEffect(() => {
    goalCategoryService
      .find({
        query: {
          id: {
            $ne: match.params.categoryId,
          },
        },
      })
      .then(page => setGoalCategories(page.data));
  }, [match.params.categoryId]);

  useEffect(() => {
    goalCategoryService
      .get(match.params.categoryId, {
        query: {
          $eager: 'goals',
          $modifyEager: {
            goals: {
              completedAt: 'null',
            },
          },
        },
      })
      .then(current => {
        if (!current) {
          history.replace('/toolkit');
          return;
        }

        updateCurrentGoalCategory(_ => current);
      });
  }, [history, match.params.categoryId, updateCurrentGoalCategory]);

  return (
    <Page
      title="Completed goals"
      aside={
        <AsideBox>
          <h2 className={headingStyles.sm}>Other categories</h2>

          <ul>
            {goalCategories.map(goalCategory => (
              <li key={goalCategory.id}>
                <Link to={`/toolkit/completed/${goalCategory.id}`}>
                  {goalCategory.name}
                </Link>
              </li>
            ))}
          </ul>
        </AsideBox>
      }
      mobileAsidePosition="top"
    >
      {currentGoalCategory && (
        <>
          <h2 className={headingStyles.md}>{currentGoalCategory.name}</h2>

          <GoalCategoryDescription id={currentGoalCategory.id} />

          <h3 className={headingStyles.sm}>Your current goals</h3>

          <GoalCategoryGroup
            categoryId={currentGoalCategory.id}
            goals={currentGoalCategory.goals as GoalResource[]}
            onCreate={handleCreate}
            onComplete={handleComplete}
            onDelete={handleDelete}
          />

          <Separator variant="dashed" />

          <h3 className={headingStyles.sm}>Your completed goals</h3>

          <LoadingSpinner center size="xl" loading={isCompletedGoalsLoading}>
            {completedGoals && completedGoals.data.length > 0 ? (
              <>
                <Row className={spacingStyles.marginBottom.md}>
                  {completedGoals.data.map(goal => (
                    <Column
                      key={goal.id}
                      grow={false}
                      size={4}
                      className={spacingStyles.marginBottom.md}
                    >
                      <GoalCard
                        name={goal.name}
                        id={goal.id}
                        completed={!!goal.completedAt}
                        onDelete={handleDeleteCompleted}
                      />
                    </Column>
                  ))}
                </Row>

                <Pagination
                  aria-label="Completed goal page navigation"
                  {...completedGoals}
                />
              </>
            ) : (
              <p>No completed goals yet.</p>
            )}
          </LoadingSpinner>
        </>
      )}
    </Page>
  );
};

export default ToolkitCompletedPage;
