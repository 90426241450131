import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import Card from '@frontend/components/Card';
import { headingSizes, spacings } from '@frontend/styles/variables';
import { format } from 'date-fns/esm';
import React, { ReactNode } from 'react';

const cardStyle = css`
  flex-direction: column-reverse;
`;

const metaStyle = css`
  margin-top: ${spacings.md};
  text-align: right;
`;

const headingStyle = css`
  font-size: ${headingSizes.xs};
  margin-bottom: ${spacings.xs};
  text-transform: uppercase;
`;

interface Props {
  children: ReactNode;
  date: Date;
  title: string;
}

const JournalCard = ({ children, date, title }: Props) => {
  const theme = useTheme();
  const themedMetaStyle = css`
    ${metaStyle};
    color: ${theme.text1};
  `;
  const themedHeadingStyle = css`
    ${headingStyle};
    border-bottom: 1px solid ${theme.border};
  `;

  return (
    <Card className={cardStyle}>
      <div className={themedMetaStyle}>
        <h2 className={themedHeadingStyle}>{title}</h2>

        <time>{format(date, 'EE do MMMM yyyy')}</time>
      </div>

      <div>{children}</div>
    </Card>
  );
};

export default JournalCard;
