import Page from '@frontend/components/Page';
import PrivacyNotice from '@frontend/components/PrivacyNotice';
import React from 'react';

const PrivacyPage = () => {
  return (
    <Page title="Privacy notice">
      <PrivacyNotice />
    </Page>
  );
};

export default PrivacyPage;
