import React, { useRef } from 'react';
import Button from '@frontend/components/Button';
import { css } from '@emotion/css';
import spacingStyles from '@frontend/styles/spacings';
import { FaPlusCircle } from 'react-icons/fa';
import { spacings } from '@frontend/styles/variables';

interface FileUploadButtonProps {
  onFileUpload: (file: File) => Promise<void>;
  title: string;
  accept?: string;
}

const FileUploadButton = ({
  onFileUpload,
  title,
  accept,
}: FileUploadButtonProps) => {
  const fileRef = useRef<HTMLInputElement | null>(null);

  const onChangeFiles = async () => {
    const file = fileRef?.current?.files?.[0];

    if (file === undefined) return;

    await onFileUpload(file);
    if (fileRef?.current) fileRef.current.value = '';
  };

  return (
    <>
      <Button onClick={() => fileRef?.current?.click()}>
        {title}
        <FaPlusCircle
          className={spacingStyles.marginLeft.xs}
          size={spacings.md}
        />
      </Button>
      <input
        className={css`
          display: none;
        `}
        type="file"
        ref={fileRef}
        onChange={() => onChangeFiles()}
        accept={accept}
      />
    </>
  );
};

export default FileUploadButton;
