import { ImageUploadUrlResource } from '@common/types/apiResources';
import apiClient from '@frontend/services/client/apiClient';

interface ImageService {
  create(data: {
    type: 'rainy-day' | 'profile';
  }): Promise<ImageUploadUrlResource>;
  remove(id: string): Promise<void>;
}

const imageService: ImageService = apiClient.service('/api/images');

export default imageService;
