import { UserResource } from '@common/types/apiResources';
import { useAuth0Context } from '@frontend/context/Auth0Context';
import useAsyncRetry from '@frontend/hooks/useAsyncRetry';
import userService from '@frontend/services/userService';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';

export interface UserContextState {
  isLoading: boolean;
  error?: Error;
  user?: UserResource;
  setUser: (user: UserResource) => void;
}

export const UserContext = createContext<UserContextState | undefined>(
  undefined,
);

interface Props {
  children: ReactNode;
}

export const UserContextProvider = ({ children }: Props) => {
  const { isAuthenticated, user } = useAuth0Context();

  const { value, isLoading, error, setValue } = useAsyncRetry<
    UserResource | undefined
  >(async () => {
    if (!isAuthenticated) {
      return undefined;
    }

    if (user?.sub) {
      const { data } = await userService.find({
        query: {
          $limit: 1,
          auth0Id: user.sub,
        },
      });

      if (data.length === 1) {
        return data[0];
      }
    }

    throw new Error('Current user does not exist.');
  }, [user]);

  const state: UserContextState = useMemo(
    () => ({
      isLoading,
      error,
      user: value,
      setUser: setValue,
    }),
    [error, isLoading, setValue, value],
  );

  return (
    <UserContext.Provider value={state}>
      {!isLoading ? children : null}
    </UserContext.Provider>
  );
};

export function useUserContext() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('Need to have a UserContextProvider as a parent component');
  }

  return context;
}
