import { headingSizes } from '@frontend/styles/variables';
import { css } from '@emotion/css';
import React from 'react';

const style = css`
  h2 {
    font-size: ${headingSizes.md};
  }
`;

const PrivacyNotice = () => {
  return (
    <div className={style}>
      <p>Sheffield Flourish is a charity, registered number 1147334.</p>

      <p>
        By registering your details you consent to Sheffield Flourish processing
        your personal data in accordance with this privacy notice, and Sheffield
        Flourish’s Data Protection Policy.
      </p>

      <h2>What type of information we collect</h2>

      <p>
        The personal data we may collect includes without limitation your name,
        email address, preferences and interests and any other information that
        you choose to provide and/or that enables you to be personally
        identified.
      </p>

      <h2>How we use your personal information</h2>

      <p>We may use the information you provide for the purpose of:</p>

      <ul>
        <li>Anonymised reporting and analysis of popular services / events</li>
        <li>
          Anonymised reporting and analysis of achievements completed per user
        </li>
        <li>
          To contact you via Mailchimp about new features within the toolkit or
          information about other services we deem relevant. You can opt out of
          this at any time by managing your email preferences within the "My
          Account" section of the Toolkit.
        </li>
      </ul>

      <p>
        Your information will be securely stored on Amazon Web Services within a
        UK data centre, and managed by our third party suppliers Hive IT. Your
        Contact Information (Email and Name) will be stored within Mailchimp if
        you have not opted out of receiving emails from Sheffield Flourish.{' '}
      </p>

      <p>
        We will retain your information only for as long as is necessary, in
        accordance with Sheffield Flourish’s retention policies and the relevant
        legislation.
      </p>

      <h2>Legal basis for processing</h2>

      <p>
        Sheffield Flourish is processing this information in accordance with
        Article 6.1 (a): consent of the General Data Protection Regulation.
      </p>

      <h2>Further information</h2>

      <p>
        If you want to know what data we hold on you, or to ask us to delete it,
        please email{' '}
        <a href="mailto:info@sheffieldflourish.co.uk">
          info@sheffieldflourish.co.uk
        </a>
        .
      </p>
    </div>
  );
};

export default PrivacyNotice;
