export default function isRequired() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Field is required';
  return function (value) {
    if (Array.isArray(value)) {
      return value.length === 0 ? message : '';
    }

    if (typeof value === 'string') {
      return value.trim() === '' ? message : '';
    }

    return !value ? message : '';
  };
}