import delay from '@common/utils/delay';
import Button from '@frontend/components/Button';
import FormTextArea from '@frontend/components/form/FormTextArea';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import SuccessMessage from '@frontend/components/SuccessMessage';
import flexStyles from '@frontend/styles/flex';
import { positionStyles } from '@frontend/styles/position';
import spacingStyles from '@frontend/styles/spacings';
import { cx } from '@emotion/css';
import debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { spacings } from '@frontend/styles/variables';

export const VentFormTextKey = 'ventFormText';

const VentForm = () => {
  const [saveState, setSaveState] = useState<'saving' | 'saved' | null>();
  const [text, setText] = useState(localStorage.getItem(VentFormTextKey) ?? '');

  const handleSave = useCallback(
    debounce(async (value: string) => {
      setSaveState('saving');

      // Simulate an asynchronous action as we
      // just want to let the user know that
      // their data is being saved.
      await delay(() => {
        localStorage.setItem(VentFormTextKey, value);

        setSaveState('saved');
      }, 600);
    }, 400),
    [],
  );

  return (
    <form>
      <div className={positionStyles.relative}>
        <FormTextArea
          id="ventForm-text"
          label="Today I need to vent about..."
          labelAfter={
            <div>
              {saveState === 'saved' && <SuccessMessage>Saved</SuccessMessage>}
              {saveState === 'saving' && <LoadingSpinner />}
            </div>
          }
          hint="Sometimes it helps to get it out. ‘Vents’ will be deleted when you log out of My Toolkit or you can delete them straight away."
          name="text"
          value={text}
          onChange={event => {
            setSaveState(null);

            setText(event.target.value);
            handleSave(event.target.value);
          }}
        />
      </div>

      <div className={cx(flexStyles.flex, flexStyles.justifyEnd)}>
        <Button
          disabled={saveState === 'saving'}
          onClick={async () => {
            setSaveState('saving');

            await delay(() => {
              setText('');
              localStorage.removeItem(VentFormTextKey);

              setSaveState('saved');
            }, 600);
          }}
        >
          <span>Delete now</span>
          <FaTrashAlt
            className={spacingStyles.marginLeft.xs}
            size={spacings.md}
          />
        </Button>
      </div>
    </form>
  );
};

export default VentForm;
