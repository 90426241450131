import ButtonText from '@frontend/components/ButtonText';
import { fonts } from '@frontend/styles/variables';
import { css, cx } from '@emotion/css';
import React from 'react';
import { FaCaretUp } from 'react-icons/fa';
import { useTheme } from '@emotion/react';

interface Props {
  className?: string;
}

const GoToTop = ({ className }: Props) => {
  const theme = useTheme();
  const themedStyle = css`
    color: ${theme.text};
    font-family: ${fonts.body};
  `;

  const handleGoToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <ButtonText className={cx(themedStyle, className)} onClick={handleGoToTop}>
      go to top <FaCaretUp />
    </ButtonText>
  );
};

export default GoToTop;
