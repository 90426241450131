import LoadingSpinner from '@frontend/components/LoadingSpinner';
import flexStyles from '@frontend/styles/flex';
import { css } from '@emotion/css';
import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  loading?: boolean;
}

const LoadingPage = ({ children, loading = true }: Props) => {
  return (
    <>
      {loading ? (
        <div
          className={css`
            ${flexStyles.flexCenterAll};
            height: 100vh;
          `}
        >
          <LoadingSpinner center size="xl" />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default LoadingPage;
