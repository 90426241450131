import { Dictionary } from '@common/types/util';
import { BadRequest } from '@feathersjs/errors';
import { FormikErrors, FormikValues } from 'formik';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MessageConverter = (params: any, originalMessage: string) => string;

const converters: Dictionary<MessageConverter> = {
  minLength: (params: { limit: number }) => {
    if (params.limit === 1) {
      return 'Field is required';
    }

    return 'Must be 1 or more characters';
  },
};

export type FieldsErrors<Values> = {
  [field in keyof Values]: {
    keyword: string;
    message: string;
    params: unknown;
  }[];
};

/**
 * Get the field errors from a server validation response and
 * convert them if required to more user-friendly messages.
 */
export default function getFieldErrors<Values extends FormikValues>(
  error: BadRequest,
): FormikErrors<Values> {
  if (error.name !== BadRequest.name || !isObject(error.data)) {
    return {};
  }

  return mapValues(error.data as FieldsErrors<Values>, fieldData =>
    fieldData.map(err => {
      if (converters[err.keyword]) {
        return converters[err.keyword](err.params, err.message);
      }

      return err.message;
    }),
  ) as FormikErrors<Values>;
}
