import AsideBox from '@frontend/components/AsideBox';
import headingStyles from '@frontend/styles/headings';
import { fonts } from '@frontend/styles/variables';
import { css } from '@emotion/css';
import React from 'react';

const MentalHealthGuideAside = () => {
  return (
    <AsideBox>
      <h2 className={headingStyles.sm}>Explore services and activities</h2>

      <p>
        Use the ‘Add Services’ and ‘Add Activities’ buttons to display
        organisations and activities. You can then narrow them down with the
        filters which appear. This information comes from the Sheffield Mental
        Health Guide.
      </p>

      <p
        className={css`
          font-family: ${fonts.heading};
          font-size: 1rem;
        `}
      >
        <a
          href="https://www.sheffieldmentalhealth.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit the full Sheffield Mental Health Guide website
        </a>
      </p>
    </AsideBox>
  );
};

export default MentalHealthGuideAside;
