import { Dictionary } from '@common/types/util';
import qs from 'qs';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

export default function useQuery<Params = Dictionary<string>>() {
  const location = useLocation();

  return useMemo<Params>(() => qs.parse(location.search.substr(1)), [
    location.search,
  ]);
}
