import Button from '@frontend/components/Button';
import ButtonCard from '@frontend/components/ButtonCard';
import ButtonGroup from '@frontend/components/ButtonGroup';
import FormImageCropperModal from '@frontend/components/form/FormImageCropperModal';
import FormLabel from '@frontend/components/form/FormLabel';
import ModalButton from '@frontend/components/ModalButton';
import { noButtonStyle } from '@frontend/styles/buttons';
import flexStyles from '@frontend/styles/flex';
import { inputGradientStyle } from '@frontend/styles/gradients';
import headingStyles from '@frontend/styles/headings';
import spacingStyles from '@frontend/styles/spacings';
import { headingSizes, spacings } from '@frontend/styles/variables';
import toDataUrl from '@frontend/utils/file/toDataUrl';
import { css, cx } from '@emotion/css';
import { lighten } from 'polished';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import { useDialogState, VisuallyHidden } from 'reakit';
import { useTheme } from '@emotion/react';
import { FaCamera } from 'react-icons/all';

const uploadButtonStyle = css`
  min-height: 300px;
`;

const imageStyle = css`
  border-radius: 8px;
  margin-bottom: ${spacings.xs};
  width: 100%;
`;

const zoomButtonStyle = css`
  ${noButtonStyle};
  cursor: zoom-in;
  padding: 0;
  width: 100%;
`;

interface Props {
  aspectRatio?: number;
  alt?: string;
  id: string;
  label: string;
  labelSize?: keyof typeof headingSizes;
  onChange?: (image: Blob) => void;
  onDelete?: () => void;
  value: string;
}

const FormImageInput = ({
  aspectRatio,
  alt = 'Preview',
  id,
  label,
  labelSize,
  onChange,
  onDelete,
  value,
}: Props) => {
  const theme = useTheme();
  const [imageFile, setImageFile] = useState<File>();
  const [imageUrl, setImageUrl] = useState(value);

  const activeDragStyle = css`
    ${inputGradientStyle(theme)};
    color: ${theme.primary};
    outline: 3px solid ${lighten(0.1, theme.primaryHighlight)};
    outline-offset: 5px;
  `;

  useEffect(() => {
    setImageUrl(value);
  }, [value]);

  const dialog = useDialogState();

  const { getInputProps, getRootProps, inputRef, isDragActive } = useDropzone({
    onDrop: async files => {
      if (files.length < 1) {
        return;
      }

      setImageFile(files[0]);

      const dataUrl = await toDataUrl(files[0]);

      setImageUrl(dataUrl.toString());
      dialog.show();
    },
  });

  const handleCropConfirm = useCallback(
    (image: HTMLCanvasElement) => {
      setImageUrl(image.toDataURL(imageFile?.type ?? 'image/jpeg'));

      image.toBlob(blob => {
        if (blob && onChange) {
          onChange(blob);
        }
      }, 'image/jpeg');
    },
    [imageFile, onChange],
  );

  const handleCropCancel = useCallback(() => {
    if (imageFile && onChange) {
      onChange(imageFile);
    }
  }, [imageFile, onChange]);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <>
      <FormLabel id={id} size={labelSize}>
        {label}
      </FormLabel>

      {imageUrl ? (
        <>
          <ModalButton
            aria-label="Your image"
            button={
              <button className={zoomButtonStyle} type="button">
                <img src={imageUrl} alt={alt} className={imageStyle} />
              </button>
            }
          >
            <img src={imageUrl} alt={alt} className={imageStyle} />
          </ModalButton>

          <ButtonGroup className={flexStyles.justifyEnd}>
            <Button
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = '';
                  inputRef.current.click();
                }
              }}
            >
              Change image
            </Button>
            <Button
              inverted
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = '';
                  setImageUrl('');

                  if (onDelete) {
                    onDelete();
                  }
                }
              }}
            >
              <FaTrashAlt />
              <span className={spacingStyles.marginLeft.xs}>
                Delete
                <VisuallyHidden>Image</VisuallyHidden>
              </span>
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <ButtonCard
          className={cx(uploadButtonStyle, isDragActive && activeDragStyle)}
          {...getRootProps()}
        >
          <FaCamera
            className={cx(headingStyles.xl, spacingStyles.marginBottom.md)}
          />

          <span className={spacingStyles.marginBottom.sm}>
            Click or drag here to upload
          </span>

          <FaPlusCircle className={headingStyles.xl} />
        </ButtonCard>
      )}

      <FormImageCropperModal
        {...dialog}
        aspectRatio={aspectRatio}
        src={imageUrl}
        onConfirm={handleCropConfirm}
        onCancel={handleCropCancel}
      />
      <input {...getInputProps()} id={id} accept="image/*" />
    </>
  );
};

export default FormImageInput;
