import { Theme } from '@emotion/react';
import { darken, lighten } from 'polished';
import { baseColours } from '../styles/variables';

const themeColours = {
  maroon: '#59023A',
  pink: '#F25270',
  orange: '#F2B706',
  yellow: '#F2CB03',
  blueBaby: '#A1DCF2',
};

const summerTime: Theme = {
  background: baseColours.white,
  background1: baseColours.greyWhite,
  background2: baseColours.greyLight,
  border: baseColours.grey,
  border1: baseColours.greyLight,
  noticeBackground: lighten(0.1, themeColours.blueBaby),
  noticeLinkColor: themeColours.pink,
  disabledButtonBackground: baseColours.greyWhite,
  disabledButtonBorder: baseColours.grey,
  disabledButtonColor: baseColours.white,
  error: baseColours.red,
  feedbackBubbleColor: themeColours.yellow,
  inputColor: themeColours.maroon,
  link: themeColours.pink,
  linkHover: themeColours.maroon,
  linkDisabled: baseColours.grey,
  modalDismissBackground: baseColours.greyDark,
  modalDismissColor: baseColours.white,
  primary: themeColours.pink,
  primaryHighlight: lighten(0.1, themeColours.pink),
  primaryAlternate: darken(0.1, themeColours.pink),
  secondary: themeColours.yellow,
  secondaryHighlight: themeColours.orange,
  success: baseColours.green,
  tertiary: themeColours.maroon,
  text: baseColours.black,
  text1: baseColours.greyDark,
  text2: baseColours.grey,
  textContrast: baseColours.white,
  textPrimary: themeColours.maroon,
  warning: baseColours.orange,
};
export default summerTime;
