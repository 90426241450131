import { css, cx } from '@emotion/css';
import React, { ReactNode } from 'react';

const rowStyle = css`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
`;

interface Props {
  children: ReactNode;
  className?: string;
}

const Row = ({ children, className }: Props) => {
  return <div className={cx(rowStyle, className)}>{children}</div>;
};

export default Row;
