import Button from '@frontend/components/Button';
import ButtonGroup from '@frontend/components/ButtonGroup';
import Column from '@frontend/components/grid/Column';
import Row from '@frontend/components/grid/Row';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import ModalButton from '@frontend/components/ModalButton';
import Page from '@frontend/components/Page';
import SurveyBox from '@frontend/components/SurveyBox';
import ThemeSelect from '@frontend/components/ThemeSelect';
import { useAuth0Context } from '@frontend/context/Auth0Context';
import { useUserContext } from '@frontend/context/UserContext';
import AccountForm from '@frontend/pages/account/components/AccountForm';
import AccountImageForm from '@frontend/pages/account/components/AccountImageForm';
import userService from '@frontend/services/userService';
import headingStyles from '@frontend/styles/headings';
import React from 'react';
import { toast } from 'react-toastify';

const AccountPage = () => {
  const { logout } = useAuth0Context();
  const { user, isLoading, setUser } = useUserContext();

  return (
    <Page
      title="My Account"
      intro="You can change your account details, and add your own profile image here."
      aside={<SurveyBox />}
      easyreadPdf="My Toolkit - My Account"
    >
      <LoadingSpinner loading={isLoading}>
        {user && (
          <Row>
            <Column size={6}>
              <h2 className={headingStyles.sm}>Account details</h2>

              <dl>
                <dt>First name</dt>
                <dd>{user?.firstName}</dd>

                <dt>Last name</dt>
                <dd>{user?.lastName}</dd>

                <dt>Email name</dt>
                <dd>{user?.email}</dd>

                <dt>Subscribed to mailing list?</dt>
                <dd>{user?.allowMailingList ? 'Yes' : 'No'}</dd>
              </dl>

              <ButtonGroup>
                <ModalButton
                  aria-label="Change account details"
                  button={<Button inverted>Edit details</Button>}
                >
                  {modal => (
                    <AccountForm
                      initialValues={user}
                      onSubmit={async ({
                        firstName,
                        lastName,
                        password,
                        allowMailingList,
                      }) => {
                        const savedUser = await userService.patch(user.id, {
                          firstName,
                          lastName,
                          password,
                          allowMailingList,
                        });

                        setUser(savedUser);
                        modal.hide();
                      }}
                    />
                  )}
                </ModalButton>

                <ModalButton
                  aria-label="Delete account"
                  button={
                    <Button inverted colour="error">
                      Delete account
                    </Button>
                  }
                >
                  {modal => (
                    <>
                      <h1 className={headingStyles.md}>Delete account</h1>

                      <p>
                        Are you sure you want to delete your account? This
                        cannot be reverted.
                      </p>

                      <Button
                        colour="error"
                        onClick={async () => {
                          modal.hide();

                          try {
                            await userService.remove(user.id);
                            await logout();
                          } catch (err) {
                            // eslint-disable-next-line no-console
                            console.error(err);

                            toast.error('Could not delete account');
                          }
                        }}
                      >
                        Yes, delete my account
                      </Button>
                    </>
                  )}
                </ModalButton>
              </ButtonGroup>
            </Column>
            <Column size={6}>
              <AccountImageForm user={user} />
            </Column>
          </Row>
        )}
        <Row>
          <Column size={6}>
            <h2 className={headingStyles.sm}>Toolkit theme</h2>
            Choose a colour theme you prefer
            <ThemeSelect showDesktop alwaysOpen />
          </Column>
        </Row>
      </LoadingSpinner>
    </Page>
  );
};

export default AccountPage;
