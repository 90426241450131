import { RainyDayResource } from '@common/types/apiResources';
import Button from '@frontend/components/Button';
import FormField from '@frontend/components/form/field/FormField';
import FormGroup from '@frontend/components/form/FormGroup';
import FormInput from '@frontend/components/form/FormInput';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import SuccessMessage from '@frontend/components/SuccessMessage';
import useFormSubmit from '@frontend/hooks/useFormSubmit';
import rainyDayService from '@frontend/services/rainyDayService';
import flexStyles from '@frontend/styles/flex';
import headingStyles from '@frontend/styles/headings';
import spacingStyles from '@frontend/styles/spacings';
import { floatStyles } from '@frontend/styles/utils';
import { cx } from '@emotion/css';
import { Form, Formik } from 'formik';
import React from 'react';

interface FormValues {
  gratitude1: string;
  gratitude2: string;
  gratitude3: string;
}

interface Props {
  rainyDay?: RainyDayResource;
  onSubmit: (values: RainyDayResource) => void;
}

const GratitudesForm = ({ rainyDay, onSubmit }: Props) => {
  const formId = 'gratitudesForm';

  const {
    fieldErrors,
    handleSubmit,
    isSubmitted,
    isSubmitting,
  } = useFormSubmit<FormValues>(
    async ({ gratitude1, gratitude2, gratitude3 }) => {
      if (rainyDay) {
        const newRainyDay = await rainyDayService.patch(
          rainyDay.userId,
          {
            gratitude1,
            gratitude2,
            gratitude3,
          },
          { query: { $eager: 'mediaReferences' } },
        );

        onSubmit(newRainyDay);
      } else {
        const newRainyDay = await rainyDayService.create({
          gratitude1,
          gratitude2,
          gratitude3,
        });

        onSubmit(newRainyDay);
      }
    },
    {
      success: 'Saved gratitudes',
      error: 'Could not save gratitudes',
    },
  );

  return (
    <Formik<FormValues>
      initialValues={{
        gratitude1: rainyDay?.gratitude1 ?? '',
        gratitude2: rainyDay?.gratitude2 ?? '',
        gratitude3: rainyDay?.gratitude3 ?? '',
      }}
      initialErrors={fieldErrors}
      onSubmit={handleSubmit}
    >
      <Form>
        <fieldset aria-describedby={`${formId}-gratitude-hint`}>
          <legend>
            <h2 className={headingStyles.sm}>
              Three things to remember when you're feeling down
            </h2>
          </legend>

          <p id={`${formId}-gratitude-hint`}>
            It could be the name of a friend you can phone, a coping strategy,
            or something you enjoy doing.
          </p>

          <div className={cx(floatStyles.right, spacingStyles.marginBottom.sm)}>
            {isSubmitting && <LoadingSpinner />}
            {isSubmitted && <SuccessMessage>Saved</SuccessMessage>}
          </div>

          <FormField
            as={FormInput}
            name="gratitude1"
            id={`${formId}-gratitude1`}
            label="First thing you are grateful for"
            showLabel={false}
            placeholder="Enter first"
          />
          <FormField
            as={FormInput}
            name="gratitude2"
            id={`${formId}-gratitude2`}
            label="Second thing you are grateful for"
            showLabel={false}
            placeholder="Enter second"
          />
          <FormField
            as={FormInput}
            name="gratitude3"
            id={`${formId}-gratitude3`}
            label="Third thing you are grateful for"
            showLabel={false}
            placeholder="Enter third"
          />
        </fieldset>

        <FormGroup className={cx(flexStyles.flex, flexStyles.justifyCenter)}>
          <Button inverted type="submit" disabled={isSubmitting}>
            Save gratitudes
          </Button>
        </FormGroup>
      </Form>
    </Formik>
  );
};

export default GratitudesForm;
