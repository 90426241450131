import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import DropdownMenu from '@frontend/components/DropdownMenu';
import DropdownMenuItem from '@frontend/components/DropdownMenuItem';
import { useAuth0Context } from '@frontend/context/Auth0Context';
import flexStyles from '@frontend/styles/flex';
import spacingStyles from '@frontend/styles/spacings';
import { fonts, spacings } from '@frontend/styles/variables';
import React from 'react';
import { FaChevronDown, FaUserCircle } from 'react-icons/fa';
import { MenuItem } from 'reakit';
import ThemeMenu from './ThemeMenu';

const imageStyle = css`
  border-radius: 50%;
  height: 150px;
  margin-bottom: ${spacings.sm};
  width: 150px;
`;

const placeholderStyle = css`
  height: 150px;
  margin-bottom: ${spacings.sm};
  width: 150px;
`;

export const menuButtonStyle = css`
  background: none;
  border: 0;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-family: ${fonts.body};
  outline: none;
  padding-bottom: ${spacings.xs};

  &:hover,
  &:active {
    transition: border-bottom 0.2s ease-in-out, color 0.2s ease-in-out;
  }
`;

interface Props {
  name: string;
  imageUrl: string;
}

const ProfileAvatar = ({ name, imageUrl }: Props) => {
  const theme = useTheme();

  const themedImageStyle = css`
    ${imageStyle};
    background: ${theme.background};
  `;

  const themedPlaceholderStyle = css`
    ${placeholderStyle};
    color: ${theme.secondaryHighlight};
  `;

  const themedMenuButtonStyle = css`
    ${menuButtonStyle};
    color: ${theme.primary};
    white-space: nowrap;
    &:hover,
    &:active {
      border-bottom: 2px solid ${theme.primaryHighlight};
      color: ${theme.primaryHighlight};
    }
  `;

  const { logout } = useAuth0Context();

  return (
    <div
      className={cx(
        flexStyles.flex,
        flexStyles.column,
        flexStyles.alignItemsCenter,
      )}
    >
      {imageUrl ? (
        <img className={themedImageStyle} src={imageUrl} alt="Your profile" />
      ) : (
        <FaUserCircle className={themedPlaceholderStyle} />
      )}

      <DropdownMenu
        aria-label="Account options"
        button={
          <button type="button" className={themedMenuButtonStyle}>
            {name}
            <FaChevronDown className={spacingStyles.marginLeft.xs} />
          </button>
        }
      >
        <DropdownMenuItem to="/account">My Account</DropdownMenuItem>
        <MenuItem as={ThemeMenu} />
        <DropdownMenuItem
          onClick={async () => {
            await logout({ returnTo: window.location.origin });
          }}
        >
          Log Out
        </DropdownMenuItem>
      </DropdownMenu>
    </div>
  );
};

export default ProfileAvatar;
