import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@emotion/react';
import { smallBoxShadow } from '@frontend/styles/shadows';
import { fontWeights } from '@frontend/styles/variables';
import React, { ReactNode } from 'react';
import {
  Tooltip as BaseTooltip,
  TooltipArrow,
  TooltipStateReturn,
} from 'reakit';

const tooltipStyle = css`
  ${smallBoxShadow()};
  border-radius: 5px;
  font-weight: ${fontWeights.semiBold};
  padding: 0.2rem 0.6rem;
`;

interface Props extends TooltipStateReturn {
  children: ReactNode;
  className?: string;
  colour?: keyof Theme;
}

const Tooltip = ({
  children,
  className,
  colour = 'tertiary',
  ...props
}: Props) => {
  const theme = useTheme();
  const themedTooltipStyle = css`
    ${tooltipStyle};
    background: ${theme[colour]};
    color: ${theme.textContrast};
  `;
  const themedTooltipArrowStyle = css`
    fill: ${theme[colour]};
  `;

  return (
    <BaseTooltip {...props} className={cx(themedTooltipStyle, className)}>
      {children}

      <TooltipArrow className={themedTooltipArrowStyle} {...props} />
    </BaseTooltip>
  );
};

export default Tooltip;
