import { css } from '@emotion/css';
import { Theme, useTheme } from '@emotion/react';
import { buttonStyle } from '@frontend/components/Button';
import { useMaxWidthMedia } from '@frontend/hooks/useMedia';
import { smallBoxShadow } from '@frontend/styles/shadows';
import {
  breakpoints,
  fonts,
  headingSizes,
  spacings,
} from '@frontend/styles/variables';
import { EventApi } from '@fullcalendar/core';
import { ExtendedEventSourceInput } from '@fullcalendar/core/structs/event-source';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import '@fullcalendar/list/main.css';
import FullCalendar from '@fullcalendar/react';
import rrulePlugin from '@fullcalendar/rrule';
import { lighten, desaturate } from 'polished';
import React, { memo, useMemo, useRef } from 'react';

const generateThemedStyle = (theme: Theme) => css`
  .fc-head-container {
    border-left: 0;
    border-right: 0;
    border-top: 0;
  }

  .fc-header-toolbar {
    @media (max-width: ${breakpoints.desktop}) {
      flex-direction: column;
    }

    .fc-right {
      display: flex;
      flex-wrap: wrap;
    }

    .fc-left,
    .fc-center,
    .fc-right {
      margin-bottom: ${spacings.sm};
    }
  }

  .fc-scroller {
    height: auto !important;
  }

  .fc-button {
    border: 1px solid transparent !important;
  }

  .fc-prev-button,
  .fc-today-button,
  .fc-next-button {
    ${buttonStyle({
      theme,
      size: 'sm',
      colour: 'secondary',
      textColour: 'inputColor',
      inverted: false,
    })};

    &:active {
      border-color: transparent !important;
      color: ${theme.primaryAlternate} !important;
    }

    &:focus,
    &:active {
      background: ${theme.secondary} !important;
      ${smallBoxShadow()}
    }

    &:disabled {
      background: ${desaturate(0.1, theme.secondary)} !important;
      color: ${desaturate(0.1, theme.inputColor)} !important;
    }
  }

  .fc-button .fc-icon {
    font-size: 1.3rem;
  }

  .fc-addEvent-button {
    ${buttonStyle({ theme, size: 'sm', colour: 'primary', inverted: false })};

    &:active {
      border-color: transparent !important;
      color: ${theme.textContrast} !important;
    }

    &:focus,
    &:active {
      background: ${theme.primary} !important;
      ${smallBoxShadow()}
    }

    @media (min-width: ${breakpoints.desktop}) {
      margin: 0;
      width: auto;
    }
  }

  /* Grid view */

  .fc-day-header {
    border-color: transparent;
    color: ${theme.tertiary};
    font-family: ${fonts.heading};
    font-size: ${headingSizes.xs};
    padding: ${spacings.xs};
    text-align: right;
  }

  .fc-day-top {
    font-family: ${fonts.heading};
    font-size: 1rem;
    padding: ${spacings.xs};
  }

  .fc-today {
    background: ${lighten(0.03, theme.secondaryHighlight)} !important;
  }

  .fc td {
    border-color: transparent;

    @media (min-width: ${breakpoints.tablet}) {
      border-color: ${theme.secondaryHighlight};
    }
  }

  .fc-widget-container {
    @media (min-width: ${breakpoints.tablet}) {
      border-color: ${theme.secondaryHighlight};
    }
  }

  /* List view */

  .fc-widget-header {
    padding: ${spacings.xs};
  }

  .fc-list-item-time,
  .fc-list-item-marker,
  .fc-list-item-title {
    padding: ${spacings.xs};
  }

  .fc-unthemed {
    .fc-list-heading td {
      border: 0;
    }

    .fc-list-view {
      border-color: ${theme.border1};
    }
  }
`;

interface Props {
  eventSources?: ExtendedEventSourceInput[];
  onAddEvent?: () => void;
  onEventClick?: (event: EventApi) => void;
  onDatesRender?: (date: Date) => void;
}

const Calendar = ({
  eventSources,
  onDatesRender,
  onEventClick,
  onAddEvent,
}: Props) => {
  const style = generateThemedStyle(useTheme());
  const mediaMatches = useMaxWidthMedia('tablet');
  const currentDate = useRef<Date>();

  const customButtons = useMemo(
    () =>
      onAddEvent
        ? {
            addEvent: {
              text: 'Add event',
              click() {
                onAddEvent();
              },
            },
          }
        : undefined,
    [onAddEvent],
  );

  return (
    <div className={style}>
      <FullCalendar
        plugins={[rrulePlugin, mediaMatches ? listPlugin : dayGridPlugin]}
        defaultView={mediaMatches ? 'listWeek' : 'dayGridMonth'}
        customButtons={customButtons}
        header={{
          left: 'title',
          center: onAddEvent ? 'addEvent' : '',
          right: 'prev today next',
        }}
        firstDay={1}
        eventClassName={css`
          cursor: pointer;
        `}
        eventSources={eventSources}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          hour12: false,
        }}
        datesRender={({ view }) => {
          const date = view.calendar.getDate();

          if (
            onDatesRender &&
            currentDate?.current?.valueOf() !== date?.valueOf()
          ) {
            onDatesRender(date);
          }

          currentDate.current = date;
        }}
        eventClick={event => {
          if (onEventClick) {
            onEventClick(event.event);
          }
        }}
      />
    </div>
  );
};

export default memo(Calendar);
