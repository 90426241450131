import { OmitStrict } from '@common/types/util';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import FormDateTime, { FormDateTimeProps } from '../FormDateTime';

type Props = OmitStrict<FormDateTimeProps, 'value' | 'onChange' | 'onBlur'>;

const FormFieldDateTime = <
  Values extends { [K in keyof Values]: Date | null }
>({
  name,
  ...props
}: Props & { name: keyof Values }) => {
  const form = useFormikContext<Values>();

  const handleChange = useCallback(
    value => {
      form.setFieldValue(name, value);
    },
    [form, name],
  );

  let errorMessage = '';

  if (form.touched[name]) {
    const errors = form.errors[name];

    errorMessage = Array.isArray(errors)
      ? (errors[0] as string)
      : (errors as string);
  }

  return (
    <>
      <FormDateTime
        {...props}
        name={name}
        value={form.values[name] as Date | null}
        error={errorMessage}
        onChange={handleChange}
      />
    </>
  );
};

export default FormFieldDateTime;
