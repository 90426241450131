import ModalButton from '@frontend/components/ModalButton';
import React, { useCallback, useState } from 'react';
import ButtonCard from '@frontend/components/ButtonCard';
import headingStyles from '@frontend/styles/headings';
import { FaPlusCircle, FaCamera } from 'react-icons/all';
import { css, cx } from '@emotion/css';
import MediaAddForm from '@frontend/pages/rainy-day/components/MediaAddForm';
import {
  MediaReferenceResource,
  MediaReferenceType,
} from '@common/types/apiResources';
import { useDropzone } from 'react-dropzone';
import { inputGradientStyle } from '@frontend/styles/gradients';
import { lighten } from 'polished';
import { useTheme } from '@emotion/react';
import tryAsyncToast from '@frontend/utils/tryAsyncToast';
import { GeneralError } from '@feathersjs/errors';
import { uploadReferencedFile } from '@frontend/services/mediaReferenceService';
import toDataUrl from '@frontend/utils/file/toDataUrl';
import FormImageCropperModal from '@frontend/components/form/FormImageCropperModal';
import { useDialogState } from 'reakit';
import mediaService from '@frontend/services/mediaService';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import spacingStyles from '@frontend/styles/spacings';
import Modal from '@frontend/components/Modal';
import Column from '@frontend/components/grid/Column';
import Row from '@frontend/components/grid/Row';
import flexStyles from '@frontend/styles/flex';

interface Props {
  className?: string;
  userId: string;
  onSave?: (reference: MediaReferenceResource) => void;
  id: string;
}

const uploadButtonStyle = css`
  min-height: 300px;
`;

type AllowedTypes = {
  [key in MediaReferenceType]?: {
    mime: string;
    extensions: string[];
    sites: string[];
    crop?: boolean;
  };
};

export const allowedTypes: AllowedTypes = {
  image: {
    mime: 'image/*',
    extensions: ['jpg', 'jpeg', 'png', 'gif', 'tif'],
    sites: [],
    crop: true,
  },
  video: {
    mime: 'video/*',
    extensions: ['mpg', 'mp4', 'hls', 'ts'],
    sites: ['youtube.com', 'vimeo.com', 'dailymotion.com', 'twitch.tv'],
  },
};

// @ts-ignore
const acceptType = Object.values(allowedTypes)
  .map(_ => _?.mime)
  .join(',');

const MediaAddButton = ({ className, userId, onSave, id }: Props) => {
  const [imageFile, setImageFile] = useState<File>();
  const [imageUrl, setImageUrl] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const theme = useTheme();
  const dialog = useDialogState();

  const loadingDialog = useDialogState();

  const activeDragStyle = css`
    ${inputGradientStyle(theme)};
    color: ${theme.primary};
    outline: 3px solid ${lighten(0.1, theme.primaryHighlight)};
    outline-offset: 5px;
  `;

  const onFileUpload = useCallback(
    async (file: File) => {
      await tryAsyncToast(
        async () => {
          const type = file.type.split('/')[0] as MediaReferenceType;

          if (allowedTypes[type] === undefined)
            throw new GeneralError(`Invalid file type chosen.`);

          setIsUploading(true);

          const newMediaReference = await uploadReferencedFile(
            file,
            type,
            userId,
            mediaService,
          );

          setImageFile(undefined);

          setIsUploading(false);

          if (onSave) {
            await onSave(newMediaReference);
          }
        },
        {
          success: 'File uploaded',
          error: err => {
            setIsUploading(false);
            setImageFile(undefined);
            if (err.name === 'GeneralError' || err.name === 'BadRequest') {
              return err.message;
            }
            return 'An error occurred, could not upload file';
          },
        },
      );
    },
    [onSave, userId],
  );

  const onFileAccept = async (file: File) => {
    setIsUploading(true);

    const type = file.type.split('/')[0] as MediaReferenceType;

    if (allowedTypes[type]?.crop) {
      setImageFile(file);

      const dataUrl = await toDataUrl(file);

      setImageUrl(dataUrl.toString());
      setIsUploading(false);
      dialog.show();
    } else {
      setIsUploading(false);
      await onFileUpload(file);
    }
  };

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop: async files => {
      await onFileAccept(files[0]);
    },
  });

  const handleCropConfirm = useCallback(
    async (image: HTMLCanvasElement) => {
      image.toBlob(async blob => {
        if (blob) {
          const blobFile = new File([blob], `cropped_${imageFile?.name}`, {
            type: 'image/jpeg',
          });
          await onFileUpload(blobFile);
        }
      }, 'image/jpeg');
    },
    [imageFile, onFileUpload],
  );

  const handleCropCancel = useCallback(async () => {
    if (imageFile) {
      await onFileUpload(imageFile);
    }
  }, [imageFile, onFileUpload]);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <>
      <ModalButton
        aria-label="drag photo/videos here"
        button={
          <div className={cx(className)}>
            <ButtonCard
              className={cx(uploadButtonStyle, isDragActive && activeDragStyle)}
              {...getRootProps()}
            >
              <LoadingSpinner loading={isUploading}>
                <FaCamera
                  className={cx(
                    headingStyles.xl,
                    spacingStyles.marginBottom.md,
                  )}
                />
                <span>Click or drag here to upload photos/videos</span>
                <FaPlusCircle
                  className={cx(headingStyles.xl, spacingStyles.marginTop.md)}
                />
              </LoadingSpinner>
            </ButtonCard>
          </div>
        }
      >
        {modal => (
          <>
            <h1 className={headingStyles.md}>Upload an Image or Video</h1>

            <MediaAddForm
              userId={userId}
              onSave={m => {
                modal.hide();
                if (onSave) onSave(m);
              }}
              onFileUpload={async m => {
                modal.hide();
                await onFileAccept(m);
              }}
              acceptType={acceptType}
            />
          </>
        )}
      </ModalButton>

      <Modal {...loadingDialog} visible={isUploading}>
        <Row className={cx(flexStyles.flexCenterAll)}>
          <Column size={12}>
            <h3 className={headingStyles.md}>Loading your file</h3>
          </Column>
        </Row>
        <Row>
          <Column size={12}>
            <LoadingSpinner loading center size="xl" />
          </Column>
        </Row>
      </Modal>

      <FormImageCropperModal
        {...dialog}
        src={imageUrl}
        onConfirm={handleCropConfirm}
        onCancel={handleCropCancel}
      />

      <input {...getInputProps()} id={id} accept={acceptType} />
    </>
  );
};

export default MediaAddButton;
