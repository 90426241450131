import { css } from '@emotion/css';
import Button from '@frontend/components/Button';
import Link from '@frontend/components/Link';
import PublicPage from '@frontend/components/PublicPage';
import { useAuth0Context } from '@frontend/context/Auth0Context';
import flexStyles from '@frontend/styles/flex';
import spacingStyles from '@frontend/styles/spacings';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router';
import {
  baseColours,
  headingSizes,
  spacings,
} from '@frontend/styles/variables';
import { useTheme } from '@emotion/react';
import { FiCalendar, FiKey, FiMap } from 'react-icons/all';
import KeyItem from '@frontend/pages/components/KeyItem';
import EasyReadKeyItemLogo from '@frontend/pages/components/EasyReadKeyItemLogo';
import UserFriendlyFeaturesIcon from '@frontend/pages/components/UserFriendlyFeaturesIcon';

const buttonContainerStyle = css`
  ${spacingStyles.margin.lg}
  ${flexStyles.flexCenterAll};
  ${flexStyles.column};

  width: 100%;
`;

const accountButtonContainerStyle = css`
  ${flexStyles.flex};
  ${flexStyles.justifyStart};

  width: 100%;
`;

const buttonStyle = css`
  ${spacingStyles.marginBottom.sm}
  width: 100%;
`;

const accountButtonStyle = css`
  ${spacingStyles.marginBottom.sm}
`;

const headerBox = css`
  ${spacingStyles.padding.sm};

  align-items: center;
  display: flex;
  flex-direction: column;
  width: 75%;
`;

const linkBox = css`
  ${spacingStyles.padding.sm};

  align-items: center;
  display: flex;
  flex-direction: column;
`;

const headerStyle = css`
  font-size: ${headingSizes.lg};
  text-align: center;
`;

const subHeaderStyle = css`
  font-size: ${headingSizes.xs};
  text-align: center;
`;

const includesHeaderStyle = css`
  ${subHeaderStyle};
  border-bottom: dotted 6px;
  margin-bottom: 0;
`;

const keyFeaturesBox = css`
  align-content: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 0 1rem;
  justify-content: center;
  padding: 3.5rem;
`;

const footerBox = css`
  ${spacingStyles.padding.lg}
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: ${spacings.xl};
  justify-content: center;
`;

const logoBox = css`
  ${spacingStyles.padding.lg}
  align-content: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: ${spacings.xl};
  justify-content: center;
`;

const links = css`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LandingPage = () => {
  const { login, isAuthenticated, createAccount } = useAuth0Context();

  const theme = useTheme();

  const themedHeaderStyle = css`
    ${headerStyle};
    color: ${theme.primary};
  `;

  const themedSubHeaderStyle = css`
    ${subHeaderStyle};
    color: ${theme.textPrimary};
  `;

  const themedIncludesHeaderStyle = css`
    ${includesHeaderStyle};
    border-bottom-color: ${theme.secondary};
    color: ${theme.textPrimary};
  `;

  return (
    <>
      <Helmet>
        <title>Hello</title>
      </Helmet>

      {isAuthenticated && <Redirect to="/home" />}

      <PublicPage title="My Toolkit" heading="" large>
        <div className={accountButtonContainerStyle}>
          <Button
            className={accountButtonStyle}
            onClick={() =>
              login({
                appState: { targetUrl: `${window.location.origin}/home` },
              })
            }
            title="Enter My Toolkit"
          >
            already have an account
          </Button>
        </div>
        <div className={headerBox}>
          <h1 className={themedHeaderStyle}>
            <img src="/images/mytoolkit-full.svg" alt="My Toolkit" />
          </h1>
          <h2 className={themedSubHeaderStyle}>Welcome to MyToolkit !</h2>

          <p>
            MyToolkit has been co-created with the community to complement the{' '}
            <Link to="https://www.sheffieldmentalhealth.co.uk/">
              Sheffield Mental Health Guide website
            </Link>
            . Sign up to create your own confidential space to put together
            ideas, services, and activities that interest you and support your
            mental health.
          </p>
          <p>
            MyToolkit is managed by{' '}
            <Link to="https://www.sheffieldflourish.co.uk/">
              Sheffield Flourish
            </Link>
            . For any questions about the website, please contact us at:{' '}
            <Link to="info@sheffieldflourish.co.uk">
              info@sheffieldflourish.co.uk
            </Link>
          </p>
        </div>
        <div className={linkBox}>
          <div className={buttonContainerStyle}>
            <Button
              className={buttonStyle}
              onClick={() =>
                createAccount({
                  appState: { targetUrl: `${window.location.origin}/home` },
                })
              }
              title="Enter My Toolkit"
            >
              Sign up for MyToolkit
            </Button>
            <Link to="/home">already have an account</Link>
          </div>
        </div>
        <div className={headerBox}>
          <h2 className={themedIncludesHeaderStyle}>MyToolkit includes</h2>
        </div>
        <div className={keyFeaturesBox}>
          <KeyItem icon={FiKey} title="On-the-go access">
            Mark and access your go-to services, activities, and explore the{' '}
            <Link to="https://www.sheffieldmentalhealth.co.uk/">
              Sheffield Mental Health Guide
            </Link>{' '}
            with ease.
          </KeyItem>
          <KeyItem icon={FiMap} title="Customised plan">
            Tailor your journey by setting your own goals, adding your favourite
            resources, pictures, and videos.
          </KeyItem>
          <KeyItem icon={EasyReadKeyItemLogo} title="Easy-read">
            Access easy-read instructions about how to use each page on
            MyToolkit.
          </KeyItem>
          <KeyItem icon={FiCalendar} title="Calendar">
            Add your own activities or select activities from the{' '}
            <Link to="https://www.sheffieldmentalhealth.co.uk/">
              Sheffield Mental Health Guide
            </Link>{' '}
            to easily view your schedule within your own calendar.
          </KeyItem>
          <KeyItem
            icon={UserFriendlyFeaturesIcon}
            title="User-friendly features"
          >
            Enjoy added features like a “vent” space for expressing frustrations
            and a “rainy days” section for uplifting resources.
          </KeyItem>
        </div>
        <div className={footerBox}>
          <div className={logoBox}>
            <Link to="https://www.sheffieldmentalhealth.co.uk/">
              <img src="/images/smhg.png" alt="Sheffield Mental Health Guide" />
            </Link>
            <Link to="https://www.sheffieldflourish.co.uk/">
              <img
                src="/images/Flourish-logo.svg"
                className={
                  theme.background === baseColours.black
                    ? css`
                        filter: invert();
                      `
                    : undefined
                }
                alt="Sheffield Flourish"
              />
            </Link>
          </div>
          <div className={links}>
            <Link to="/privacy">Privacy notice</Link>
            <Link to="https://www.sheffieldmentalhealth.co.uk/how-to-use-my-toolkit/">
              how to use guide
            </Link>
          </div>
        </div>
      </PublicPage>
    </>
  );
};

export default LandingPage;
