import { css } from '@emotion/css';
import { Theme, useTheme } from '@emotion/react';
import { fonts, fontWeights, headingSizes } from '@frontend/styles/variables';
import { linearGradient } from 'polished';
import React, { ReactNode } from 'react';

const baseItemStyle = css`
  display: flex;
  flex-direction: column;
  font-weight: ${fontWeights.regular};
  justify-content: center;
  padding: 1.5rem 3rem;
  position: relative;

  &::before {
    border-radius: 50%;
    content: '';
    position: absolute;
    z-index: 2;
  }

  &:first-child {
    border-left: 0;
    padding-top: 0;
  }
`;

interface TimelineItemProps {
  children: ReactNode;
  colour?: keyof Theme;
  gradientStartColour?: keyof Theme;
  marker?: boolean;
}

const TimelineItem = ({
  children,
  colour = 'primary',
  gradientStartColour = 'tertiary',
  marker,
}: TimelineItemProps) => {
  const theme = useTheme();

  const themedItemStyle = css`
    ${baseItemStyle}
    ${marker
      ? css`
          &::before {
            background: ${theme.background1};
            border: 6px solid ${theme[colour]};
            height: 2rem;
            left: -1rem;
            width: 2rem;
          }
        `
      : css`
          &::before {
            ${linearGradient({
              colorStops: [
                `${theme[gradientStartColour]} 0%`,
                `${theme[colour]} 100%`,
              ],
              toDirection: '316deg',
              fallback: theme[colour],
            })};
            height: 3rem;
            left: -1.5rem;
            width: 3rem;
          }
        `}
  `;

  return (
    <li className={themedItemStyle}>
      <div
        className={
          marker
            ? css`
                color: ${theme[colour]};
                font-family: ${fonts.heading};
                font-size: ${headingSizes.xs};
                line-height: 1.4;
              `
            : undefined
        }
      >
        {children}
      </div>
    </li>
  );
};

export default TimelineItem;
