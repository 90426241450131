import Button from '@frontend/components/Button';
import FormFieldToggle from '@frontend/components/form/field/FormFieldToggle';
import FormGroup from '@frontend/components/form/FormGroup';
import { Form, Formik } from 'formik';
import React from 'react';
import FiltersDropdown from '@frontend/components/FiltersDropdown';
import spacingStyles from '@frontend/styles/spacings';
import { FaConciergeBell, FaLink } from 'react-icons/fa';
import { MdLocalActivity } from 'react-icons/all';

export interface FavouriteFiltersFormValues {
  showServices: boolean;
  showActivities: boolean;
  showLinks: boolean;
}

interface Props {
  initialValues?: FavouriteFiltersFormValues;
  onSubmit: (values: FavouriteFiltersFormValues) => void;
}

const FavouriteFiltersForm = ({
  initialValues = {
    showServices: true,
    showActivities: true,
    showLinks: true,
  },
  onSubmit,
}: Props) => {
  return (
    <FiltersDropdown>
      <Formik<FavouriteFiltersFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <Form>
          <FormGroup>
            <FormFieldToggle
              id="favouriteFiltersForm-showServices"
              name="showServices"
              label={
                <>
                  <span>
                    View services
                    <FaConciergeBell className={spacingStyles.marginLeft.xs} />
                  </span>
                </>
              }
            />

            <FormFieldToggle
              id="favouriteFiltersForm-showActivities"
              name="showActivities"
              label={
                <>
                  <span>
                    View activities
                    <MdLocalActivity className={spacingStyles.marginLeft.xs} />
                  </span>
                </>
              }
            />

            <FormFieldToggle
              id="favouriteFiltersForm-showLinks"
              name="showLinks"
              label={
                <>
                  <span>
                    View links
                    <FaLink className={spacingStyles.marginLeft.xs} />
                  </span>
                </>
              }
            />
          </FormGroup>

          <Button type="submit" block>
            Filter results
          </Button>
        </Form>
      </Formik>
    </FiltersDropdown>
  );
};

export default FavouriteFiltersForm;
