import React from 'react';

const UserFriendlyFeaturesIcon = ({ size }: { size?: string }) => {
  return (
    <svg
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 44 44"
      fill="none"
    >
      <path d="M34.0417 5.375H8.95833C6.97931 5.375 5.375 6.97931 5.375 8.95833V34.0417C5.375 36.0207 6.97931 37.625 8.95833 37.625H34.0417C36.0207 37.625 37.625 36.0207 37.625 34.0417V8.95833C37.625 6.97931 36.0207 5.375 34.0417 5.375Z" />
      <path d="M5.375 16.125H37.625" />
      <path d="M16.125 37.625V16.125" />
    </svg>
  );
};

export default UserFriendlyFeaturesIcon;
