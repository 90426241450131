import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import GoToTop from '@frontend/components/GoToTop';
import Link from '@frontend/components/Link';
import {
  linkListStyle as navLinkListStyle,
  linkStyle,
} from '@frontend/components/NavBar';
import SMHGLogo from '@frontend/components/SMHGLogo';
import flexStyles from '@frontend/styles/flex';
import spacingStyles from '@frontend/styles/spacings';
import { breakpoints, spacings } from '@frontend/styles/variables';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaCalendarAlt, FaHeart, FaHome, FaToolbox } from 'react-icons/fa';
import { IoIosJournal, IoMdRainy } from 'react-icons/all';
import MyToolkitLogo from '@frontend/components/MyToolkitLogo';
import ThemeSelect from './ThemeSelect';

const footerContainerStyle = css`
  ${flexStyles.flexCenterAll};
  flex-direction: column;
  margin-top: 4rem;
  padding: ${spacings.md};
  width: 100%;

  @media (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const navStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacings.lg} 0;
  width: 100%;

  @media (min-width: ${breakpoints.desktop}) {
    display: none;
  }
`;

const secondaryNavStyle = css`
  margin-bottom: ${spacings.lg};
  padding-bottom: ${spacings.lg};
  width: 100%;

  @media (min-width: ${breakpoints.desktop}) {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    width: auto;
  }
`;

const linkListStyle = css`
  ${navLinkListStyle};

  li {
    margin-bottom: ${spacings.sm};
  }
`;

const logoContainerStyle = css`
  display: flex;
  gap: 2rem;
  justify-content: center;
  width: 100%;

  @media (min-width: ${breakpoints.desktop}) {
    width: auto;
  }
`;

const logoStyle = css`
  height: 4rem;
  width: auto;
`;

const Footer = () => {
  const theme = useTheme();
  const themedFooterContainerStyle = css`
    ${footerContainerStyle};

    @media (min-width: ${breakpoints.desktop}) {
      border-top: 1px solid ${theme.border1};
    }
  `;
  const themedNavStyle = css`
    ${navStyle};
    border-top: 1px dashed ${theme.border1};
  `;
  const themedSecondaryNavStyle = css`
    ${secondaryNavStyle};
    border-bottom: 1px solid ${theme.border1};
  `;

  return (
    <div className={themedFooterContainerStyle}>
      <nav className={themedNavStyle}>
        <GoToTop className={spacingStyles.marginBottom.lg} />

        <ul className={linkListStyle}>
          <li>
            <NavLink to="/" className={linkStyle} exact>
              <FaHome className={spacingStyles.marginRight.xs} />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/toolkit" className={linkStyle}>
              <FaToolbox className={spacingStyles.marginRight.xs} />
              Toolkit
            </NavLink>
          </li>
          <li>
            <NavLink to="/journal" className={linkStyle}>
              <IoIosJournal className={spacingStyles.marginRight.xs} />
              Journal
            </NavLink>
          </li>
          <li>
            <NavLink to="/rainy-day" className={linkStyle}>
              <IoMdRainy className={spacingStyles.marginRight.xs} />
              Rainy day
            </NavLink>
          </li>
          <li>
            <NavLink to="/favourites" className={linkStyle}>
              <FaHeart className={spacingStyles.marginRight.xs} />
              Favourites
            </NavLink>
          </li>
          <li>
            <NavLink to="/calendar" className={linkStyle}>
              <FaCalendarAlt className={spacingStyles.marginRight.xs} />
              Calendar
            </NavLink>
          </li>
        </ul>
      </nav>

      <ThemeSelect />

      <nav className={themedSecondaryNavStyle}>
        <ul className={linkListStyle}>
          <li>
            <Link
              to="https://www.sheffieldmentalhealth.co.uk/how-to-use-my-toolkit/"
              className={linkStyle}
            >
              Confused by the Toolkit?
            </Link>
          </li>
          <li>
            <NavLink to="/privacy" className={linkStyle}>
              Privacy notice
            </NavLink>
          </li>
        </ul>
      </nav>

      <div className={logoContainerStyle}>
        <NavLink to="/">
          <MyToolkitLogo className={logoStyle} overrideFill />
        </NavLink>
        <a href="https://www.sheffieldmentalhealth.co.uk">
          <SMHGLogo className={logoStyle} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
