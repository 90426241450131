import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { smallBoxShadow } from '@frontend/styles/shadows';
import { fonts, fontWeights, spacings } from '@frontend/styles/variables';
import React, { ReactNode } from 'react';

const style = css`
  border-radius: 2rem;
  ${smallBoxShadow()}
  display: inline-block;
  font-family: ${fonts.heading};
  font-weight: ${fontWeights.bold};
  padding: ${spacings.xs} ${spacings.sm} 0.2rem;

  &:not(:last-child) {
    margin-bottom: ${spacings.md};
    margin-right: ${spacings.xs};
  }
`;

interface Props {
  className?: string;
  children: ReactNode;
}

const Badge = ({ className, children }: Props) => {
  const theme = useTheme();

  const themedStyle = css`
    ${style};
    background-color: ${theme.primary};
    color: ${theme.textContrast};
  `;
  return <div className={cx(themedStyle, className)}>{children}</div>;
};

export default Badge;
