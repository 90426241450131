import { Theme } from '@emotion/react';
import defaultTheme from './default';
import indigoDark from './indigoDark';
import parkLife from './parkLife';
import summerTime from './summer';
import watermelon from './watermelon';
// import autumnDark from './autumnDark';
// import indigo from './indigo';
// import memories from './memories';

export interface Themes {
  [key: string]: Theme;
}

const themes: Themes = {
  default: defaultTheme,
  watermelon,
  summerTime,
  parkLife,
  indigoDark,
  // memories,
  // indigo,
  // autumnDark,
};

export default themes;
