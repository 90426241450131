import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import {
  inputErrorStyle,
  inputStyle,
  themedInputStyle,
} from '@frontend/components/form/FormInput';
import { smallBoxShadow } from '@frontend/styles/shadows';
import { widthStyles } from '@frontend/styles/utils';
import { fonts, headingSizes } from '@frontend/styles/variables';
import { lighten } from 'polished';
import React, { FocusEventHandler, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ErrorMessage from '../ErrorMessage';
import FormLabel from './FormLabel';

export const calendarStyle = css`
  ${smallBoxShadow()};
  font-family: ${fonts.body};
  font-size: 1rem;

  .react-datepicker__header {
    font-family: ${fonts.body};
    text-transform: uppercase;
  }

  .react-datepicker__header__dropdown,
  .react-datepicker__current-month {
    margin-bottom: 0.8rem;
  }

  .react-datepicker-time__header,
  .react-datepicker__current-month {
    font-family: ${fonts.heading};
    font-size: 1.2rem;
  }

  .react-datepicker__year-option,
  .react-datepicker__month-option {
    font-family: ${fonts.body};
    font-size: 1rem;
    padding: 4px 0;
    text-transform: none;
  }

  .react-datepicker__day {
    padding: 0.3rem 0.5rem;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 2.5rem;
  }

  .react-datepicker__time-container {
    width: 86px;
  }

  .react-datepicker__navigation {
    &:focus {
      outline-offset: 2px;
    }
  }

  .react-datepicker__navigation--next--with-time {
    right: 90px !important;
  }
`;

export interface FormDateTimeProps {
  className?: string;
  dateFormat?: string;
  disabled?: boolean;
  error?: string;
  id: string;
  label: string;
  labelSize?: keyof typeof headingSizes;
  name: string;
  placeholder?: string;
  showLabel?: boolean;
  showTime?: boolean;
  timeFormat?: string;
  value: Date | null;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange: (date: Date | null) => void;
}

const FormDateTime = ({
  className,
  dateFormat = 'dd/MM/yyyy',
  error,
  id,
  label,
  labelSize,
  placeholder = 'e.g. 12/12/2020',
  showLabel = true,
  showTime = true,
  value,
  onBlur,
  onChange,
}: FormDateTimeProps) => {
  const theme = useTheme();
  const themedCalendarStyle = css`
    ${calendarStyle};
    color: ${theme.text};

    .react-datepicker__triangle {
      border-bottom-color: ${theme.background} !important;
    }

    .react-datepicker__header {
      background-color: ${theme.background};
      border-color: ${theme.border};
    }

    .react-datepicker-time__header,
    .react-datepicker__current-month {
      color: ${theme.tertiary};
    }

    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown {
      background-color: ${theme.background};
    }

    .react-datepicker__year-option,
    .react-datepicker__month-option {
      font-family: ${fonts.body};

      &:hover {
        background: ${theme.background2};
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__time-list-item--selected {
      background-color: ${theme.primary} !important;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: ${lighten(0.1, theme.primary)};
    }

    .react-datepicker__navigation {
      &:focus {
        outline: 3px solid ${theme.primaryHighlight};
      }
    }
  `;

  return (
    <>
      <FormLabel id={id} show={showLabel} size={labelSize}>
        {label}
      </FormLabel>

      <DatePicker
        id={id}
        className={cx(
          inputStyle,
          themedInputStyle(theme),
          className,
          error && inputErrorStyle,
        )}
        wrapperClassName={widthStyles.full}
        calendarClassName={themedCalendarStyle}
        autoComplete="off"
        dateFormat={dateFormat}
        placeholderText={placeholder}
        selected={value}
        showTimeSelect={showTime}
        showMonthDropdown
        showYearDropdown
        timeFormat="HH:mm"
        timeIntervals={15}
        onBlur={onBlur}
        onChange={useCallback(
          newValue => {
            if (onChange) {
              onChange(newValue);
            }
          },
          [onChange],
        )}
      />

      {error && <ErrorMessage id={`${id}-error`}>{error}</ErrorMessage>}
    </>
  );
};

export default FormDateTime;
