import { Paginated } from '@feathersjs/feathers';
import AsideBox from '@frontend/components/AsideBox';
import FiltersDropdown from '@frontend/components/FiltersDropdown';
import Column from '@frontend/components/grid/Column';
import Row from '@frontend/components/grid/Row';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import NoItemsMessage from '@frontend/components/NoItemsMessage';
import Page from '@frontend/components/Page';
import PageLink from '@frontend/components/PageLink';
import Pagination from '@frontend/components/Pagination';
import MentalHealthGuideAside from '@frontend/pages/favourites/components/MentalHealthGuideAside';
import spacingStyles from '@frontend/styles/spacings';
import React, { ReactElement } from 'react';
import { ActivityResource, ServiceResource } from '@common/types/apiResources';

interface FavouritesAddPageProps<T extends ActivityResource | ServiceResource> {
  addCard: (item: T) => ReactElement;
  emptyText: string;
  filterForm: ReactElement;
  loading: boolean;
  paginated?: Paginated<T>;
  sortDropdown?: ReactElement;
  title: string;
}

const FavouritesAddPage = <T extends ActivityResource | ServiceResource>({
  addCard,
  emptyText,
  filterForm,
  loading,
  paginated,
  sortDropdown,
  title,
}: FavouritesAddPageProps<T>) => {
  return (
    <Page
      title={title}
      intro="’Favourite’ the support services and activities listed on the Sheffield Mental Health Guide and see them appear below. ‘Favourited’ services/activities can then be added to your ‘Toolkit’."
      mobileAsidePosition="top"
      aside={
        <>
          <AsideBox>
            <FiltersDropdown>{filterForm}</FiltersDropdown>
          </AsideBox>

          <MentalHealthGuideAside />
        </>
      }
    >
      <PageLink to="/favourites" arrow="left">
        Back to Favourites
      </PageLink>

      {sortDropdown}

      <LoadingSpinner loading={loading} center size="xl">
        {paginated && (
          <>
            {paginated?.total > 0 ? (
              <Row>
                {paginated.data.map(item => (
                  <Column
                    size={6}
                    key={item.id}
                    className={spacingStyles.marginBottom.lg}
                  >
                    {addCard(item)}
                  </Column>
                ))}
              </Row>
            ) : (
              <NoItemsMessage>{emptyText}</NoItemsMessage>
            )}

            <Pagination {...paginated} aria-label="Service pages" />
          </>
        )}
      </LoadingSpinner>
    </Page>
  );
};

export default FavouritesAddPage;
