import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { TabsPanelProps } from '@frontend/components/TabsPanel';
import { noButtonStyle } from '@frontend/styles/buttons';
import { fontWeights, spacings } from '@frontend/styles/variables';
import { lighten } from 'polished';
import React, { ReactElement, ReactNode } from 'react';
import { Tab, TabList, TabPanel, useTabState } from 'reakit';

const tabListStyle = css`
  display: flex;
  margin-bottom: ${spacings.md};
  padding: 0 ${spacings.sm};
`;

const tabStyle = css`
  ${noButtonStyle};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 0 0 -1px 0;
  padding: 1rem 1.4rem;

  &[aria-selected='true'] {
    border-bottom: 0;
    font-weight: ${fontWeights.semiBold};

    span {
      padding-bottom: 3px;
    }
  }

  &:focus {
    border-bottom: 0;
  }
`;

const panelStyle = css`
  outline: 0;
`;

export interface TabsProps {
  'aria-label': string;
  children: ReactNode;
  selectedTabId?: string;
  className?: string;
}

const Tabs = ({
  'aria-label': ariaLabel,
  children,
  selectedTabId,
  className,
}: TabsProps) => {
  const theme = useTheme();
  const themedTabListStyle = css`
    ${tabListStyle};
    border-bottom: 1px solid ${theme.border1};
  `;
  const themedTabStyle = css`
    ${tabStyle};
    background-color: ${theme.background};
    border-bottom: 1px solid ${theme.border1};
    color: ${theme.link};

    &[aria-selected='true'] {
      border: 1px solid ${theme.border1};

      span {
        border-bottom: 2px solid ${theme.link};
      }
    }

    &:hover {
      color: ${theme.linkHover};

      span {
        border-bottom-color: ${theme.linkHover};
      }
    }

    &:focus {
      border: 1px solid ${lighten(0.1, theme.primaryHighlight)};
    }
  `;
  const childArray = React.Children.toArray(children) as ReactElement[];

  const tab = useTabState({
    selectedId:
      selectedTabId === undefined ? childArray[0].props.id : selectedTabId,
  });

  React.useEffect(() => {
    tab.setSelectedId(selectedTabId);
    // We don't care about the state of 'tab' here but the exhaustive deps say we should
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabId]);

  return (
    <>
      <TabList
        {...tab}
        aria-label={ariaLabel}
        className={cx(themedTabListStyle, className)}
      >
        {childArray.map(child => {
          const { id, title } = child.props as TabsPanelProps;

          return (
            <Tab {...tab} id={id} key={id} className={themedTabStyle}>
              <span>{title}</span>
            </Tab>
          );
        })}
      </TabList>

      {childArray.map(child => {
        const { id, children: panelChildren } = child.props as TabsPanelProps;

        return (
          <TabPanel {...tab} id={id} key={id} className={panelStyle}>
            {panelChildren}
          </TabPanel>
        );
      })}
    </>
  );
};

export default Tabs;
