import React, { useCallback } from 'react';
import Card from '@frontend/components/Card';
import Button from '@frontend/components/Button';
import { positionStyles } from '@frontend/styles/position';
import DismissButton from '@frontend/components/DismissButton';
import { cx } from '@emotion/css';
import { spacings } from '@frontend/styles/variables';
import headingStyles from '@frontend/styles/headings';

interface Props {
  id: string;
  onDelete: (id: string) => void;
}

const FavouriteNotFoundCard = ({ id, onDelete }: Props) => {
  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  return (
    <Card className={positionStyles.relative}>
      <DismissButton
        className={cx(
          positionStyles.absolute,
          positionStyles.top(spacings.sm),
          positionStyles.right(spacings.sm),
        )}
        onClick={handleDelete}
      >
        Delete favourite
      </DismissButton>
      <h3 className={headingStyles.xs}>Favourite not found</h3>
      <p>
        We couldn't find one of your favourites, perhaps it was moved or deleted
        on the{' '}
        <a
          href="https://sheffieldmentalhealth.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sheffield Mental Health Guide
        </a>
        .
      </p>
      <Button onClick={handleDelete}>Delete Favourite</Button>
    </Card>
  );
};

export default FavouriteNotFoundCard;
