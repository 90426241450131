import { Theme } from '@emotion/react';
import { darken, lighten } from 'polished';
import { baseColours } from '../styles/variables';

const themeColours = {
  blueDark: '#272639',
  blue: '#47A4D0',
  green: '#86B648',
  lime: '#DEF365',
};

const parkLife: Theme = {
  background: baseColours.white,
  background1: baseColours.greyWhite,
  background2: baseColours.greyLight,
  border: baseColours.grey,
  border1: baseColours.greyLight,
  noticeBackground: themeColours.lime,
  noticeLinkColor: themeColours.blueDark,
  disabledButtonBackground: baseColours.greyWhite,
  disabledButtonBorder: baseColours.grey,
  disabledButtonColor: baseColours.white,
  error: baseColours.red,
  feedbackBubbleColor: themeColours.lime,
  inputColor: themeColours.blueDark,
  link: darken(0.1, themeColours.blue),
  linkHover: themeColours.blue,
  linkDisabled: baseColours.grey,
  modalDismissBackground: baseColours.greyDark,
  modalDismissColor: baseColours.white,
  primary: themeColours.blue,
  primaryHighlight: lighten(0.1, themeColours.blue),
  primaryAlternate: darken(0.1, themeColours.blue),
  secondary: themeColours.lime,
  secondaryHighlight: themeColours.green,
  success: baseColours.green,
  tertiary: themeColours.blueDark,
  text: baseColours.black,
  text1: baseColours.greyDark,
  text2: baseColours.grey,
  textContrast: baseColours.white,
  textPrimary: themeColours.blueDark,
  warning: baseColours.green,
};

export default parkLife;
