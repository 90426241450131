import { MediaReferenceResource } from '@common/types/apiResources';
import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@emotion/react';
import DismissIcon from '@frontend/components/DismissButton';
import FullScreenButton from '@frontend/components/FullScreenButton';
import ModalButton from '@frontend/components/ModalButton';
import mediaReferenceService from '@frontend/services/mediaReferenceService';
import { positionStyles } from '@frontend/styles/position';
import { heightStyles, widthStyles } from '@frontend/styles/utils';
import { spacings } from '@frontend/styles/variables';
import { invert, rgba } from 'polished';
import React, { ReactNode } from 'react';
import ReactPlayer from 'react-player';

interface PreviewProps {
  mediaReference: MediaReferenceResource;
  preview?: boolean;
}

const PreviewableReactPlayer = ({ mediaReference, preview }: PreviewProps) => {
  const playerRef = React.createRef<ReactPlayer>();

  const fullsize = css`
    height: 75vh !important;
    min-width: 800px;
  `;

  const inline = css`
    display: inline;
    height: 100%;
    width: 100%;
  `;

  return (
    <ReactPlayer
      url={mediaReference.url}
      width="100%"
      height="100%"
      light={preview}
      className={preview ? inline : fullsize}
      ref={playerRef}
      onClickPreview={() => playerRef.current?.showPreview()}
      controls
    />
  );
};

const PreviewableImage = ({ mediaReference, preview }: PreviewProps) => {
  const image = css`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `;

  const inline = css`
    display: inline;
    height: 100%;
    width: 100%;
  `;

  if (preview)
    return (
      <div
        style={{ backgroundImage: `url(${mediaReference.url})` }}
        className={cx(inline, image)}
      />
    );

  return (
    <img
      alt=""
      src={mediaReference.url}
      className={cx(
        inline,
        css`
          max-height: 80vh;
          object-fit: contain;
        `,
      )}
    />
  );
};

interface CircleProps {
  className?: string;
  children: ReactNode;
  colour?: keyof Theme;
  alpha?: number;
}

const Circle = ({
  className,
  children,
  colour = 'text1',
  alpha = 0.5,
}: CircleProps) => {
  const theme = useTheme();

  const circleClass = css`
    background-color: ${rgba(invert(theme[colour]), alpha)};
    border-radius: 50%;
    content: '';
    height: ${spacings.lg};
    position: relative;
    right: -50%;
    user-select: none;
    width: ${spacings.lg};
  `;

  return <span className={cx(circleClass, className)}>{children}</span>;
};

const cardStyle = css`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
`;

interface Props {
  mediaReference: MediaReferenceResource;
  onRemove: (mediaReference: MediaReferenceResource) => Promise<void>;
  colour?: keyof Theme;
  className?: string;
}

const MediaReferenceCard = ({
  mediaReference,
  onRemove,
  colour = 'textContrast',
  className,
}: Props) => {
  const themedCardStyle = css`
    ${cardStyle};
    overflow: hidden;
  `;

  const [isDeleting, setIsDeleting] = React.useState(false);

  const mountedRef = React.useRef(true);
  React.useEffect(() => {
    setIsDeleting(false);
    return () => {
      mountedRef.current = false;
    };
  }, [mediaReference]);

  const handleDelete = React.useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      if (onRemove) {
        e.preventDefault();
        setIsDeleting(true);
        await mediaReferenceService.remove(mediaReference.id);
        await onRemove(mediaReference);

        if (mountedRef.current) setIsDeleting(false);
      }
    },
    [mediaReference, onRemove],
  );

  return (
    <ModalButton
      aria-label={mediaReference.type}
      button={
        <div className={cx(themedCardStyle, className)}>
          <Circle
            className={cx(
              positionStyles.absolute,
              positionStyles.top(spacings.sm),
              positionStyles.right(spacings.sm),
            )}
            colour={colour}
          >
            <DismissIcon
              disabled={isDeleting}
              onClick={handleDelete}
              colour={colour}
              className={cx(
                positionStyles.relative,
                widthStyles.full,
                heightStyles.full,
              )}
            >
              Delete
            </DismissIcon>
          </Circle>

          <Circle
            className={cx(
              positionStyles.absolute,
              positionStyles.bottom(spacings.sm),
              positionStyles.right(spacings.sm),
            )}
            colour={colour}
          >
            <FullScreenButton
              colour={colour}
              className={cx(
                positionStyles.relative,
                widthStyles.full,
                heightStyles.full,
              )}
            >
              Full screen
            </FullScreenButton>
          </Circle>

          {mediaReference.type === 'video' ? (
            <PreviewableReactPlayer mediaReference={mediaReference} preview />
          ) : mediaReference.type === 'image' ? (
            <PreviewableImage mediaReference={mediaReference} preview />
          ) : (
            <></>
          )}
        </div>
      }
    >
      {({ visible }) => (
        <>
          {visible ? (
            <>
              {mediaReference.type === 'video' ? (
                <PreviewableReactPlayer mediaReference={mediaReference} />
              ) : mediaReference.type === 'image' ? (
                <PreviewableImage mediaReference={mediaReference} />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </ModalButton>
  );
};

export default MediaReferenceCard;
