import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@emotion/react';
import { buttonStyle } from '@frontend/components/Button';
import Link from '@frontend/components/Link';
import flexStyles from '@frontend/styles/flex';
import { widthStyles } from '@frontend/styles/utils';
import { lighten } from 'polished';
import React, { forwardRef, ReactNode, Ref } from 'react';
import { LinkProps } from 'react-router-dom';

interface ButtonLinkProps extends LinkProps {
  children: ReactNode;
  className?: string;
  colour?: keyof Theme;
  block?: boolean;
  inverted?: boolean;
  size?: 'sm' | 'md';
  textColour?: keyof Theme;
}

const ButtonLink = (
  {
    block,
    children,
    className,
    colour = 'primary',
    inverted = false,
    size = 'md',
    textColour,
    to,
    ...props
  }: ButtonLinkProps,
  ref: Ref<HTMLAnchorElement>,
) => {
  const theme = useTheme();
  const styles = cx(
    buttonStyle({ colour, inverted, size, textColour, theme }),
    css`
      display: inline-block;
      text-align: center;
      text-decoration: none;

      &:hover,
      &:focus {
        color: ${textColour
          ? lighten(0.05, theme[textColour])
          : theme.textContrast};
        text-decoration: none;
      }
    `,
    className,
    block && widthStyles.full,
  );

  return (
    <Link {...props} className={styles} to={to} ref={ref}>
      <span className={flexStyles.flexCenterAll}>{children}</span>
    </Link>
  );
};

export default forwardRef<HTMLAnchorElement, ButtonLinkProps>(ButtonLink);
