import LoadingSpinner from '@frontend/components/LoadingSpinner';
import useAsyncRetry from '@frontend/hooks/useAsyncRetry';
import favouriteService from '@frontend/services/favouriteService';
import headingStyles from '@frontend/styles/headings';
import { fontWeights, spacings } from '@frontend/styles/variables';
import { css } from '@emotion/css';
import React from 'react';
import spacingStyles from '@frontend/styles/spacings';
import { FaHeart } from 'react-icons/fa';

const RainyDayFavourites = () => {
  const { value: paginated, isLoading } = useAsyncRetry(
    () =>
      favouriteService.find({
        query: {
          $limit: 12,
          $sort: {
            createdAt: -1,
          },
        },
      }),
    [],
  );

  return (
    <div>
      <h2 className={headingStyles.sm}>
        <FaHeart className={spacingStyles.marginRight.xs} size={spacings.md} />
        Favourites
      </h2>

      <LoadingSpinner center loading={isLoading} size="md">
        {paginated && (
          <>
            {paginated?.total > 0 ? (
              <ul
                className={css`
                  font-weight: ${fontWeights.regular};
                `}
              >
                {paginated?.data.map(favourite => (
                  <li key={favourite.id}>
                    <a
                      href={favourite.data?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {favourite.data?.title}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>You have no favourites yet.</p>
            )}
          </>
        )}
      </LoadingSpinner>
    </div>
  );
};

export default RainyDayFavourites;
