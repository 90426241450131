import Link from '@frontend/components/Link';
import flexStyles from '@frontend/styles/flex';
import spacingStyles from '@frontend/styles/spacings';
import { displayStyles, textStyles } from '@frontend/styles/utils';
import { css, cx } from '@emotion/css';
import React from 'react';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { LinkProps } from 'react-router-dom';

interface Props extends LinkProps {
  arrow?: 'left' | 'right';
}

const PageLink = ({
  arrow = 'right',
  to,
  className,
  children,
  ...props
}: Props) => {
  return (
    <Link
      {...props}
      className={cx(
        displayStyles.inlineBlock,
        spacingStyles.marginBottom.md,
        className,
      )}
      to={to}
    >
      <span className={cx(flexStyles.flex, flexStyles.alignItemsCenter)}>
        {arrow === 'left' && (
          <FaCaretLeft
            className={css`
              margin-right: 0.2rem;
            `}
          />
        )}

        <span className={textStyles.lowercase}>{children}</span>

        {arrow === 'right' && (
          <FaCaretRight
            className={css`
              margin-left: 0.2rem;
            `}
          />
        )}
      </span>
    </Link>
  );
};

export default PageLink;
