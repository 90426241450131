import Modal from '@frontend/components/Modal';
import React from 'react';
import { useDialogState } from 'reakit';
import { useUserContext } from '@frontend/context/UserContext';
import ButtonLink from '@frontend/components/ButtonLink';
import Button from '@frontend/components/Button';
import { css } from '@emotion/css';
import userService from '@frontend/services/userService';

const MailingListModal = () => {
  const { user, setUser } = useUserContext();

  const mailingListModal = useDialogState({
    visible: user?.viewedMailingListPopup === false,
  });

  if (user?.viewedMailingListPopup === false) {
    userService.patch(user.id, { viewedMailingListPopup: true }).then(setUser);
  }

  if (!user) {
    return null;
  }

  return (
    <Modal {...mailingListModal}>
      {({ hide }) => (
        <>
          <p>
            We now have a My Toolkit mailing list so that we can send you
            updates and information about new features.
          </p>
          <p>
            If you would like to subscribe, please visit the ‘My Account’
            section and select to opt in to receiving emails.
          </p>

          <div
            className={css`
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
            `}
          >
            <Button onClick={hide} colour="modalDismissBackground">
              Close
            </Button>

            <ButtonLink title="Go to My Account" to="/account">
              Go to My Account
            </ButtonLink>
          </div>
        </>
      )}
    </Modal>
  );
};

export default MailingListModal;
