import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import LoadingPage from '@frontend/components/LoadingPage';
import createAuth0Client, {
  Auth0Options,
} from '@frontend/services/client/auth0Client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';

export interface AppState {
  targetUrl?: string;
}

export interface Auth0User {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
}

interface Auth0ContextState {
  auth0Client?: Auth0Client;
  isAuthenticated: boolean;
  isError?: boolean;
  isLoading: boolean;
  user?: Auth0User;
  onLogout?: () => Promise<void>;
}

const Auth0Context = createContext<Auth0ContextState | undefined>(undefined);

export interface Auth0ContextProviderProps {
  children: ReactNode | ((state: Auth0ContextState) => ReactNode);
  options?: Auth0Options;
  onAuthenticated?: (user: unknown, token: string) => void;
  onLogout?: () => Promise<void>;
}

export const Auth0ContextProvider = ({
  children,
  options,
  onAuthenticated,
  onLogout,
}: Auth0ContextProviderProps) => {
  const history = useHistory();

  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isError, setError] = useState(false);
  const [auth0Client, setAuth0Client] = useState<Auth0Client>();
  const [user, setUser] = useState<Auth0User>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      const client = await createAuth0Client(options);
      setAuth0Client(client);

      if (
        window.location.search.includes('code=') ||
        window.location.search.includes('error_description=')
      ) {
        try {
          const { appState } = await client.handleRedirectCallback();

          if (appState.targetUrl) {
            const u = new URL(appState.targetUrl);
            history.replace(u.pathname);
          } else {
            history.replace('/');
          }
        } catch (e) {
          setError(true);
          setLoading(false);
          return;
        }
      }

      const authenticated = await client.isAuthenticated();
      setAuthenticated(authenticated);

      if (authenticated) {
        const auth0User = await client.getUser();
        const token = await client.getTokenSilently();

        setUser(auth0User);

        if (onAuthenticated) {
          await onAuthenticated(user, token);
        }
      }

      setLoading(false);
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const state = useMemo(() => {
    return {
      isAuthenticated,
      isError,
      isLoading,
      auth0Client,
      user,
      onLogout,
    };
  }, [auth0Client, isAuthenticated, isError, isLoading, user, onLogout]);

  return (
    <Auth0Context.Provider value={state}>
      <LoadingPage loading={isLoading}>
        {typeof children === 'function' ? children(state) : children}
      </LoadingPage>
    </Auth0Context.Provider>
  );
};

export type PageToShow = 'login' | 'forgotPassword' | 'registration';

interface LoginOptions {
  appState?: AppState;
  scope?: string;
  redirectUri?: string;
  screen?: PageToShow;
}

interface LogoutOptions {
  returnTo?: string;
}

export function useAuth0Context() {
  const context = useContext(Auth0Context);

  if (!context) {
    throw new Error(
      'Must have an Auth0ContextProvider further up the component tree',
    );
  }

  const { isAuthenticated, isError, auth0Client, user, onLogout } = context;

  const login = useCallback(
    async (options: LoginOptions = {}) => {
      if (auth0Client) {
        await auth0Client.loginWithRedirect({
          /* eslint-disable @typescript-eslint/camelcase */
          appState: options.appState,
          scope: options.scope,
          redirect_uri: options.redirectUri,
          screen: options.screen,

          /* eslint-enable @typescript-eslint/camelcase */
        });
      }
    },
    [auth0Client],
  );

  const createAccount = useCallback(
    async (options: LoginOptions) => {
      return login({ ...options, screen: 'registration' });
    },
    [login],
  );

  const logout = useCallback(
    async (options: LogoutOptions = {}) => {
      if (auth0Client) {
        if (onLogout) await onLogout();
        await auth0Client.logout(options);
      }
    },
    [auth0Client, onLogout],
  );

  const getToken = useCallback(() => {
    if (auth0Client) {
      return auth0Client.getTokenSilently();
    }

    return Promise.reject();
  }, [auth0Client]);

  return {
    getToken,
    isAuthenticated,
    isError,
    login,
    logout,
    user,
    createAccount,
  };
}
