import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import Button from '@frontend/components/Button';
import { useAuth0Context } from '@frontend/context/Auth0Context';
import { useMaxWidthMedia } from '@frontend/hooks/useMedia';
import { smallBoxShadow } from '@frontend/styles/shadows';
import spacingStyles from '@frontend/styles/spacings';
import { breakpoints, fontWeights, spacings } from '@frontend/styles/variables';
import React from 'react';
import {
  FaCalendarAlt,
  FaChevronDown,
  FaHeart,
  FaHome,
  FaToolbox,
} from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit';
import { IoIosJournal, IoMdRainy } from 'react-icons/all';
import MyToolkitLogo from '@frontend/components/MyToolkitLogo';

const navContainerStyle = css`
  display: block;
  padding: 0;
  width: 100%;

  @media (max-width: ${breakpoints.desktop}) {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const navStyle = css`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${spacings.lg};
  max-width: 100%;

  @media (max-width: ${breakpoints.desktop}) {
    ${smallBoxShadow()};
    align-items: center;
    justify-content: center;
    max-width: 300px;
    padding: ${spacings.sm} 0;
    position: relative;
    width: 100%;
    z-index: 999;
  }
`;

export const linkListStyle = css`
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  @media (max-width: ${breakpoints.desktop}) {
    align-items: center;
    flex-direction: column;
  }

  li {
    display: inline-block;
    margin-bottom: ${spacings.md};
    margin-right: 0;

    @media (min-width: ${breakpoints.desktop}) {
      margin-right: ${spacings.md};
    }
  }
`;

export const linkStyle = css`
  border-bottom: 2px solid transparent;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: ${fontWeights.regular};
  outline: none;
  padding: ${spacings.xs};
  text-transform: lowercase;
  white-space: nowrap;

  &[aria-current='page'],
  &:hover,
  &:focus {
    text-decoration: none;
    transition: border-bottom 0.2s ease-in-out, color 0.2s ease-in-out;
  }
`;

const logoNavBarListStyle = css`
  display: inline-flex;
  flex-direction: row-reverse;
`;

const logoNavBarLinkStyle = css`
  width: 50%;
`;

interface Props {
  title?: string;
}

const NavBar = ({ title }: Props) => {
  const theme = useTheme();
  const themedNavStyle = css`
    ${navStyle};
    border-bottom: 1px solid ${theme.border1};

    @media (max-width: ${breakpoints.desktop}) {
      background: ${theme.background1};
    }
  `;

  const themedLinkStyle = css`
    ${linkStyle};

    &[aria-current='page'],
    &:hover,
    &:focus {
      border-bottom: 2px solid ${theme.linkHover};
    }
  `;

  const { logout } = useAuth0Context();
  const mediaMatches = useMaxWidthMedia('desktop');

  const disclosure = useDisclosureState({
    visible: false,
  });

  return (
    <div className={navContainerStyle}>
      {mediaMatches && (
        <Disclosure
          {...disclosure}
          className={css`
            padding: ${spacings.xs} ${spacings.md};
          `}
          as={Button}
          size="sm"
          colour="secondaryHighlight"
          textColour="textPrimary"
        >
          {title === 'Welcome' ? 'Home' : title}
          <FaChevronDown className={spacingStyles.marginLeft.md} />
        </Disclosure>
      )}
      <DisclosureContent {...disclosure}>
        {disclosureProps => (
          <nav
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(mediaMatches ? disclosureProps : {})}
            className={themedNavStyle}
          >
            <ul className={linkListStyle}>
              <li>
                <NavLink to="/" className={logoNavBarListStyle} exact>
                  <MyToolkitLogo className={logoNavBarLinkStyle} />
                </NavLink>
              </li>
              <li>
                <NavLink to="/home" className={themedLinkStyle} exact>
                  <FaHome className={spacingStyles.marginRight.xs} />
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/toolkit" className={themedLinkStyle}>
                  <FaToolbox className={spacingStyles.marginRight.xs} />
                  Toolkit
                </NavLink>
              </li>
              <li>
                <NavLink to="/journal" className={themedLinkStyle}>
                  <IoIosJournal className={spacingStyles.marginRight.xs} />
                  Journal
                </NavLink>
              </li>
              <li>
                <NavLink to="/rainy-day" className={themedLinkStyle}>
                  <IoMdRainy className={spacingStyles.marginRight.xs} />
                  Rainy day
                </NavLink>
              </li>
              <li>
                <NavLink to="/favourites" className={themedLinkStyle}>
                  <FaHeart className={spacingStyles.marginRight.xs} />
                  Favourites
                </NavLink>
              </li>
              <li>
                <NavLink to="/calendar" className={themedLinkStyle}>
                  <FaCalendarAlt className={spacingStyles.marginRight.xs} />
                  Calendar
                </NavLink>
              </li>
              {mediaMatches && (
                <>
                  <li>
                    <NavLink to="/account" className={themedLinkStyle}>
                      My account
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/log-out"
                      exact
                      className={themedLinkStyle}
                      onClick={async () => {
                        await logout({ returnTo: window.location.origin });
                      }}
                    >
                      Log Out
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </nav>
        )}
      </DisclosureContent>
    </div>
  );
};

export default NavBar;
