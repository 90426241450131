import ProtectedRoute from '@frontend/components/ProtectedRoute';
import { useAuth0Context } from '@frontend/context/Auth0Context';
import AccountPage from '@frontend/pages/account/AccountPage';
import CalendarPage from '@frontend/pages/calendar/CalendarPage';
import EmailVerifyPage from '@frontend/pages/EmailVerifyPage';
import ErrorPage from '@frontend/pages/ErrorPage';
import FavouritesActivitiesPage from '@frontend/pages/favourites/FavouritesActivitiesPage';
import FavouritesExternalPage from '@frontend/pages/favourites/FavouritesExternalPage';
import FavouritesPage from '@frontend/pages/favourites/FavouritesPage';
import FavouritesServicesPage from '@frontend/pages/favourites/FavouritesServicesPage';
import HomePage from '@frontend/pages/HomePage';
import JournalPage from '@frontend/pages/journal/JournalPage';
import LandingPage from '@frontend/pages/LandingPage';
import PrivacyPage from '@frontend/pages/PrivacyPage';
import PrivacyPublicPage from '@frontend/pages/PrivacyPublicPage';
import RainyDayPage from '@frontend/pages/rainy-day/RainyDayPage';
import ToolkitCompletedPage from '@frontend/pages/toolkit/ToolkitCompletedPage';
import ToolkitPage from '@frontend/pages/toolkit/ToolkitPage';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const Routes = () => {
  const { isAuthenticated } = useAuth0Context();

  return (
    <Switch>
      {!isAuthenticated && (
        <Route exact path="/privacy" component={PrivacyPublicPage} />
      )}

      {!isAuthenticated && (
        <Route exact path="/email-verify" component={EmailVerifyPage} />
      )}

      <Route exact path="/" component={LandingPage} />
      <ProtectedRoute exact path="/home" component={HomePage} />
      <ProtectedRoute exact path="/privacy" component={PrivacyPage} />
      <ProtectedRoute exact path="/account" component={AccountPage} />
      <ProtectedRoute exact path="/toolkit" component={ToolkitPage} />
      <ProtectedRoute
        exact
        path="/toolkit/completed/:categoryId"
        component={ToolkitCompletedPage}
      />
      <ProtectedRoute exact path="/journal" component={JournalPage} />
      <ProtectedRoute exact path="/rainy-day" component={RainyDayPage} />
      <ProtectedRoute exact path="/favourites" component={FavouritesPage} />
      <ProtectedRoute
        exact
        path="/favourites/services"
        component={FavouritesServicesPage}
      />
      <ProtectedRoute
        exact
        path="/favourites/activities"
        component={FavouritesActivitiesPage}
      />
      <ProtectedRoute exact path="/calendar" component={CalendarPage} />
      <ProtectedRoute
        exact
        path="/favourites/services/external/:typeId"
        render={props => {
          return <FavouritesExternalPage {...props} type="services" />;
        }}
      />
      <ProtectedRoute
        exact
        path="/favourites/activities/external/:typeId"
        render={props => {
          return <FavouritesExternalPage {...props} type="activities" />;
        }}
      />
      <Route path="*">
        <ErrorPage title="Page not found">
          <p>Sorry, we couldn't find the page you're looking for.</p>
        </ErrorPage>
      </Route>
    </Switch>
  );
};

export default Routes;
