import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { noButtonStyle } from '@frontend/styles/buttons';
import { themedFocusOutline } from '@frontend/styles/focus';
import { fonts } from '@frontend/styles/variables';
import React, { MouseEventHandler, ReactNode } from 'react';

const style = css`
  ${noButtonStyle};
  cursor: pointer;
  font-family: ${fonts.heading};
  font-size: 1rem;

  &:disabled {
    cursor: default;
  }
`;

export interface ButtonTextProps {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  title?: string;
}

const ButtonText = ({
  children,
  className,
  disabled,
  onClick,
  title,
}: ButtonTextProps) => {
  const theme = useTheme();
  const themedStyle = css`
    ${style}
    ${themedFocusOutline(theme)}
    color: ${theme.link};

    &:disabled {
      color: ${theme.linkDisabled}
    }
  `;

  return (
    <button
      className={cx(themedStyle, className)}
      disabled={disabled}
      onClick={onClick}
      title={title}
      type="button"
    >
      {children}
    </button>
  );
};

export default ButtonText;
