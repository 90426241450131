import PublicPage from '@frontend/components/PublicPage';
import ButtonLink from '@frontend/components/ButtonLink';
import useQuery from '@frontend/hooks/useQuery';
import { textStyles } from '@frontend/styles/utils';
import React from 'react';
import { Helmet } from 'react-helmet-async';

interface QueryParams {
  success?: 'true' | 'false';
  email?: string;
  message?: string;
}

const EmailVerifyPage = () => {
  const { success } = useQuery<QueryParams>();

  if (success === 'true') {
    return (
      <PublicPage title="Your email address has been verified" logo>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>

        <p>Click the button below to get started with My Toolkit.</p>

        <ButtonLink to="/">Login to My Toolkit</ButtonLink>
      </PublicPage>
    );
  }

  return (
    <PublicPage title="Could not verify your email address" logo>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <p className={textStyles.center}>
        If this is preventing you from logging in, please contact us at{' '}
        <a href="mail:mhguide@sheffieldflourish.co.uk">
          mhguide@sheffieldflourish.co.uk
        </a>
      </p>
    </PublicPage>
  );
};

export default EmailVerifyPage;
