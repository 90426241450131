import { GoalCategoryResource, GoalResource } from '@common/types/apiResources';
import AsideBox from '@frontend/components/AsideBox';
import Link from '@frontend/components/Link';
import Page from '@frontend/components/Page';
import PageLink from '@frontend/components/PageLink';
import Separator from '@frontend/components/Separator';
import { useCompletedGoalsContext } from '@frontend/context/CompletedGoalsContext';
import GoalList from '@frontend/pages/components/GoalList';
import GoalCategoryDescription from '@frontend/pages/toolkit/components/GoalCategoryDescription';
import GoalCategoryGroup from '@frontend/pages/toolkit/components/GoalCategoryGroup';
import goalCategoryService from '@frontend/services/goalCategoryService';
import goalService from '@frontend/services/goalService';
import headingStyles from '@frontend/styles/headings';
import spacingStyles from '@frontend/styles/spacings';
import tryAsyncToast from '@frontend/utils/tryAsyncToast';
import { cx } from '@emotion/css';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import linkService from '@frontend/services/linkService';
import Collapse from '@frontend/components/Collapse';
import { FaToolbox, FaClipboardCheck } from 'react-icons/fa';
import { spacings } from '@frontend/styles/variables';

const ToolkitPage = () => {
  const { refresh: refreshCompletedGoals } = useCompletedGoalsContext();
  const [goalCategories, updateGoalCategories] = useImmer<
    GoalCategoryResource[]
  >([]);

  const goals: GoalResource[] = useMemo(() => {
    return goalCategories.reduce((acc, goalCategory) => {
      acc.push(...(goalCategory.goals ?? []));
      return acc;
    }, [] as GoalResource[]);
  }, [goalCategories]);

  const handleCreate = useCallback(
    (goal: Partial<GoalResource>, { createLink } = { createLink: false }) =>
      tryAsyncToast(
        async () => {
          const savedGoal = await goalService.create(goal);

          const { link } = goal;
          if (createLink && link) {
            await linkService.create({
              title: link,
              url: link,
              description: `This link was added to one of your goals, so we added it to your favourites.`,
            });
          }

          updateGoalCategories(draft => {
            draft.forEach(goalCategory => {
              if (
                goalCategory.id === savedGoal.categoryId &&
                goalCategory.goals
              ) {
                goalCategory.goals.push(savedGoal);
              }
            });
          });
        },
        {
          success: 'Successfully created goal',
          error: 'Could not create goal',
        },
      ),
    [updateGoalCategories],
  );

  const handleComplete = useCallback(
    async (goalId: string) => {
      const completedAt = new Date().toISOString();

      await goalService.patch(goalId, {
        completedAt,
      });

      updateGoalCategories(draft =>
        draft.forEach(goalCategory => {
          if (goalCategory.goals) {
            const matchingGoal = goalCategory.goals.find(
              goal => goal.id === goalId,
            );

            if (matchingGoal) {
              matchingGoal.completedAt = completedAt;
            }
          }
        }),
      );

      refreshCompletedGoals();
    },
    [refreshCompletedGoals, updateGoalCategories],
  );

  const handleDelete = useCallback(
    async (goalId: string) => {
      await goalService.remove(goalId);

      updateGoalCategories(draft =>
        draft.forEach(goalCategory => {
          if (goalCategory.goals) {
            const idx = goalCategory.goals.findIndex(
              goal => goal.id === goalId,
            );
            if (idx >= 0) goalCategory.goals.splice(idx, 1);
          }
        }),
      );

      refreshCompletedGoals();
    },
    [refreshCompletedGoals, updateGoalCategories],
  );

  useEffect(() => {
    goalCategoryService
      .find({
        query: {
          $eager: 'goals',
          $modifyEager: {
            goals: {
              completedAt: 'null',
            },
          },
        },
      })
      .then(page => {
        updateGoalCategories(_ => page.data);
      });
  }, [updateGoalCategories]);

  return (
    <Page
      title="Toolkit"
      titleIcon={FaToolbox}
      intro={
        <p>
          This is your space to bring together your ideas, services and
          activities to support you with your mental health (relating to the{' '}
          <a
            href="https://www.sheffieldmentalhealth.co.uk/wellbeing"
            target="_blank"
            rel="noopener noreferrer"
          >
            ‘5 ways to wellbeing’
          </a>
          ). Use the <Link to="/favourites">’favourites’</Link> tab to find
          services and activities you would like to get involved with, and you
          can add them to your toolkit below (or you can add your own). When
          you’ve done them mark them as ‘complete’, and they’ll appear in your{' '}
          <Link to="/journal">‘journal’</Link>.
        </p>
      }
      aside={
        <AsideBox>
          <h2 className={headingStyles.sm}>
            <FaClipboardCheck
              className={spacingStyles.marginRight.xs}
              size={spacings.md}
            />
            Things to do
          </h2>
          <GoalList goals={goals} />
        </AsideBox>
      }
      easyreadPdf="My Toolkit - Toolkit"
    >
      {goalCategories.map(goalCategory => (
        <React.Fragment key={goalCategory.id}>
          <Collapse
            title={goalCategory.name}
            header={
              <PageLink
                to={`/toolkit/completed/${goalCategory.id}`}
                className={cx(
                  spacingStyles.marginLeft.md,
                  spacingStyles.marginBottom.none,
                )}
              >
                View completed
              </PageLink>
            }
          >
            {collapsed => (
              <>
                <GoalCategoryDescription id={goalCategory.id} />

                {!collapsed ? (
                  <>
                    <GoalCategoryGroup
                      key={goalCategory.id}
                      categoryId={goalCategory.id}
                      goals={goalCategory.goals}
                      onCreate={handleCreate}
                      onComplete={handleComplete}
                      onDelete={handleDelete}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Collapse>

          <Separator variant="dashed" />
        </React.Fragment>
      ))}
    </Page>
  );
};

export default ToolkitPage;
