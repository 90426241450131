import { themedFocusOutline } from '@frontend/styles/focus';
import {
  fonts,
  fontWeights,
  headingSizes,
  spacings,
} from '@frontend/styles/variables';
import { css } from '@emotion/css';
import { Theme } from '@emotion/react';

const globalTypographyStyles = css`
  @font-face {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: bold;
    src: url('https://my-toolkit-web.s3.eu-west-2.amazonaws.com/assets/fonts/leaguespartan-bold.eot');
    src: url('https://my-toolkit-web.s3.eu-west-2.amazonaws.com/assets/fonts/leaguespartan-bold.eot?#iefix')
        format('embedded-opentype'),
      url('https://my-toolkit-web.s3.eu-west-2.amazonaws.com/assets/fonts/leaguespartan-bold.woff2')
        format('woff2'),
      url('https://my-toolkit-web.s3.eu-west-2.amazonaws.com/assets/fonts/leaguespartan-bold.woff')
        format('woff'),
      url('https://my-toolkit-web.s3.eu-west-2.amazonaws.com/assets/fonts/leaguespartan-bold.ttf')
        format('truetype'),
      url('https://my-toolkit-web.s3.eu-west-2.amazonaws.com/assets/fonts/leaguespartan-bold.svg#league_spartanbold')
        format('svg');
  }

  html {
    font-family: ${fonts.body};
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.6;
    text-rendering: optimizeLegibility;
  }

  h1 {
    font-size: ${headingSizes.xl};
    margin-bottom: ${spacings.xl};
  }

  h2 {
    font-size: ${headingSizes.lg};
    margin-bottom: ${spacings.lg};
  }

  h3 {
    font-size: ${headingSizes.md};
  }

  h4 {
    font-size: ${headingSizes.sm};
  }

  h5 {
    font-size: ${headingSizes.xs};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${fonts.heading};
    font-weight: ${fontWeights.bold};
    margin-bottom: ${spacings.md};
    margin-top: 0;
    overflow-wrap: break-word;
  }

  ul {
    padding-left: ${spacings.md};
  }

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
      text-underline-position: under;
    }
  }

  p,
  ul,
  ol {
    font-size: 1rem;
    font-weight: ${fontWeights.light};
    margin-bottom: ${spacings.md};
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }

    a:focus {
      outline-offset: 2px;
      outline-width: 2px;
    }
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: ${fontWeights.bold};
  }

  small {
    font-size: 0.8rem;
  }

  dl {
    font-weight: ${fontWeights.light};
    margin-bottom: ${spacings.md};
    margin-top: 0;
  }

  dt {
    font-weight: ${fontWeights.regular};
  }

  dd {
    margin-bottom: ${spacings.xs};
    margin-left: 0;
  }
`;

export const themedGlobalTypographyStyles = (theme: Theme): string => `
  body {
    background: ${theme.background};
    color: ${theme.text};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${theme.tertiary};
  }

  a {
    ${themedFocusOutline(theme)};
    color: ${theme.link};

    &:hover,
    &:focus {
      color: ${theme.linkHover};
    }
  }

  dt {
    color: ${theme.primaryAlternate};
  }
`;

export default globalTypographyStyles;
