import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import DismissButton from '@frontend/components/DismissButton';
import flexStyles from '@frontend/styles/flex';
import { positionStyles } from '@frontend/styles/position';
import { smallBoxShadow } from '@frontend/styles/shadows';
import { breakpoints, shadow, spacings } from '@frontend/styles/variables';
import findFirstFocusable from '@frontend/utils/dom/findFirstFocusable';
import { darken, lighten } from 'polished';
import React, { ReactNode, useEffect, useRef } from 'react';
import { Dialog, DialogBackdrop, DialogStateReturn, Portal } from 'reakit';

const backdropStyle = css`
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

const dialogStyle = css`
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 4rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  &:focus {
    outline: 0;
  }
`;

const modalStyle = css`
  border: 5px solid transparent;
  border-radius: 10px;
  box-shadow: 0 5px 20px ${shadow};
  max-width: 95vw;
  min-width: 95vw;
  padding: ${spacings.lg} ${spacings.sm};
  position: relative;
  transition: background-color 1s ease-in-out;
  width: 100%;
  z-index: 2;

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 90vw;
    min-width: 500px;
    padding: ${spacings.xl} ${spacings.lg};
  }

  @media (min-width: ${breakpoints.desktop}) {
    max-width: 70vw;
    min-width: 600px;
  }
`;

const dismissStyle = css`
  ${smallBoxShadow()}
  border-radius: 8px 8px 0 0;
  padding: 1rem 1.5rem;
  position: absolute;
  right: 1rem;
  top: -3.2rem;
  z-index: 1;

  &:focus,
  &:hover {
    outline: 0;
  }
`;

export interface ModalRenderProps {
  visible: boolean;
  hide: () => void;
  show: () => void;
  toggle: () => void;
}

export interface ModalProps extends DialogStateReturn {
  'aria-label'?: string;
  children: ReactNode | ((props: ModalRenderProps) => ReactNode);
}

const Modal = ({
  'aria-label': ariaLabel,
  children,
  ...dialog
}: ModalProps) => {
  const theme = useTheme();
  const themedModalStyle = css`
    ${modalStyle};
    background-color: ${theme.background};
  `;
  const themedDismissStyle = css`
    ${dismissStyle};
    background: ${darken(0.1, theme.modalDismissBackground)};

    &:focus,
    &:hover {
      background: ${lighten(0.1, theme.modalDismissBackground)};
    }
  `;

  const contentRef = useRef<HTMLDivElement>(null);

  const { visible, hide, toggle } = dialog;

  useEffect(() => {
    if (visible && contentRef.current) {
      const focusableElement = findFirstFocusable(contentRef.current);

      if (focusableElement) {
        focusableElement.focus();
      }
    }
  }, [visible]);

  return (
    <>
      <Portal>
        <DialogBackdrop {...dialog} className={backdropStyle} />
      </Portal>

      <Dialog
        {...dialog}
        aria-label={ariaLabel}
        className={dialogStyle}
        hideOnClickOutside={false}
      >
        <div className={flexStyles.flexCenterAll}>
          <div className={positionStyles.relative}>
            <DismissButton
              onClick={hide}
              className={themedDismissStyle}
              showText
              colour="modalDismissColor"
            >
              Close
            </DismissButton>

            <div className={themedModalStyle}>
              <div ref={contentRef}>
                {typeof children === 'function'
                  ? children({ visible, hide, show: dialog.show, toggle })
                  : children}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Modal;
