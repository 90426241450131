import { useAuth0Context } from '@frontend/context/Auth0Context';
import React, { useEffect } from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';

const ProtectedRoute = ({
  component: Component,
  children,
  render,
  ...props
}: RouteProps) => {
  const location = useLocation();

  const { isAuthenticated, isError, login, logout } = useAuth0Context();

  useEffect(() => {
    if (isError) {
      logout({
        returnTo: window.location.origin,
      });
    } else if (!isAuthenticated) {
      login({
        appState: {
          targetUrl: `${window.location.origin}/home`,
        },
      });
    }
  }, [isAuthenticated, isError, location.pathname, login, logout]);

  return (
    <Route
      {...props}
      render={routeProps => {
        if (Component) {
          return isAuthenticated ? <Component {...routeProps} /> : null;
        }

        if (render) {
          return isAuthenticated ? render(routeProps) : null;
        }

        return isAuthenticated ? children : null;
      }}
    />
  );
};

export default ProtectedRoute;
