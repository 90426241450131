import { EventResource } from '@common/types/apiResources';
import Modal from '@frontend/components/Modal';
import CalendarEventForm from '@frontend/pages/calendar/components/CalendarEventForm';
import eventService from '@frontend/services/eventService';
import headingStyles from '@frontend/styles/headings';
import { endOfDay, format, startOfDay } from 'date-fns/esm';
import React, { ReactNode } from 'react';
import { DialogStateReturn } from 'reakit';
import { Frequency, RRule } from 'rrule';

const frequencyMap = {
  daily: Frequency.DAILY,
  weekly: Frequency.WEEKLY,
  monthly: Frequency.MONTHLY,
};

interface Props extends DialogStateReturn {
  buttons: ReactNode;
  id: string;
  event?: EventResource;
  onSubmit?: (event: EventResource) => void;
}

const CalendarEventModal = ({
  buttons,
  event,
  id,
  onSubmit,
  ...props
}: Props) => {
  return (
    <Modal {...props}>
      {modal => {
        return (
          <>
            <h1 className={headingStyles.md}>
              {event ? 'Update event' : 'Add event'}
            </h1>

            <CalendarEventForm
              id={id}
              initialValues={
                event
                  ? {
                      ...event,
                    }
                  : undefined
              }
              buttons={buttons}
              onSubmit={async ({
                title,
                startDate,
                endDate,
                startTime = '00:00',
                endTime = '23:59',
                recurrence,
              }) => {
                let rrule: RRule | undefined;

                if (recurrence) {
                  const [hours, minutes] = startTime.split(':');

                  rrule = new RRule({
                    freq: frequencyMap[recurrence],
                    dtstart: startOfDay(startDate),
                    until: endOfDay(endDate),
                    byhour: +hours,
                    byminute: +minutes,
                  });
                }

                let savedEvent: EventResource;

                if (event) {
                  savedEvent = await eventService.patch(event.id, {
                    title,
                    rrule: rrule?.toString() ?? '',
                    startTime,
                    endTime,
                    startDate: format(startDate, 'yyyy-MM-dd'),
                    endDate: format(endDate, 'yyyy-MM-dd'),
                  });
                } else {
                  savedEvent = await eventService.create({
                    title,
                    rrule: rrule?.toString() ?? '',
                    startTime,
                    endTime,
                    startDate: format(startDate, 'yyyy-MM-dd'),
                    endDate: format(endDate, 'yyyy-MM-dd'),
                  });
                }

                modal.hide();

                if (onSubmit) {
                  onSubmit(savedEvent);
                }
              }}
            />
          </>
        );
      }}
    </Modal>
  );
};

export default CalendarEventModal;
