import { isMinLength } from '@common/validations';
import validateFields from '@common/validations/validateFields';
import Button from '@frontend/components/Button';
import FormField from '@frontend/components/form/field/FormField';
import FormGroup from '@frontend/components/form/FormGroup';
import FormInput from '@frontend/components/form/FormInput';
import { Form, Formik } from 'formik';
import React from 'react';

export interface ServiceFiltersFormValues {
  searchTerm: string;
}

interface Props {
  initialValues?: ServiceFiltersFormValues;
  onSubmit: (values: ServiceFiltersFormValues) => void;
}

const ServiceFiltersForm = ({
  initialValues = { searchTerm: '' },
  onSubmit,
}: Props) => {
  return (
    <Formik<ServiceFiltersFormValues>
      initialValues={initialValues}
      validate={values =>
        validateFields<ServiceFiltersFormValues>({
          searchTerm: value => {
            if (!value) {
              return '';
            }

            return isMinLength(3)(value);
          },
        })(values)
      }
      onSubmit={onSubmit}
    >
      <Form>
        <FormGroup>
          <FormField
            name="searchTerm"
            as={FormInput}
            label="Search"
            showLabel={false}
            placeholder="Search services"
            id="serviceFiltersForm-searchTerm"
          />
        </FormGroup>

        <Button type="submit" block>
          Filter results
        </Button>
      </Form>
    </Formik>
  );
};

export default ServiceFiltersForm;
