import DropdownMenu from '@frontend/components/DropdownMenu';
import DropdownMenuItem from '@frontend/components/DropdownMenuItem';
import useQuery from '@frontend/hooks/useQuery';
import { noButtonStyle } from '@frontend/styles/buttons';
import { themedFocusOutline } from '@frontend/styles/focus';
import spacingStyles from '@frontend/styles/spacings';
import { css, cx } from '@emotion/css';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { FaSortDown } from 'react-icons/fa';
import { useTheme } from '@emotion/react';

interface SortOption {
  value: string;
  label: string;
}

interface Props {
  'aria-label': string;
  className?: string;
  options: SortOption[];
  onChange?: (option: SortOption) => void;
  sortKey?: string;
}

const SortDropdown = ({
  'aria-label': ariaLabel,
  className,
  options = [],
  onChange,
  sortKey = 'sort',
}: Props) => {
  const theme = useTheme();
  const menuButtonStyle = css`
    ${noButtonStyle};
    ${themedFocusOutline(theme)};
    color: ${theme.link};
  `;

  const query = useQuery();

  const [selected, setSelected] = useState<SortOption>(options[0]);

  useEffect(() => {
    const selectedOption = options.find(
      option => option.value === query[sortKey],
    );

    if (selectedOption) {
      setSelected(selectedOption);
    }
  }, [options, query, sortKey]);

  return (
    <DropdownMenu
      aria-label={ariaLabel}
      button={
        <button type="button" className={cx(menuButtonStyle, className)}>
          {selected.label}
          <FaSortDown className={spacingStyles.marginLeft.xs} />
        </button>
      }
    >
      {options.map(option => (
        <DropdownMenuItem
          key={option.value}
          to={qs.stringify(
            {
              ...query,
              [sortKey]: option.value,
            },
            {
              addQueryPrefix: true,
            },
          )}
          onClick={() => {
            setSelected(option);

            if (onChange) {
              onChange(option);
            }
          }}
        >
          {option.label}
        </DropdownMenuItem>
      ))}
    </DropdownMenu>
  );
};

export default SortDropdown;
