import {
  MediaReferenceResource,
  MediaReferenceType,
  RainyDayResource,
  UploadUrlResource,
} from '@common/types/apiResources';
import apiClient from '@frontend/services/client/apiClient';
import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import s3, { S3Service } from '@frontend/services/s3Service';
import { BadRequest } from '@feathersjs/errors';
import rainyDayService from '@frontend/services/rainyDayService';
import maximumFileSize from '@common/validations/maximumFileSize';

interface MedaiReferenceService {
  find(userId: string): Promise<MediaReferenceResource[]>;

  create(
    data: Partial<MediaReferenceResource>,
  ): Promise<MediaReferenceResource>;

  remove(id: string): Promise<void>;
}

const mediaReferenceService: MedaiReferenceService = apiClient.service(
  '/api/mediaReference',
);

export const uploadReferencedFile = async <T extends UploadUrlResource>(
  file: File,
  type: MediaReferenceType,
  userId: string,
  s3Service: S3Service<T>,
  subType: 'rainy-day' = 'rainy-day',
) => {
  const s3Document = await s3Service.create({
    type: subType,
    fileType: file.type,
    name: file.name,
  });

  const data = new FormData();

  Object.entries(s3Document.fields).forEach(([key, value]) =>
    data.append(key, value),
  );

  data.append('file', file);

  try {
    await axios.post(s3Document.url, data);
  } catch (err) {
    if (err.response.status === 400) {
      const body = err.response.data as string;
      if (body.includes('<Code>EntityTooLarge</Code>')) {
        const maxmimumFileSizeBytes = maximumFileSize[type];
        const maximumFileSizeMb = Math.floor(maxmimumFileSizeBytes / 1000000);
        throw new BadRequest(
          `The selected file exceeds the maximum allowed ${maximumFileSizeMb}MB`,
        );
      }
    }

    throw err;
  }

  return mediaReferenceService.create({
    type,
    url: `${s3Document.url}/${s3Document.fields.key}`,
    userId,
    title: file.name,
  });
};

export const updateRainyDayMediaReferences = async (
  rainyDay: RainyDayResource | undefined,
  mediaReferences: MediaReferenceResource[] | undefined,
) => {
  let newRainyDay: RainyDayResource;
  if (rainyDay === undefined) {
    newRainyDay = await rainyDayService.create({});
  } else {
    newRainyDay = { ...rainyDay };
  }
  return {
    ...newRainyDay,
    mediaReferences,
  };
};

export default mediaReferenceService;
