import { css } from '@emotion/css';

// eslint-disable-next-line import/prefer-default-export
export const noButtonStyle = css`
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
`;
