import { FavouriteResource } from '@common/types/apiResources';
import { useTheme } from '@emotion/react';
import Button from '@frontend/components/Button';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import NoItemsMessage from '@frontend/components/NoItemsMessage';
import Pagination from '@frontend/components/Pagination';
import useAsyncRetry from '@frontend/hooks/useAsyncRetry';
import favouriteService from '@frontend/services/favouriteService';
import themedTableStyle from '@frontend/styles/table';
import React, { useState } from 'react';
import { VisuallyHidden } from 'reakit';
import { Paginated } from '@feathersjs/feathers';
import Link from '@frontend/components/Link';

interface Props {
  type: 'service' | 'activity';
  onSelect: (favourite: FavouriteResource) => void;
  onTableLoad?: (data: Paginated<FavouriteResource>) => void;
}

const GoalFavouriteTable = ({ type, onSelect, onTableLoad }: Props) => {
  const theme = useTheme();
  const [page, setPage] = useState(1);

  const $limit = 6;
  const $skip = page > 1 ? $limit * (page - 1) : 0;

  const { value: paginated, isLoading } = useAsyncRetry(async () => {
    const result = await favouriteService.find({
      query: {
        type,
        $limit,
        $skip,
      },
    });
    if (onTableLoad) onTableLoad(result);
    return result;
  }, [type, $skip, $limit]);

  return (
    <LoadingSpinner loading={isLoading} center size="xl">
      {paginated && (
        <>
          {paginated?.total > 0 ? (
            <>
              <table className={themedTableStyle(theme)}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>
                      <VisuallyHidden>Action</VisuallyHidden>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginated?.data.map(favourite => (
                    <tr key={favourite.id}>
                      <td>{favourite.data?.title}</td>
                      <td>
                        <Button
                          size="sm"
                          onClick={() => {
                            onSelect(favourite);
                          }}
                        >
                          Add as goal
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Pagination
                {...paginated}
                aria-label="Favourite pages"
                onChange={setPage}
              />
            </>
          ) : (
            <NoItemsMessage>
              <p>
                No favourite {type === 'service' ? 'Services' : 'Activites'}{' '}
                have been set
              </p>
              <p>
                You can do this in the <Link to="/favourites">Favourites</Link>{' '}
                section of the Toolkit.
              </p>
            </NoItemsMessage>
          )}
        </>
      )}
    </LoadingSpinner>
  );
};

export default GoalFavouriteTable;
