import { OmitStrict } from '@common/types/util';
import Button from '@frontend/components/Button';
import ButtonGroup from '@frontend/components/ButtonGroup';
import Modal, { ModalProps } from '@frontend/components/Modal';
import headingStyles from '@frontend/styles/headings';
import { spacings } from '@frontend/styles/variables';
import Cropper from 'cropperjs';
import { css } from '@emotion/css';
import React, { useCallback, useEffect, useRef } from 'react';

const containerStyle = css`
  display: flex;
  justify-content: center;
  margin-bottom: ${spacings.md};
`;

const imageStyle = css`
  max-width: 100%;
`;

interface Props extends OmitStrict<ModalProps, 'children'> {
  aspectRatio?: number;
  alt?: string;
  onConfirm: (croppedImage: HTMLCanvasElement) => void;
  onCancel?: () => void;
  src?: string;
}

const FormImageCropperModal = ({
  'aria-label': ariaLabel = 'Crop image',
  aspectRatio,
  alt,
  onConfirm,
  onCancel,
  src,
  ...props
}: Props) => {
  const { visible, hide } = props;

  const imageRef = useRef<HTMLImageElement>(null);
  const cropperRef = useRef<Cropper>();

  const destroyCropper = useCallback(() => {
    if (cropperRef.current) {
      cropperRef.current.destroy();
      cropperRef.current = undefined;
    }
  }, []);

  const handleConfirm = useCallback(() => {
    if (cropperRef.current) {
      onConfirm(cropperRef.current.getCroppedCanvas());
    }

    hide();
  }, [onConfirm, hide]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }

    hide();
  }, [onCancel, hide]);

  useEffect(() => {
    if (!visible) {
      return () => undefined;
    }

    setTimeout(() => {
      if (imageRef.current) {
        cropperRef.current = new Cropper(imageRef.current, {
          viewMode: 2,
          aspectRatio,
        });
      }
    }, 100);

    return () => {
      destroyCropper();
    };
  }, [aspectRatio, destroyCropper, src, visible]);

  return (
    <Modal {...props} aria-label={ariaLabel}>
      <h1 className={headingStyles.md}>{ariaLabel}</h1>

      <div className={containerStyle}>
        <img className={imageStyle} ref={imageRef} src={src} alt={alt} />
      </div>

      <ButtonGroup>
        <Button onClick={handleConfirm}>Confirm crop</Button>
        <Button inverted onClick={handleCancel}>
          Don't crop
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

export default FormImageCropperModal;
