import React, { forwardRef, Ref } from 'react';
import { Link as BaseLink, LinkProps } from 'react-router-dom';

const Link = (
  { children, className, to, ...props }: LinkProps,
  ref: Ref<HTMLAnchorElement>,
) => {
  if (typeof to === 'string' && /^https?/.test(to)) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <a {...props} href={to} className={className} ref={ref}>
        {children}
      </a>
    );
  }

  return (
    <BaseLink {...props} className={className} to={to} innerRef={ref}>
      {children}
    </BaseLink>
  );
};

export default forwardRef<HTMLAnchorElement, LinkProps>(Link);
