import spacingStyles from '@frontend/styles/spacings';
import { cx } from '@emotion/css';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const FormGroup = ({ children, className }: Props) => {
  return (
    <div className={cx(spacingStyles.marginBottom.md, className)}>
      {children}
    </div>
  );
};

export default FormGroup;
