import { RainyDayResource } from '@common/types/apiResources';
import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import Button from '@frontend/components/Button';
import FormField from '@frontend/components/form/field/FormField';
import FormGroup from '@frontend/components/form/FormGroup';
import FormTextArea from '@frontend/components/form/FormTextArea';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import SuccessMessage from '@frontend/components/SuccessMessage';
import useFormSubmit from '@frontend/hooks/useFormSubmit';
import rainyDayService from '@frontend/services/rainyDayService';
import flexStyles from '@frontend/styles/flex';
import { floatStyles } from '@frontend/styles/utils';
import { fonts, fontWeights } from '@frontend/styles/variables';
import { Form, Formik } from 'formik';
import React, { TextareaHTMLAttributes } from 'react';

const textAreaStyle = css`
  font-size: 1.8rem;
  font-style: italic;
  font-weight: ${fontWeights.semiBold};
  padding: 3rem 2.2rem;
  position: relative;
  text-align: center;
  vertical-align: center;
`;

const quotesStyle = css`
  position: relative;

  &::before,
  &::after {
    font-family: ${fonts.heading};
    font-size: 4rem;
    font-style: italic;
    position: absolute;
    z-index: 100;
  }

  &::before {
    content: '“';
    left: 1rem;
    top: 0.5rem;
  }

  &::after {
    bottom: 0;
    content: '”';
    right: 2rem;
  }
`;

const QuotesTextArea = ({
  value,
  ...props
}: TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  const theme = useTheme();

  const themedQuoteStyle = css`
    ${quotesStyle}
    &::after {
      color: ${theme.textContrast};
    }
  `;

  return (
    <div className={value ? themedQuoteStyle : undefined}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <textarea {...props} value={value} />
    </div>
  );
};

interface FormValues {
  helpfulWords: string;
}

interface Props {
  rainyDay?: RainyDayResource;
  onSubmit: (values: RainyDayResource) => void;
}

const HelpfulWordsForm = ({ rainyDay, onSubmit }: Props) => {
  const {
    fieldErrors,
    handleSubmit,
    isSubmitted,
    isSubmitting,
  } = useFormSubmit<FormValues>(
    async ({ helpfulWords }) => {
      if (rainyDay) {
        const newRainyDay = await rainyDayService.patch(
          rainyDay.userId,
          {
            helpfulWords,
          },
          { query: { $eager: 'mediaReferences' } },
        );

        onSubmit(newRainyDay);
      } else {
        const newRainyDay = await rainyDayService.create({
          helpfulWords,
        });

        onSubmit(newRainyDay);
      }
    },
    {
      success: 'Saved helpful words',
      error: 'Could not save helpful words',
    },
  );

  return (
    <Formik<FormValues>
      initialValues={{
        helpfulWords: rainyDay?.helpfulWords ?? '',
      }}
      initialErrors={fieldErrors}
      onSubmit={handleSubmit}
    >
      <Form>
        <div className={floatStyles.right}>
          {isSubmitting && <LoadingSpinner />}
          {isSubmitted && <SuccessMessage>Saved</SuccessMessage>}
        </div>

        <FormField
          as={FormTextArea}
          id="helpfulWordsForm-helpfulWords"
          label="Some helpful words"
          name="helpfulWords"
          hint="Add some words you might find helpful, like a quote or words someone's said to you that make you feel better"
          className={textAreaStyle}
          component={QuotesTextArea}
          rows={3}
        />

        <FormGroup className={cx(flexStyles.flex, flexStyles.justifyCenter)}>
          <Button inverted type="submit" disabled={isSubmitting}>
            Save helpful words
          </Button>
        </FormGroup>
      </Form>
    </Formik>
  );
};

export default HelpfulWordsForm;
