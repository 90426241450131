import { OmitStrict } from '@common/types/util';
import FormToggle, {
  FormToggleProps,
} from '@frontend/components/form/FormToggle';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';

type Props = OmitStrict<FormToggleProps, 'checked' | 'value' | 'onChange'>;

const FormFieldToggle = <Values extends { [K in keyof Values]: boolean }>({
  name,
  ...props
}: Props & { name: keyof Values }) => {
  const form = useFormikContext<Values>();

  const handleChange: FormToggleProps['onChange'] = useCallback(
    checked => {
      form.setFieldValue(name, checked);
    },
    [form, name],
  );

  let errorMessage = '';

  if (form.touched[name]) {
    const errors = form.errors[name];

    errorMessage = Array.isArray(errors)
      ? (errors[0] as string)
      : (errors as string);
  }

  return (
    <>
      <FormToggle
        {...props}
        checked={form.values[name]}
        value="true"
        name={name}
        error={errorMessage}
        onChange={handleChange}
      />
    </>
  );
};

export default FormFieldToggle;
