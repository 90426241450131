export default function isMin(minValue) {
  return function (value) {
    if (Array.isArray(value)) {
      return value.length < minValue ? "Must choose at least ".concat(minValue, " options") : '';
    }

    if (typeof value === 'string') {
      return value.length < minValue ? "Must be ".concat(minValue, " or more characters") : '';
    }

    return value < minValue ? "Must be at least ".concat(minValue) : '';
  };
}