import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { fonts, spacings } from '@frontend/styles/variables';
import React, { ReactNode } from 'react';

const errorStyle = css`
  font-family: ${fonts.body};
  margin-bottom: ${spacings.sm};
`;

interface Props {
  children: ReactNode;
  className?: string;
  id: string;
}

const ErrorMessage = ({ className, children, id }: Props) => {
  const theme = useTheme();
  const themedErrorStyle = css`
    ${errorStyle};
    color: ${theme.error};
  `;

  return (
    <div className={cx(themedErrorStyle, className)} id={id}>
      {children}
    </div>
  );
};

export default ErrorMessage;
