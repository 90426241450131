import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import Link from '@frontend/components/Link';
import useQuery from '@frontend/hooks/useQuery';
import flexStyles from '@frontend/styles/flex';
import { fontWeights, spacings } from '@frontend/styles/variables';
import range from 'lodash/range';
import qs from 'qs';
import React from 'react';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';

const listStyle = css`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding-left: 0;
`;

const itemStyle = css`
  font-size: 1.5rem;
  margin: ${spacings.xs};
`;

const arrowStyle = css`
  font-size: 2rem;
`;

const pageNumberStyle = css`
  font-weight: ${fontWeights.regular};
  padding: ${spacings.xs};

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const currentItemStyle = css`
  font-weight: ${fontWeights.semiBold};
`;

interface PaginationProps {
  'aria-label': string;
  limit: number;
  pageKey?: string;
  skip: number;
  total: number;
  onChange?: (pageNumber: number) => void;
}

const Pagination = ({
  'aria-label': ariaLabel,
  limit,
  pageKey = 'page',
  skip,
  total,
  onChange,
}: PaginationProps) => {
  const theme = useTheme();

  const themedPageNumberStyle = css`
    ${pageNumberStyle};
    border-bottom: 2px solid ${theme.secondary};

    &:hover,
    &:focus {
      border-bottom: 2px solid ${theme.linkHover};
    }
  `;

  const themedCurrentItemStyle = css`
    ${currentItemStyle};
    border-bottom: 2px solid ${theme.linkHover};
  `;

  const query = useQuery();

  const currentPage = skip / limit + 1;
  const lastPage = Math.ceil(total / limit) || 1;

  let windowMin = currentPage - 2;
  let windowMax = currentPage + 2;

  const isBeginning = currentPage <= 3;
  const isEnd = currentPage > lastPage - 3;

  if (isBeginning) {
    windowMin = 1;
    windowMax = lastPage < 5 ? lastPage : 5;
  } else if (isEnd) {
    windowMin = lastPage - 4 >= 1 ? lastPage - 4 : 1;
    windowMax = lastPage;
  }

  return (
    <nav
      role="navigation"
      aria-label={ariaLabel}
      className={cx(flexStyles.flex, flexStyles.justifyCenter)}
    >
      <ul className={listStyle}>
        {currentPage > 1 && (
          <li className={itemStyle}>
            <Link
              to={qs.stringify(
                {
                  ...query,
                  [pageKey]: currentPage - 1,
                },
                {
                  addQueryPrefix: true,
                },
              )}
              aria-current={currentPage === 1}
              aria-label={`${currentPage === 1 ? 'Current page, ' : ''}Page 1`}
              className={cx(flexStyles.flex, flexStyles.alignItemsCenter)}
              onClick={() => {
                if (onChange) {
                  onChange(currentPage - 1);
                }
              }}
            >
              <FaCaretLeft className={arrowStyle} />
            </Link>
          </li>
        )}

        {!isBeginning && lastPage > 5 && (
          <>
            <li className={itemStyle}>
              <Link
                to={qs.stringify(
                  {
                    ...query,
                    [pageKey]: 1,
                  },
                  {
                    addQueryPrefix: true,
                  },
                )}
                aria-current={false}
                aria-label="Page 1"
                className={themedPageNumberStyle}
                onClick={() => {
                  if (onChange) {
                    onChange(1);
                  }
                }}
              >
                1
              </Link>
            </li>
            <li className={itemStyle}>…</li>
          </>
        )}

        {range(windowMin, windowMax + 1).map(page => {
          const isCurrent = currentPage === page;
          return (
            <li key={page} className={itemStyle}>
              <Link
                to={qs.stringify(
                  {
                    ...query,
                    [pageKey]: page,
                  },
                  {
                    addQueryPrefix: true,
                  },
                )}
                aria-current={isCurrent}
                aria-label={`${isCurrent ? 'Current page, ' : ''}Page ${page}`}
                className={cx(
                  themedPageNumberStyle,
                  isCurrent && themedCurrentItemStyle,
                )}
                onClick={() => {
                  if (onChange) {
                    onChange(page);
                  }
                }}
              >
                {page}
              </Link>
            </li>
          );
        })}

        {!isEnd && lastPage > 5 && (
          <>
            <li className={itemStyle}>…</li>
            <li className={itemStyle}>
              <Link
                to={qs.stringify(
                  {
                    ...query,
                    [pageKey]: lastPage,
                  },
                  {
                    addQueryPrefix: true,
                  },
                )}
                aria-current={false}
                aria-label={`Page ${lastPage}`}
                className={themedPageNumberStyle}
                onClick={() => {
                  if (onChange) {
                    onChange(lastPage);
                  }
                }}
              >
                {lastPage}
              </Link>
            </li>
          </>
        )}

        {currentPage !== lastPage && (
          <li className={itemStyle}>
            <Link
              to={qs.stringify(
                {
                  ...query,
                  [pageKey]: currentPage + 1,
                },
                {
                  addQueryPrefix: true,
                },
              )}
              aria-current={currentPage === lastPage}
              aria-label={`${
                currentPage === lastPage ? 'Current page, ' : ''
              }Page ${lastPage}`}
              className={cx(flexStyles.flex, flexStyles.alignItemsCenter)}
              onClick={() => {
                if (onChange) {
                  onChange(currentPage + 1);
                }
              }}
            >
              <FaCaretRight className={arrowStyle} />
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
