import { UserResource } from '@common/types/apiResources';
import FormImageInput from '@frontend/components/form/FormImageInput';
import { useUserContext } from '@frontend/context/UserContext';
import imageService from '@frontend/services/imageService';
import userService from '@frontend/services/userService';
import spacingStyles from '@frontend/styles/spacings';
import tryAsyncToast from '@frontend/utils/tryAsyncToast';
import axios from 'axios';
import last from 'lodash/last';
import React from 'react';

interface Props {
  user: UserResource;
}

const AccountImageForm = ({ user }: Props) => {
  const { setUser } = useUserContext();

  return (
    <div className={spacingStyles.marginBottom.md}>
      <FormImageInput
        id="accountImageForm-image"
        label="Profile image"
        aspectRatio={1}
        onChange={async file => {
          const image = await imageService.create({
            type: 'profile',
          });

          const data = new FormData();

          Object.entries(image.fields).forEach(([key, value]) => {
            data.append(key, value);
          });

          data.append('file', file);

          await tryAsyncToast(
            async () => {
              await axios.post(image.url, data, {
                headers: {
                  'Content-Type': 'image/jpeg',
                },
              });

              const savedUser = await userService.patch(user.id, {
                imageUrl: `${image.url}/${image.fields.key}`,
              });

              setUser(savedUser);
            },
            {
              success: 'Profile image saved',
              error: 'Could not save profile image',
            },
          );
        }}
        onDelete={async () => {
          if (user?.imageUrl) {
            await tryAsyncToast(
              async () => {
                const parts = user.imageUrl.split('/');
                await imageService.remove(last(parts) as string);

                const savedUser = await userService.patch(user.id, {
                  imageUrl: '',
                });

                setUser(savedUser);
              },
              {
                success: 'Profile image deleted',
                error: 'Could not delete profile image',
              },
            );
          }
        }}
        value={user?.imageUrl ?? ''}
      />
    </div>
  );
};

export default AccountImageForm;
