import {
  MediaReferenceResource,
  MediaReferenceType,
} from '@common/types/apiResources';
import isPattern from '@common/validations/isPattern';
import validateFields from '@common/validations/validateFields';
import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import FormField from '@frontend/components/form/field/FormField';
import FormGroup from '@frontend/components/form/FormGroup';
import FormInput from '@frontend/components/form/FormInput';
import Column from '@frontend/components/grid/Column';
import Row from '@frontend/components/grid/Row';
import FileUploadButton from '@frontend/pages/rainy-day/components/FileUploadButton';
import { allowedTypes } from '@frontend/pages/rainy-day/components/MediaAddButton';
import mediaReferenceService from '@frontend/services/mediaReferenceService';
import flexStyles from '@frontend/styles/flex';
import { spacings } from '@frontend/styles/variables';
import { Form, Formik } from 'formik';
import React, { ReactNode } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/all';

export interface MediaAddFormValues {
  url: string;
}

interface Props {
  userId: string;
  onSave: (reference: MediaReferenceResource) => void;
  onFileUpload: (file: File) => Promise<void>;
  acceptType: string;
}

const attemptToDetermineType = async (
  url: string,
): Promise<MediaReferenceType> => {
  // first attempt, try to see if there's an extension on the URL

  const [extension] = /.*[.](.+)$/.exec(url) || [''];

  let type = Object.entries(allowedTypes).find(([, entry]) =>
    entry?.extensions.includes(extension),
  )?.[0];

  if (type) return type as MediaReferenceType;

  // now try the URL itself to see if there's a content type

  try {
    const response = await fetch(url, { method: 'HEAD', mode: 'no-cors' });
    const contentType = response.headers.get('content-type');

    if (contentType !== null) {
      type = Object.entries(allowedTypes).find(
        ([, entry]) => entry && contentType.startsWith(entry.mime),
      )?.[0];
    }
  } catch (e) {
    // ignore errors and allow it to fall back
  }

  if (type) return type as MediaReferenceType;

  // finally see if the domain name matches anything we might recognise
  // this is last as it's possible some of these host different types than expected
  // maybe? (Images on a video server possibly?)

  const parseUrl = new URL(url);

  const urlParts = parseUrl.hostname.split('.');
  const domain = urlParts.slice(urlParts.length - 2).join('.');

  type = Object.entries(allowedTypes).find(([, entry]) =>
    entry?.sites.includes(domain),
  )?.[0];

  if (type) return type as MediaReferenceType;

  // if we can't determine type, default to image

  return 'image';
};

const MediaAddForm = ({ userId, onSave, onFileUpload, acceptType }: Props) => {
  const initialValues: MediaAddFormValues = {
    url: '',
  };

  const theme = useTheme();

  const wrapped = css`
    position: relative;

    > input {
      padding-right: 3em;
    }
  `;

  const button = css`
    background: ${theme.background};
    border: 0;
    border-radius: 50%;
    line-height: 0;
    margin: 0.5rem;
    padding: 0;
    position: absolute;
    right: ${spacings.xs};
  `;

  return (
    <Formik<MediaAddFormValues>
      initialValues={initialValues}
      validate={values =>
        validateFields({
          url: isPattern(
            /^http[s]:[/]{2}.*/,
            'Please specify a valid url beginning with http or https',
          ),
        })(values)
      }
      validateOnBlur={false}
      onSubmit={async values => {
        const type = await attemptToDetermineType(values.url);

        const mediaReference = await mediaReferenceService.create({
          userId,
          type,
          url: values.url,
        });

        onSave(mediaReference);
      }}
    >
      <Form>
        <FormGroup>
          <Row>
            <Column size={12}>
              <p>
                Copy and past your link to your image or video, or add one from
                your device
              </p>

              <FormField
                as={FormInput}
                name="url"
                label="Image or Video Link"
                id={`mediaForm-${userId}-url`}
                placeholder="Url of an image or video"
              >
                {(input: ReactNode) => (
                  <span className={wrapped}>
                    {input}
                    <button className={button} type="submit">
                      <AiOutlinePlusCircle
                        size="2em"
                        color={theme.inputColor}
                      />
                    </button>
                  </span>
                )}
              </FormField>
            </Column>
          </Row>

          <Row>
            <Column
              size={12}
              className={cx(
                flexStyles.flexCenterAll,
                css`
                  margin: ${spacings.md};
                `,
              )}
            >
              OR
            </Column>
          </Row>

          <Row>
            <Column size={12} className={flexStyles.flexCenterAll}>
              <FileUploadButton
                onFileUpload={onFileUpload}
                title="Choose from your device"
                accept={acceptType}
              />
            </Column>
          </Row>
        </FormGroup>
      </Form>
    </Formik>
  );
};

export default MediaAddForm;
