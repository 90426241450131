import Button from '@frontend/components/Button';
import FormField from '@frontend/components/form/field/FormField';
import FormGroup from '@frontend/components/form/FormGroup';
import FormInput from '@frontend/components/form/FormInput';
import Column from '@frontend/components/grid/Column';
import Row from '@frontend/components/grid/Row';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import SuccessMessage from '@frontend/components/SuccessMessage';
import useFormSubmit from '@frontend/hooks/useFormSubmit';
import flexStyles from '@frontend/styles/flex';
import headingStyles from '@frontend/styles/headings';
import { positionStyles } from '@frontend/styles/position';
import { cx } from '@emotion/css';
import { Form, Formik } from 'formik';
import React from 'react';

interface FormValues {
  id: string;
  entry1: string;
  entry2: string;
  entry3: string;
}

interface Props {
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => void;
}

const JournalForm = ({
  initialValues = {
    id: '',
    entry1: '',
    entry2: '',
    entry3: '',
  },
  onSubmit,
}: Props) => {
  const formId = 'journalForm';

  const {
    fieldErrors,
    handleSubmit,
    isSubmitted,
    isSubmitting,
  } = useFormSubmit<FormValues>(onSubmit, {
    success: 'Saved to journal',
    error: 'Could not save to journal',
  });

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      initialErrors={fieldErrors}
      onSubmit={handleSubmit}
    >
      <Form>
        <fieldset>
          <div className={cx(flexStyles.flex, flexStyles.justifySpaceBetween)}>
            <legend aria-describedby={`${formId}-entry-hint`}>
              <h2 className={headingStyles.sm}>Today I am grateful for...</h2>
            </legend>

            <div>
              {isSubmitting && <LoadingSpinner />}
              {isSubmitted && <SuccessMessage>Saved</SuccessMessage>}
            </div>
          </div>

          <p id={`${formId}-entry-hint`}>
            Regularly recognising the things in your life to be thankful for can
            improve your mental health. These can be saved to your ‘journal’.
          </p>

          <Row
            className={cx(
              flexStyles.flex,
              flexStyles.column,
              flexStyles.alignItemsCenter,
            )}
          >
            <Column size={6}>
              <FormField
                as={FormInput}
                id={`${formId}-entry1`}
                label="First gratitude"
                showLabel={false}
                placeholder="Enter first"
                name="entry1"
                data-lpignore="true"
              />
              <FormField
                as={FormInput}
                id={`${formId}-entry2`}
                label="Second gratitude"
                showLabel={false}
                placeholder="Enter second"
                name="entry2"
                data-lpignore="true"
              />
              <FormField
                as={FormInput}
                id={`${formId}-entry3`}
                label="Third gratitude"
                showLabel={false}
                placeholder="Enter third"
                name="entry3"
                data-lpignore="true"
              />
            </Column>

            <FormGroup
              className={cx(
                flexStyles.flex,
                flexStyles.alignItemsCenter,
                positionStyles.relative,
              )}
            >
              <Button
                colour="secondary"
                inverted
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Saving...' : 'Save to Journal'}
              </Button>
            </FormGroup>
          </Row>
        </fieldset>
      </Form>
    </Formik>
  );
};

export default JournalForm;
