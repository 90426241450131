import { FavouriteResource } from '@common/types/apiResources';
import ButtonLink from '@frontend/components/ButtonLink';
import Column from '@frontend/components/grid/Column';
import Row from '@frontend/components/grid/Row';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import NoItemsMessage from '@frontend/components/NoItemsMessage';
import Pagination from '@frontend/components/Pagination';
import SortDropdown from '@frontend/components/SortDropdown';
import useAsyncRetry from '@frontend/hooks/useAsyncRetry';
import usePaginationQuery from '@frontend/hooks/usePaginationQuery';
import useQuery from '@frontend/hooks/useQuery';
import favouriteService from '@frontend/services/favouriteService';
import flexStyles from '@frontend/styles/flex';
import headingStyles from '@frontend/styles/headings';
import spacingStyles from '@frontend/styles/spacings';
import { breakpoints, spacings } from '@frontend/styles/variables';
import tryAsyncToast from '@frontend/utils/tryAsyncToast';
import { css, cx } from '@emotion/css';
import { LocationDescriptor } from 'history';
import React, { ComponentType, ReactNode, useCallback, useRef } from 'react';
import { FiPlusCircle } from 'react-icons/fi';

export const favouritesHeadingContainerStyle = css`
  align-items: baseline;
  display: flex;
  margin-bottom: ${spacings.md};

  @media (max-width: ${breakpoints.tablet}) {
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: ${spacings.lg};
  }
`;

export const favouritesHeadingStyle = css`
  ${headingStyles.md};
  margin-bottom: 0;
  margin-right: ${spacings.md};

  @media (max-width: ${breakpoints.tablet}) {
    margin-right: 0;
  }
`;

export const favouritesIconStyle = css`
  font-size: 1.2rem;
  margin-right: ${spacings.xs};
`;

interface Props {
  children: (props: {
    item: FavouriteResource;
    handleDelete: (id: string) => void;
  }) => ReactNode;
  title: string;
  titleIcon?: ComponentType<{ className: string; size: string }>;
  addPageLink: LocationDescriptor;
  type: FavouriteResource['type'];
  introduction?: string;
}

const FavouritesGroup = <T extends {}>({
  children,
  title,
  type,
  titleIcon: TitleIcon,
  addPageLink,
  introduction,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const sortKey = `${type}Sort`;
  const query = useQuery();

  const { $skip, $limit } = usePaginationQuery({
    key: `${type}Page`,
  });

  const { value, isLoading, retry } = useAsyncRetry(
    () =>
      favouriteService.find({
        query: {
          $skip,
          $limit,
          $sort: {
            createdAt: query[sortKey] === 'asc' ? 1 : -1,
          },
          type,
        },
      }),
    [$skip, $limit, query, type],
  );

  const handleDelete = useCallback(
    (id: string) =>
      tryAsyncToast(
        async () => {
          await favouriteService.remove(id);
          await retry();
        },
        {
          success: 'Favourite deleted',
          error: 'Could not delete favourite',
        },
      ),
    [retry],
  );

  return (
    <div className={spacingStyles.marginBottom.lg} ref={ref}>
      <div className={favouritesHeadingContainerStyle}>
        <h2 className={favouritesHeadingStyle}>
          {TitleIcon && (
            <TitleIcon
              className={spacingStyles.marginRight.xs}
              size={spacings.md}
            />
          )}
          {title}
        </h2>

        <ButtonLink
          to={addPageLink}
          colour="secondary"
          size="sm"
          textColour="inputColor"
        >
          <FiPlusCircle className={favouritesIconStyle} />
          Add {title}
        </ButtonLink>
      </div>

      <div className={cx(flexStyles.flex, flexStyles.justifySpaceBetween)}>
        <p>{introduction}</p>
        <SortDropdown
          aria-label="Sort"
          className={spacingStyles.marginBottom.md}
          sortKey={sortKey}
          options={[
            {
              value: 'desc',
              label: 'Sort by newest',
            },
            {
              value: 'asc',
              label: 'Sort by oldest',
            },
          ]}
        />
      </div>

      <LoadingSpinner loading={isLoading} size="xl" center>
        {value && (
          <>
            {value.data.length > 0 ? (
              <>
                <Row>
                  {value.data.map(item => (
                    <Column
                      size={6}
                      className={spacingStyles.marginBottom.lg}
                      key={item.id}
                    >
                      {children({ item, handleDelete })}
                    </Column>
                  ))}
                </Row>
              </>
            ) : (
              <NoItemsMessage>No {title.toLowerCase()} found</NoItemsMessage>
            )}

            <Pagination
              {...value}
              aria-label={`${title} pages`}
              pageKey={`${type}Page`}
              onChange={() => {
                if (ref.current) {
                  ref.current.scrollIntoView({
                    behavior: 'smooth',
                  });
                }
              }}
            />
          </>
        )}
      </LoadingSpinner>
    </div>
  );
};

export default FavouritesGroup;
