import { css, cx } from '@emotion/css';
import Badge from '@frontend/components/Badge';
import Footer from '@frontend/components/Footer';
import Column from '@frontend/components/grid/Column';
import Row from '@frontend/components/grid/Row';
import NavBar from '@frontend/components/NavBar';
import ProfileAvatar from '@frontend/components/ProfileAvatar';
import { useCompletedGoalsContext } from '@frontend/context/CompletedGoalsContext';
import { useUserContext } from '@frontend/context/UserContext';
import { useMaxWidthMedia } from '@frontend/hooks/useMedia';
import useScrollToTop from '@frontend/hooks/useScrollToTop';
import flexStyles from '@frontend/styles/flex';
import spacingStyles from '@frontend/styles/spacings';
import { breakpoints } from '@frontend/styles/variables';
import React, { ComponentType, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import MailingListModal from '@frontend/pages/components/MailingListModal';
import EasyReadIcon from '@frontend/components/EasyReadIcon';

export const pageStyle = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const columnStyle = css`
  padding: 4rem 2rem 0;
  width: 100vw;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0;
    max-width: 1440px;
    padding: 4rem 2rem;
  }
`;

const fixSlider = css`
  min-height: 0;
  min-width: 0;

  * {
    min-height: 0;
    min-width: 0;
  }
`;

const heading = css`
  align-items: baseline;
  display: flex;
`;

const headerText = css`
  white-space: nowrap;
`;

const paragraph = css`
  margin: auto !important;
`;

interface Props {
  aside?: ReactNode;
  children: ReactNode;
  intro?: ReactNode;
  titleIcon?: ComponentType<{ className: string }>;
  title: string;
  mobileAsidePosition?: 'top' | 'bottom';
  easyreadPdf?: string;
}

const Page = ({
  aside,
  children,
  title,
  intro,
  titleIcon: TitleIcon,
  mobileAsidePosition = 'bottom',
  easyreadPdf = undefined,
}: Props) => {
  useScrollToTop();

  const mediaMatches = useMaxWidthMedia('desktop');

  const { user } = useUserContext();
  const { completedGoals, isLoading } = useCompletedGoalsContext();

  return (
    <div className={pageStyle}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className={columnStyle}>
        <Row
          className={cx(
            spacingStyles.marginBottom.lg,
            css`
              align-items: center;
            `,
          )}
        >
          <Column size={mediaMatches ? 12 : 2} grow>
            <h1 className={cx(spacingStyles.marginBottom.xs, heading)}>
              {TitleIcon && (
                <TitleIcon className={spacingStyles.marginRight.sm} />
              )}
              <span className={headerText}>{title}</span>
              {mediaMatches && (
                <EasyReadIcon
                  className={css`
                    margin-left: auto;
                  `}
                  pdf={easyreadPdf}
                />
              )}
            </h1>

            {!isLoading && (
              <Badge>
                {completedGoals === 1
                  ? `${completedGoals} completed goal`
                  : `${completedGoals} completed goals`}
              </Badge>
            )}

            {mediaMatches && (
              <div>{typeof intro === 'string' ? <p>{intro}</p> : intro}</div>
            )}
          </Column>

          {!mediaMatches && (
            <>
              <Column size={8} grow className={paragraph}>
                <Row
                  className={css`
                    margin: auto;
                  `}
                >
                  <Column size={9} grow>
                    {typeof intro === 'string' ? <p>{intro}</p> : intro}
                  </Column>
                  <Column size={1} grow>
                    <EasyReadIcon pdf={easyreadPdf} />
                  </Column>
                </Row>
              </Column>
              <Column
                grow
                size={2}
                className={cx(flexStyles.flex, flexStyles.justifyEnd)}
              >
                <ProfileAvatar
                  name={`${user?.firstName} ${user?.lastName}`}
                  imageUrl={user?.imageUrl ?? ''}
                />
              </Column>
            </>
          )}
        </Row>

        {mediaMatches ? (
          <>
            {mobileAsidePosition === 'top' && <aside>{aside}</aside>}

            <NavBar title={title} />

            {children}

            {mobileAsidePosition === 'bottom' && <aside>{aside}</aside>}
          </>
        ) : (
          <Row className={fixSlider}>
            {aside && (
              <Column as="aside" grow size={3}>
                {aside}
              </Column>
            )}
            <Column grow size={9}>
              <NavBar title={title} />

              {children}
            </Column>
          </Row>
        )}

        <Footer />

        <MailingListModal />
      </div>
    </div>
  );
};

export default Page;
