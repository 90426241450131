import { labelStyle as legendStyle } from '@frontend/components/form/FormLabel';
import headingStyles from '@frontend/styles/headings';
import spacingStyles from '@frontend/styles/spacings';
import { spacings } from '@frontend/styles/variables';
import { css, cx } from '@emotion/css';
import React, { ChangeEventHandler } from 'react';

const labelStyle = css`
  cursor: pointer;
  padding-left: ${spacings.sm};
`;

interface RadioOption {
  value: string;
  label: string;
}

interface Props {
  id: string;
  name: string;
  options: RadioOption[];
  label: string;
  labelSize?: keyof typeof headingStyles;
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const FormRadios = ({
  id,
  label,
  labelSize,
  name,
  options,
  onChange,
  value,
}: Props) => {
  return (
    <fieldset>
      <legend
        className={cx(legendStyle, labelSize && headingStyles[labelSize])}
      >
        {label}
      </legend>

      {options.map(option => (
        <div key={option.value} className={spacingStyles.marginBottom.xs}>
          <input
            id={`${id}-${option.value}`}
            type="radio"
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={onChange}
          />

          <label htmlFor={`${id}-${option.value}`} className={labelStyle}>
            {option.label}
          </label>
        </div>
      ))}
    </fieldset>
  );
};

export default FormRadios;
