import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { useMinWidthMedia } from '@frontend/hooks/useMedia';
import headingStyles from '@frontend/styles/headings';
import spacingStyles from '@frontend/styles/spacings';
import { fonts, headingSizes, spacings } from '@frontend/styles/variables';
import React, { ReactNode } from 'react';
import { FaCaretDown, FaCaretUp, FaFilter } from 'react-icons/fa';
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit';

const style = css`
  background: none;
  border: 0;
  display: flex;
  font-family: ${fonts.heading};
  font-size: ${headingSizes.sm};
  justify-content: space-between;
  outline: none;
  padding: 0;
  text-align: left;
  width: 100%;

  &:disabled {
    cursor: default;
  }
`;

interface FiltersDropdownProps {
  children: ReactNode;
}

const FiltersDropdown = ({ children }: FiltersDropdownProps) => {
  const theme = useTheme();
  const themedStyle = css`
    ${style};
    color: ${theme.tertiary};

    &:focus,
    &:hover {
      color: ${theme.tertiary};
    }

    &:disabled {
      color: ${theme.tertiary};
    }
  `;

  const mediaMatches = useMinWidthMedia('desktop');
  const disclosure = useDisclosureState({ visible: false });

  return mediaMatches ? (
    <>
      <h2 className={headingStyles.sm}>
        <FaFilter className={spacingStyles.marginRight.xs} size={spacings.md} />
        Filters
      </h2>
      {children}
    </>
  ) : (
    <>
      <Disclosure {...disclosure} as="button" className={themedStyle}>
        <FaFilter className={spacingStyles.marginRight.xs} size={spacings.md} />
        Filters
        {disclosure.visible ? <FaCaretUp /> : <FaCaretDown />}
      </Disclosure>
      <DisclosureContent
        {...disclosure}
        as="div"
        className={spacingStyles.marginTop.md}
      >
        {children}
      </DisclosureContent>
    </>
  );
};

export default FiltersDropdown;
