import validateFields from '@common/validations/validateFields';
import Button from '@frontend/components/Button';
import FormFieldDateTime from '@frontend/components/form/field/FormFieldDateTime';
import FormFieldToggle from '@frontend/components/form/field/FormFieldToggle';
import FormGroup from '@frontend/components/form/FormGroup';
import { Form, Formik } from 'formik';
import React from 'react';
import FiltersDropdown from '@frontend/components/FiltersDropdown';

export interface FormValues {
  startDate: Date | null;
  endDate: Date | null;
  showAchievements: boolean;
  showGratitudes: boolean;
}

interface Props {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
}

const JournalFiltersForm = ({ initialValues, onSubmit }: Props) => {
  return (
    <FiltersDropdown>
      <Formik<FormValues>
        initialValues={initialValues}
        validate={values =>
          validateFields<FormValues>({
            endDate: value => {
              if (!value || !values.startDate) {
                return '';
              }

              return value < values.startDate
                ? 'End date must be before start date'
                : '';
            },
          })(values)
        }
        onSubmit={onSubmit}
      >
        <Form>
          <>
            <FormGroup>
              <FormFieldDateTime
                id="journalFiltersForm-startDate"
                label="Start date"
                labelSize="xs"
                name="startDate"
                showTime={false}
              />

              <FormFieldDateTime
                id="journalFiltersForm-endDate"
                label="End date"
                labelSize="xs"
                name="endDate"
                showTime={false}
              />
            </FormGroup>

            <FormGroup>
              <FormFieldToggle
                id="journalFiltersForm-showAchievements"
                name="showAchievements"
                label="View achievements"
              />

              <FormFieldToggle
                id="journalFiltersForm-showGratitudes"
                name="showGratitudes"
                label="View gratitudes"
              />
            </FormGroup>

            <Button type="submit" block>
              Filter results
            </Button>
          </>
        </Form>
      </Formik>
    </FiltersDropdown>
  );
};

export default JournalFiltersForm;
