import flexStyles from '@frontend/styles/flex';
import { themedFocusOutline } from '@frontend/styles/focus';
import { smallBoxShadow } from '@frontend/styles/shadows';
import { widthStyles } from '@frontend/styles/utils';
import { fonts, fontWeights } from '@frontend/styles/variables';
import { css, cx } from '@emotion/css';
import { lighten } from 'polished';
import React, { forwardRef, Ref } from 'react';
import { Button as BaseButton, ButtonProps as BaseButtonProps } from 'reakit';
import { useTheme, Theme } from '@emotion/react';

const sizeStyles = {
  sm: css`
    border-radius: 5px;
    padding: 0.4rem 0.8rem;
  `,
  md: css`
    border-radius: 8px;
    padding: 0.8rem 1.4rem;
  `,
};

export const buttonStyle = (options: {
  colour: keyof Theme;
  inverted: boolean;
  size: 'sm' | 'md';
  textColour?: keyof Theme;
  theme: Theme;
}) => {
  const { colour, size, inverted, textColour, theme } = options;

  const defaultTextColour = inverted ? theme[colour] : theme.textContrast;

  return css`
    ${smallBoxShadow()};
    ${themedFocusOutline(theme)};
    ${sizeStyles[size]};
    background-color: ${inverted ? theme.background : theme[colour]};
    border: 1px solid ${inverted ? theme[colour] : 'transparent'};
    border-radius: 8px;
    color: ${textColour ? theme[textColour] : defaultTextColour};
    cursor: pointer;
    display: inline-block;
    font-family: ${fonts.body};
    font-size: 1rem;
    font-weight: ${fontWeights.semiBold};
    line-height: 1.5;
    outline: none;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      background-color: ${inverted
        ? theme[colour]
        : lighten(0.05, theme[colour])};
      color: ${textColour
        ? lighten(0.05, theme[textColour])
        : theme.textContrast};
      transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
    }

    &:disabled {
      background-color: ${theme.disabledButtonBackground};
      border-color: ${theme.disabledButtonBorder};
      color: ${theme.disabledButtonColor};
      cursor: not-allowed;
    }
  `;
};

export interface ButtonProps extends Omit<BaseButtonProps, 'color'> {
  colour?: keyof Theme;
  block?: boolean;
  inverted?: boolean;
  size?: 'sm' | 'md';
  textColour?: keyof Theme;
}

// eslint-disable-next-line react/display-name
const Button = (
  {
    block,
    children,
    colour = 'primary',
    className,
    inverted = false,
    size = 'md',
    textColour,
    ...props
  }: ButtonProps,
  ref: Ref<HTMLButtonElement>,
) => {
  const theme = useTheme();

  return (
    <BaseButton
      className={cx(
        buttonStyle({ colour, inverted, size, textColour, theme }),
        className,
        block && widthStyles.full,
      )}
      {...props}
      ref={ref}
    >
      <span className={flexStyles.flexCenterAll}>{children}</span>
    </BaseButton>
  );
};

export default forwardRef<HTMLButtonElement, ButtonProps>(Button);
