import { GratitudeResource } from '@common/types/apiResources';
import AsideBox from '@frontend/components/AsideBox';
import Page from '@frontend/components/Page';
import PageLink from '@frontend/components/PageLink';
import Separator from '@frontend/components/Separator';
import SurveyBox from '@frontend/components/SurveyBox';
import { useMaxWidthMedia } from '@frontend/hooks/useMedia';
import JournalForm from '@frontend/pages/components/JournalForm';
import RecentAchievements from '@frontend/pages/components/RecentAchievements';
import VentForm from '@frontend/pages/components/VentForm';
import gratitudeService from '@frontend/services/gratitudeService';
import flexStyles from '@frontend/styles/flex';
import headingStyles from '@frontend/styles/headings';
import { textStyles } from '@frontend/styles/utils';
import { endOfDay, startOfDay } from 'date-fns';
import { cx } from '@emotion/css';
import React, { useEffect, useState } from 'react';
import Link from '@frontend/components/Link';
import { FaHome, FaTrophy } from 'react-icons/fa';
import { spacings } from '@frontend/styles/variables';
import spacingStyles from '@frontend/styles/spacings';

const HomePage = () => {
  const [journal, setJournal] = useState<GratitudeResource>();

  useEffect(() => {
    const today = new Date();

    gratitudeService
      .find({
        query: {
          $limit: 1,
          createdAt: {
            $gte: startOfDay(today).toISOString(),
            $lte: endOfDay(today).toISOString(),
          },
        },
      })
      .then(response => {
        if (response.data.length) {
          setJournal(response.data[0]);
        }
      });
  }, []);

  const mediaMatches = useMaxWidthMedia('desktop');

  return (
    <Page
      title="Welcome"
      titleIcon={FaHome}
      intro={
        <p>
          On My Toolkit you can create your own space to support your mental
          health and wellbeing, using resources from the{' '}
          <Link to="https://www.sheffieldmentalhealth.co.uk/">
            Sheffield Mental Health Guide
          </Link>
          . Co-created with the community by{' '}
          <Link to="https://www.sheffieldflourish.co.uk/">
            Sheffield Flourish
          </Link>
          . Remember, you can{' '}
          <Link to="/account">change the colour scheme</Link> of your Toolkit or
          visit our{' '}
          <Link to="https://www.sheffieldmentalhealth.co.uk/how-to-use-my-toolkit/">
            how to use My Toolkit guide
          </Link>{' '}
          at any time.
        </p>
      }
      aside={
        <>
          {!mediaMatches && <SurveyBox />}

          <AsideBox>
            <h2
              className={cx(
                headingStyles.sm,
                mediaMatches && textStyles.center,
              )}
            >
              <FaTrophy
                size={spacings.md}
                className={spacingStyles.marginRight.xs}
              />
              Recent achievements
            </h2>

            <RecentAchievements />
          </AsideBox>

          {mediaMatches && <SurveyBox />}
        </>
      }
      easyreadPdf="My Toolkit - Welcome"
    >
      <JournalForm
        initialValues={journal}
        onSubmit={async ({ id, entry1, entry2, entry3 }) => {
          if (id) {
            if (!entry1 && !entry2 && !entry3) {
              await gratitudeService.remove(id);

              setJournal(undefined);
              return;
            }

            const savedJournal = await gratitudeService.patch(id, {
              entry1,
              entry2,
              entry3,
            });

            setJournal(savedJournal);
            return;
          }

          if (!entry1 && !entry2 && !entry3) {
            setJournal(undefined);
            return;
          }

          const newJournal = await gratitudeService.create({
            entry1,
            entry2,
            entry3,
          });

          setJournal(newJournal);
        }}
      />

      <div className={cx(flexStyles.flex, flexStyles.justifyEnd)}>
        <PageLink to="/journal">See your journal</PageLink>
      </div>

      <Separator variant="dashed" />

      <VentForm />
    </Page>
  );
};

export default HomePage;
