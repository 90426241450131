import { UploadUrlResource } from '@common/types/apiResources';
import apiClient from '@frontend/services/client/apiClient';

export interface S3Service<T extends UploadUrlResource> {
  create(data: {
    type: 'rainy-day';
    fileType: string;
    name: string;
  }): Promise<T>;
  remove(id: string): Promise<void>;
}

const s3ServiceFactory = <T extends UploadUrlResource>(
  path: string,
): S3Service<T> => {
  return apiClient.service(path);
};

export default s3ServiceFactory;
