import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { breakpoints, spacings } from '@frontend/styles/variables';
import React, { ReactNode } from 'react';

const style = css`
  border-radius: 8px;
  margin: ${spacings.md} 0;
  padding: ${spacings.md};

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 0;
    padding: ${spacings.lg};
  }
`;

interface Props {
  children: ReactNode;
}

const AsideBox = ({ children }: Props) => {
  const theme = useTheme();

  const themedStyle = css`
    ${style};
    background-color: ${theme.background1};
  `;

  return <div className={themedStyle}>{children}</div>;
};

export default AsideBox;
