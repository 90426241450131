import React, { ReactNode } from 'react';

export interface TabsPanelProps {
  children: ReactNode;
  id: string;
  title: string;
}

const TabsPanel = ({ children }: TabsPanelProps) => {
  return <>{children}</>;
};

export default TabsPanel;
