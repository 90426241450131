import { cx } from '@emotion/css';
import Button from '@frontend/components/Button';
import EditButton from '@frontend/components/EditButton';
import Column from '@frontend/components/grid/Column';
import Row from '@frontend/components/grid/Row';
import LinkPreview from '@frontend/components/LinkPreview';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import ModalButton from '@frontend/components/ModalButton';
import NoItemsMessage from '@frontend/components/NoItemsMessage';
import Pagination from '@frontend/components/Pagination';
import SortDropdown from '@frontend/components/SortDropdown';
import useAsyncRetry from '@frontend/hooks/useAsyncRetry';
import usePaginationQuery from '@frontend/hooks/usePaginationQuery';
import useQuery from '@frontend/hooks/useQuery';
import {
  favouritesHeadingContainerStyle,
  favouritesHeadingStyle,
  favouritesIconStyle,
} from '@frontend/pages/favourites/components/FavouritesGroup';
import linkService from '@frontend/services/linkService';
import flexStyles from '@frontend/styles/flex';
import headingStyles from '@frontend/styles/headings';
import { positionStyles } from '@frontend/styles/position';
import spacingStyles from '@frontend/styles/spacings';
import { spacings } from '@frontend/styles/variables';
import tryAsyncToast from '@frontend/utils/tryAsyncToast';
import React, { useCallback, useRef } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { FaLink } from 'react-icons/fa';
import FavouriteCard from './FavouriteCard';
import LinkCreateForm from './LinkCreateForm';

const FavouriteLinksGroup = () => {
  const ref = useRef<HTMLDivElement>(null);
  const sortKey = 'linkSort';
  const pageKey = 'linkPage';
  const query = useQuery();

  const { $skip, $limit } = usePaginationQuery({
    key: pageKey,
  });

  const { value, isLoading, retry } = useAsyncRetry(
    () =>
      linkService.find({
        query: {
          $skip,
          $limit,
          $sort: {
            createdAt: query[sortKey] === 'asc' ? 1 : -1,
          },
        },
      }),
    [$skip, $limit, query],
  );

  const handleDelete = useCallback(
    (id: string) =>
      tryAsyncToast(
        async () => {
          await linkService.remove(id);
          await retry();
        },
        {
          success: 'Link deleted',
          error: 'Could not delete link',
        },
      ),
    [retry],
  );

  return (
    <div className={spacingStyles.marginBottom.lg} ref={ref}>
      <div className={favouritesHeadingContainerStyle}>
        <h2 className={favouritesHeadingStyle}>
          <FaLink className={spacingStyles.marginRight.xs} size={spacings.md} />
          Link
        </h2>

        <ModalButton
          aria-label="Create a favourite link"
          button={
            <Button colour="secondary" size="sm" textColour="inputColor">
              <FiPlusCircle className={favouritesIconStyle} />
              Add link
            </Button>
          }
        >
          {({ hide }) => (
            <>
              <h1 className={headingStyles.md}>Add a link</h1>
              <p>Copy and paste a link</p>
              <LinkCreateForm
                onSubmit={async ({ title, url, description }) => {
                  await tryAsyncToast(
                    async () => {
                      await linkService.create({
                        title,
                        url,
                        description,
                      });
                    },
                    {
                      success: (
                        <strong>
                          Link added to favourites.
                          <br />
                          This can now be set as a goal.
                        </strong>
                      ),
                      error: 'Could not add link to favourites',
                    },
                  );
                  hide();
                  retry();
                }}
              />
            </>
          )}
        </ModalButton>
      </div>

      <div className={cx(flexStyles.flex, flexStyles.justifySpaceBetween)}>
        <p>
          Add links below. This could be your favourite wellbeing website, or a
          service or activity that isn't listed on the guide.
        </p>

        <SortDropdown
          aria-label="Sort"
          className={spacingStyles.marginBottom.md}
          sortKey={sortKey}
          options={[
            {
              value: 'desc',
              label: 'Sort by newest',
            },
            {
              value: 'asc',
              label: 'Sort by oldest',
            },
          ]}
        />
      </div>

      <LoadingSpinner loading={isLoading} size="xl" center>
        {value && (
          <>
            {value.data.length > 0 ? (
              <>
                <Row>
                  {value.data.map(
                    ({ id, title, description, url, ...linkProps }) => (
                      <Column
                        size={6}
                        className={spacingStyles.marginBottom.lg}
                        key={id}
                      >
                        <FavouriteCard
                          id={id}
                          title={title}
                          url={url}
                          itemName="link"
                          onDelete={handleDelete}
                        >
                          <p>{description}</p>
                          <LinkPreview url={url} />

                          <ModalButton
                            aria-label="Create a favourite link"
                            button={
                              <EditButton
                                className={cx(
                                  positionStyles.absolute,
                                  positionStyles.top(spacings.sm),
                                  positionStyles.right(spacings.xl),
                                )}
                              >
                                Edit link
                              </EditButton>
                            }
                          >
                            {({ hide }) => (
                              <>
                                <h1 className={headingStyles.md}>Edit link</h1>
                                <p>Copy and paste a link</p>
                                <LinkCreateForm
                                  initialValues={{ title, url, description }}
                                  onSubmit={async updatedLink => {
                                    await tryAsyncToast(
                                      async () => {
                                        await linkService.update(id, {
                                          id,
                                          ...linkProps,
                                          ...updatedLink,
                                        });
                                      },
                                      {
                                        success: 'Link updated',
                                        error: 'Could not update link',
                                      },
                                    );
                                    hide();
                                    retry();
                                  }}
                                />
                              </>
                            )}
                          </ModalButton>
                        </FavouriteCard>
                      </Column>
                    ),
                  )}
                </Row>
              </>
            ) : (
              <NoItemsMessage>No links found</NoItemsMessage>
            )}

            <Pagination
              {...value}
              aria-label="Link pages"
              pageKey={pageKey}
              onChange={() => {
                if (ref.current) {
                  ref.current.scrollIntoView({
                    behavior: 'smooth',
                  });
                }
              }}
            />
          </>
        )}
      </LoadingSpinner>
    </div>
  );
};

export default FavouriteLinksGroup;
