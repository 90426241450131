import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@emotion/react';
import { useThemeContext } from '@frontend/context/ThemeContext';
import { useMaxWidthMedia } from '@frontend/hooks/useMedia';
import { noButtonStyle } from '@frontend/styles/buttons';
import flexStyles from '@frontend/styles/flex';
import { themedFocusOutline } from '@frontend/styles/focus';
import spacingStyles from '@frontend/styles/spacings';
import themes from '@frontend/themes';
import { startCase } from 'lodash';
import React from 'react';
import {
  Button,
  Disclosure,
  DisclosureContent,
  useDisclosureState,
  VisuallyHidden,
} from 'reakit';
import { linkStyle } from './NavBar';

const themePreview = (theme: Theme) => css`
  background: ${theme.tertiary};
  border-bottom-color: ${theme.secondary};
  border-left-color: ${theme.primary};
  border-radius: 50%;
  border-right-color: ${theme.secondary};
  border-style: solid;
  border-top-color: ${theme.primary};
  border-width: 0.8rem;
  box-shadow: 0 0 5px 5px ${theme.background};

  padding: 0.7rem;
  ${spacingStyles.margin.sm};
  ${themedFocusOutline(theme)}
`;

interface Props {
  showDesktop?: boolean;
  alwaysOpen?: boolean;
  disabled?: boolean;
}

const ThemeSelect = ({
  showDesktop = false,
  alwaysOpen = false,
  disabled = false,
}: Props) => {
  const theme = useTheme();
  const { setThemeKey } = useThemeContext();
  const disclosure = useDisclosureState({ visible: alwaysOpen });

  const mediaMatches = useMaxWidthMedia('desktop');

  const themedLinkStyle = css`
    ${noButtonStyle}
    ${linkStyle}
    color: ${theme.link};
  `;

  return (
    <>
      {(mediaMatches || showDesktop) && (
        <div
          className={cx(
            flexStyles.flex,
            flexStyles.column,
            flexStyles.alignItemsCenter,
            spacingStyles.marginBottom.lg,
          )}
        >
          {!alwaysOpen && (
            <Disclosure
              {...disclosure}
              className={themedLinkStyle}
              disabled={disabled || alwaysOpen}
            >
              Select Theme
            </Disclosure>
          )}
          <DisclosureContent {...disclosure}>
            <div
              className={cx(
                flexStyles.flex,
                flexStyles.alignItemsCenter,
                flexStyles.justifyCenter,
                flexStyles.wrap,
                css`
                  margin-bottom: 1rem;
                `,
              )}
            >
              {Object.entries(themes).map(([themeKey, themeValue]) => {
                return (
                  <Button
                    key={themeKey}
                    onClick={() => {
                      setThemeKey(themeKey);
                    }}
                    className={themePreview(themeValue)}
                  >
                    <VisuallyHidden>{startCase(themeKey)}</VisuallyHidden>
                  </Button>
                );
              })}
            </div>
          </DisclosureContent>
        </div>
      )}
    </>
  );
};

export default React.forwardRef(ThemeSelect);
