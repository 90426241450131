import React from 'react';
import { css, cx } from '@emotion/css';
import { VisuallyHidden } from 'reakit';

interface Props {
  title?: string;
  pdf?: string;
  className?: string;
}

const EasyReadIcon = ({
  title = 'You can download our "Easy Read" instructions here.',
  pdf,
  className,
}: Props) => {
  const linkStyle = css`
    background-image: url('/images/EasyReadLogo.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 4rem;
    width: 4rem;
  `;

  return (
    <>
      {pdf && (
        <a
          href={`/pdfs/${pdf}.pdf`}
          title={title}
          className={cx(linkStyle, className)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <VisuallyHidden>{title}</VisuallyHidden>
        </a>
      )}
    </>
  );
};

export default EasyReadIcon;
