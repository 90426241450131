import { useTheme } from '@emotion/react';
import { css } from '@emotion/css';
import { smallBoxShadow } from '@frontend/styles/shadows';
import { fonts, fontWeights, spacings } from '@frontend/styles/variables';
import React from 'react';
import { ToastContainer } from 'react-toastify';

const Toaster = () => {
  const theme = useTheme();

  const toastWrapperStyle = css`
    @media only screen and (max-width: 480px) {
      padding: 2rem;
    }
  `;

  const toastStyle = css`
    ${smallBoxShadow()};
    border-radius: 8px;
    color: ${theme.primary};
    font-family: ${fonts.body};
    font-weight: ${fontWeights.semiBold};
    padding: ${spacings.sm};
    text-transform: uppercase;

    &.Toastify__toast--success {
      background: ${theme.success};
      color: ${theme.textContrast};
    }

    &.Toastify__toast--warning {
      background: ${theme.warning};
      color: ${theme.textContrast};
    }

    &.Toastify__toast--error {
      background: ${theme.error};
      color: ${theme.textContrast};
    }
  `;

  return (
    <ToastContainer
      className={toastWrapperStyle}
      toastClassName={toastStyle}
      position="top-left"
      newestOnTop={false}
      hideProgressBar
      closeOnClick
    />
  );
};

export default Toaster;
