import React from 'react';

interface Props {
  id: number;
}

const GoalCategoryDescription = ({ id }: Props) => {
  switch (id) {
    case 1:
      return (
        <p>
          It’s easier to manage mental health difficulties and distress when we
          feel close to the people around us. Add up to 3{' '}
          <a
            href="https://www.sheffieldmentalhealth.co.uk/resources/connect-with-others/"
            rel="noopener noreferrer"
            target="_blank"
          >
            ways you’d like to connect with others
          </a>{' '}
          – or you can choose from the activities and services you’ve already
          favourited.
        </p>
      );
    case 2:
      return (
        <p>
          Regular physical activity can improve mood and decrease stress,
          anxiety and depression. Add up to 3{' '}
          <a
            href="https://www.sheffieldmentalhealth.co.uk/resources/get-busy-and-active/"
            rel="noopener noreferrer"
            target="_blank"
          >
            ways you’d like to get busy and active
          </a>{' '}
          – or you can choose activities and services you’ve already favourited
          which can help with this.
        </p>
      );
    case 3:
      return (
        <p>
          Being aware of what is taking place in the present directly improves
          your awareness and self-understanding. Have a think about up to 3
          things that{' '}
          <a
            href="https://www.sheffieldmentalhealth.co.uk/resources/take-notice-and-relax/"
            rel="noopener noreferrer"
            target="_blank"
          >
            might help you relax
          </a>
          , and you may also like to choose from activities and services which
          can support you in this.
        </p>
      );
    case 4:
      return (
        <p>
          When you give and share with others you give back to yourself too. Add
          up to 3{' '}
          <a
            href="https://www.sheffieldmentalhealth.co.uk/resources/give-and-share/"
            rel="noopener noreferrer"
            target="_blank"
          >
            ways, big or small, you’d like to give back to your community or
            those around you
          </a>{' '}
          – also include any service/activity that may help with this.
        </p>
      );
    case 5:
      return (
        <p>
          Learning new skills throughout life enhances your self-esteem and
          encourages social interaction and a more active life. Add up to 3{' '}
          <a
            href="https://www.sheffieldmentalhealth.co.uk/resources/learn-something-new/"
            rel="noopener noreferrer"
            target="_blank"
          >
            ways you will learn new skills
          </a>{' '}
          – maybe add a service or activity that can help you with this too.
        </p>
      );
    case 6:
      return (
        <p>
          This section is aimed at adults in recovery or aiming for recovery and
          are making changes to their alcohol use. Add services or activities
          which have a positive impact on your recovery. We also have a useful
          list of resources and services available here on the{' '}
          <a
            href="https://www.sheffieldmentalhealth.co.uk/resources/road-to-recovery/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Sheffield Mental Health Guide - Road to Recovery page.
          </a>
        </p>
      );
    default:
      return null;
  }
};

export default GoalCategoryDescription;
