import React, { ComponentType } from 'react';
import { css } from '@emotion/css';
import { headingSizes } from '@frontend/styles/variables';
import { useTheme } from '@emotion/react';

const keyItem = css`
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: 242px;
  padding: 2rem;
  width: 263px;
`;

const keyItemText = css`
  display: inline-block;
  flex-grow: 1;
  font-size: ${headingSizes.xxs};
  margin-bottom: 0;
  text-transform: uppercase;
`;

const keyItemHeader = css`
  display: flex;

  gap: 1em;
`;

interface Props {
  icon?: ComponentType<{ size?: string }>;
  title: string;
  children: React.ReactNode;
}

const KeyItem = ({ icon: Icon, title, children }: Props) => {
  const theme = useTheme();

  const themedKeyItemText = css`
    ${keyItemText};
    color: ${theme.textPrimary};
  `;

  return (
    <div className={keyItem}>
      <div className={keyItemHeader}>
        {Icon && (
          <div
            className={css`
              height: 44px;
              width: 44px;
            `}
          >
            <Icon size="44px" />
          </div>
        )}
        <h3 className={themedKeyItemText}>{title}</h3>
      </div>
      <p>{children}</p>
    </div>
  );
};

export default KeyItem;
