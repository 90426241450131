import { isMinLength } from '@common/validations';
import validateFields from '@common/validations/validateFields';
import Button from '@frontend/components/Button';
import FormField from '@frontend/components/form/field/FormField';
import FormFieldDateTime from '@frontend/components/form/field/FormFieldDateTime';
import FormGroup from '@frontend/components/form/FormGroup';
import FormInput from '@frontend/components/form/FormInput';
import { Form, Formik } from 'formik';
import React from 'react';

export interface ActivityFiltersFormValues {
  searchTerm: string;
  startDate: Date | null;
  endDate: Date | null;
}

interface Props {
  initialValues?: ActivityFiltersFormValues;
  onSubmit: (values: ActivityFiltersFormValues) => void;
}

const ActivityFiltersForm = ({
  initialValues = {
    searchTerm: '',
    startDate: null,
    endDate: null,
  },
  onSubmit,
}: Props) => {
  return (
    <Formik<ActivityFiltersFormValues>
      initialValues={initialValues}
      validate={values =>
        validateFields<ActivityFiltersFormValues>({
          searchTerm: value => {
            if (!value) {
              return '';
            }

            return isMinLength(3)(value);
          },
          endDate: value => {
            if (!value || !values.startDate) {
              return '';
            }

            return value < values.startDate
              ? 'End date must be before start date'
              : '';
          },
        })(values)
      }
      onSubmit={onSubmit}
    >
      <Form>
        <FormGroup>
          <FormField
            id="activityFiltersForm-searchTerm"
            name="searchTerm"
            as={FormInput}
            label="Search term"
            labelSize="xs"
            placeholder="Search activities"
          />

          <FormFieldDateTime
            id="activityFiltersForm-startDate"
            label="Start date"
            labelSize="xs"
            name="startDate"
            showTime={false}
          />

          <FormFieldDateTime
            id="activityFiltersForm-endDate"
            label="End date"
            labelSize="xs"
            name="endDate"
            showTime={false}
          />
        </FormGroup>

        <Button type="submit" block>
          Filter results
        </Button>
      </Form>
    </Formik>
  );
};

export default ActivityFiltersForm;
