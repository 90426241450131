import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import SMHGLogo from '@frontend/components/SMHGLogo';
import flexStyles from '@frontend/styles/flex';
import { smallBoxShadow } from '@frontend/styles/shadows';
import {
  breakpoints,
  headingSizes,
  spacings,
} from '@frontend/styles/variables';
import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

const wrapperStyle = css`
  ${flexStyles.flexCenterAll};
  height: 100%;
  min-height: 100vh;
  padding: ${spacings.md};

  @media (min-width: ${breakpoints.tablet}) {
    padding: ${spacings.lg};
  }
`;

const containerSharedStyle = css`
  ${flexStyles.flexCenterAll};
  ${flexStyles.column};
  ${smallBoxShadow()};
  border-radius: 5px;
  padding: ${spacings.lg};
  width: 100%;
`;

const containerStyle = css`
  ${containerSharedStyle};

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 600px;
    padding: ${spacings.xl};
  }
`;

const largeContainerStyle = css`
  ${containerSharedStyle};

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 1024px;
    padding: ${spacings.xl};
  }
`;

const logoStyle = css`
  height: auto;
  margin-bottom: ${spacings.md};
  width: 12rem;
`;

const headerStyle = css`
  font-size: ${headingSizes.md};
  text-align: center;
  text-transform: lowercase;
`;

interface Props {
  children: ReactNode;
  title: string;
  heading?: string;
  logo?: boolean;
  large?: boolean;
}

const PublicPage = ({
  children,
  title,
  heading = title,
  logo,
  large = false,
}: Props) => {
  const theme = useTheme();
  const themedWrapperStyle = css`
    ${wrapperStyle};
    background: ${theme.secondary};
    background: linear-gradient(
      90deg,
      ${theme.secondary} 0%,
      ${theme.secondaryHighlight} 100%
    );
  `;

  const themedContainerStyle = css`
    ${containerStyle};
    background: ${theme.background};
  `;

  const largeThemedContainerStyle = css`
    ${largeContainerStyle};
    background: ${theme.background};
  `;

  const themedHeaderStyle = css`
    ${headerStyle};
    color: ${theme.primary};
  `;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className={themedWrapperStyle}>
        <div
          className={large ? largeThemedContainerStyle : themedContainerStyle}
        >
          {logo && <SMHGLogo className={logoStyle} />}

          {heading && <h1 className={themedHeaderStyle}>{heading}</h1>}

          {children}
        </div>
      </div>
    </>
  );
};

export default PublicPage;
