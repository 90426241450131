import {
  MediaReferenceResource,
  RainyDayResource,
} from '@common/types/apiResources';
import { css } from '@emotion/css';
import ResponsiveCarousel from '@frontend/components/ResponsiveCarousel';
import { useMaxWidthMedia } from '@frontend/hooks/useMedia';
import MediaAddButton from '@frontend/pages/rainy-day/components/MediaAddButton';
import MediaReferenceCard from '@frontend/pages/rainy-day/components/MediaReferenceCard';
import { updateRainyDayMediaReferences } from '@frontend/services/mediaReferenceService';
import headingStyles from '@frontend/styles/headings';
import React from 'react';
import { Settings } from 'react-slick';

interface Props {
  userId: string;
  rainyDay: RainyDayResource | undefined;
  onChange: (rainyDay: RainyDayResource) => void;
}

const sliderClass = css`
  .slick-track {
    display: flex !important;

    .slick-slide {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      height: inherit !important;

      > div {
        flex-basis: 100%;
      }
    }
  }
`;

const buttonClass = css`
  margin: 0 1em;
`;

const MediaReferenceForm = ({ userId, rainyDay, onChange }: Props) => {
  const isTablet = useMaxWidthMedia('tablet');
  const isSmall = useMaxWidthMedia('small');
  const isDesktop = useMaxWidthMedia('desktop');

  const slidesToShow = isTablet ? 1 : isSmall ? 2 : isDesktop ? 3 : 4;

  const sliderSettings: Settings = {
    slidesToShow,
    className: sliderClass,
  };

  const mediaReferences = React.useMemo(() => rainyDay?.mediaReferences, [
    rainyDay,
  ]);

  const visibleMedia = React.useMemo(() => {
    if (mediaReferences === undefined) return [];

    return mediaReferences?.filter(
      mr => mr.type === 'image' || mr.type === 'video',
    );
  }, [mediaReferences]);

  const onCreate = React.useCallback(
    async (mediaReference: MediaReferenceResource) => {
      const newRainyDay = await updateRainyDayMediaReferences(rainyDay, [
        ...(mediaReferences ?? []),
        mediaReference,
      ]);
      onChange(newRainyDay);
    },
    [mediaReferences, onChange, rainyDay],
  );

  const onRemove = React.useCallback(
    async (mediaReference: MediaReferenceResource) => {
      const newRainyDay = await updateRainyDayMediaReferences(
        rainyDay,
        mediaReferences?.filter(mr => mr.id !== mediaReference.id),
      );
      onChange(newRainyDay);
    },
    [mediaReferences, onChange, rainyDay],
  );

  return (
    <div>
      <h2 className={headingStyles.sm}>
        Add any images/videos that make you feel better
      </h2>
      <ResponsiveCarousel alwaysShowSlider sliderSettings={sliderSettings}>
        {visibleMedia?.map(mediaReference => (
          <MediaReferenceCard
            key={mediaReference.id}
            mediaReference={mediaReference}
            onRemove={m => onRemove(m)}
            className={buttonClass}
          />
        ))}

        {(visibleMedia?.length ?? 0) < 15 ? (
          <MediaAddButton
            userId={userId}
            onSave={m => onCreate(m)}
            className={buttonClass}
            id="mediaAddButton"
          />
        ) : (
          <></>
        )}
      </ResponsiveCarousel>
    </div>
  );
};

export default MediaReferenceForm;
