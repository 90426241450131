import { Theme } from '@emotion/react';
import { darken } from 'polished';
import { baseColours } from '../styles/variables';

const defaultTheme: Theme = {
  background: baseColours.white,
  background1: baseColours.greyWhite,
  background2: baseColours.greyLight,
  border: baseColours.grey,
  border1: baseColours.greyLight,
  noticeBackground: baseColours.pinkLight,
  noticeLinkColor: baseColours.blueDark,
  disabledButtonBackground: baseColours.greyWhite,
  disabledButtonBorder: baseColours.grey,
  disabledButtonColor: baseColours.white,
  error: baseColours.red,
  feedbackBubbleColor: baseColours.pinkLight,
  inputColor: baseColours.blue,
  link: baseColours.blue,
  linkHover: baseColours.blueLight,
  linkDisabled: baseColours.grey,
  modalDismissBackground: baseColours.greyDark,
  modalDismissColor: baseColours.white,
  primary: baseColours.blue,
  primaryHighlight: baseColours.blueLight,
  primaryAlternate: darken(0.1, baseColours.blue),
  secondary: baseColours.pink,
  secondaryHighlight: baseColours.pinkLight,
  success: baseColours.green,
  tertiary: baseColours.blueDark,
  text: baseColours.black,
  text1: baseColours.greyDark,
  text2: baseColours.grey,
  textContrast: baseColours.white,
  textPrimary: baseColours.blueDark,
  warning: baseColours.orange,
};
export default defaultTheme;
