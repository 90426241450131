import { ActivityResource } from '@common/types/apiResources';
import { SmhgActivityFindParams } from '@common/types/smhg';
import { cx } from '@emotion/css';
import SortDropdown from '@frontend/components/SortDropdown';
import useAsyncRetry from '@frontend/hooks/useAsyncRetry';
import usePaginationQuery from '@frontend/hooks/usePaginationQuery';
import ActivityFiltersForm, {
  ActivityFiltersFormValues,
} from '@frontend/pages/favourites/components/ActivityFiltersForm';
import FavouriteAddCard from '@frontend/pages/favourites/components/FavouriteAddCard';
import FavouritesAddPage from '@frontend/pages/favourites/components/FavouritesAddPage';
import favouriteService from '@frontend/services/favouriteService';
import activityService from '@frontend/services/activityService';
import flexStyles from '@frontend/styles/flex';
import spacingStyles from '@frontend/styles/spacings';
import tryAsyncToast from '@frontend/utils/tryAsyncToast';
import { format } from 'date-fns';
import produce from 'immer';
import React, { useCallback, useState } from 'react';

const FavouritesActivitiesPage = () => {
  const [filters, setFilters] = useState<ActivityFiltersFormValues>({
    searchTerm: '',
    startDate: null,
    endDate: null,
  });
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const { $limit, $skip } = usePaginationQuery({
    limit: 10,
  });

  const {
    value: paginated,
    isLoading,
    setValue: setPaginated,
  } = useAsyncRetry(() => {
    const { searchTerm, startDate, endDate } = filters;
    const params: SmhgActivityFindParams = {};

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (startDate) {
      params.startDate = format(startDate, 'yyyy-MM-dd');
    }
    if (endDate) {
      params.endDate = format(endDate, 'yyyy-MM-dd');
    }

    params.page = $skip === 0 ? 1 : $skip / $limit + 1;
    // eslint-disable-next-line @typescript-eslint/camelcase
    params.per_page = $limit;

    return activityService.find({ query: params });
  }, [$limit, $skip, filters, sortDirection]);

  const handleAdd = useCallback(
    async (activity: ActivityResource) => {
      if (!paginated) {
        return;
      }

      await tryAsyncToast(
        async () => {
          const favourite = await favouriteService.create({
            type: 'activity',
            typeId: activity.id,
          });

          setPaginated(
            produce(paginated, draft => {
              const number = draft.data.findIndex(
                data => data.id === favourite.typeId,
              );
              draft.data[number].favouriteId = favourite.id;
            }),
          );
        },
        {
          success: (
            <strong>
              Activity added to favourites.
              <br />
              This can now be set as a goal.
            </strong>
          ),
          error: 'Could not add to favourites',
        },
      );
    },
    [paginated, setPaginated],
  );

  const handleDelete = useCallback(
    async (activity: ActivityResource) => {
      if (!paginated) {
        return;
      }

      await tryAsyncToast(
        async () => {
          if (!activity.favouriteId) {
            return;
          }

          await favouriteService.remove(activity.favouriteId);

          setPaginated(
            produce(paginated, draft => {
              draft.data[
                draft.data.findIndex(
                  item => item.favouriteId === activity.favouriteId,
                )
              ].favouriteId = undefined;
            }),
          );
        },
        {
          success: 'Activity deleted from favourites',
          error: 'Could not delete from favourites',
        },
      );
    },
    [paginated, setPaginated],
  );

  return (
    <FavouritesAddPage<ActivityResource>
      title="Add favourite activities"
      emptyText="No activities found"
      addCard={item => (
        <FavouriteAddCard<ActivityResource>
          resource={item}
          onAdd={handleAdd}
          onDelete={handleDelete}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: item.excerpt }} />
        </FavouriteAddCard>
      )}
      filterForm={<ActivityFiltersForm onSubmit={setFilters} />}
      sortDropdown={
        <div className={cx(flexStyles.flex, flexStyles.justifyEnd)}>
          <SortDropdown
            aria-label="Sort activities"
            className={spacingStyles.marginBottom.md}
            options={[
              {
                value: 'asc',
                label: 'Sort by title (A-Z)',
              },
              {
                value: 'desc',
                label: 'Sort by title (Z-A)',
              },
            ]}
            onChange={option => {
              setSortDirection(option.value as 'asc' | 'desc');
            }}
          />
        </div>
      }
      paginated={paginated}
      loading={isLoading}
    />
  );
};

export default FavouritesActivitiesPage;
