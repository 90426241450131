import LoadingSpinner from '@frontend/components/LoadingSpinner';
import Timeline from '@frontend/components/Timeline';
import TimelineItem from '@frontend/components/TimelineItem';
import useAsyncRetry from '@frontend/hooks/useAsyncRetry';
import goalService from '@frontend/services/goalService';
import { endOfMonth, format, startOfMonth } from 'date-fns/esm';
import React from 'react';
import { VisuallyHidden } from 'reakit';

const RecentAchievements = () => {
  const { value: achievements, isLoading } = useAsyncRetry(async () => {
    return goalService.find({
      query: {
        completedAt: {
          $gte: startOfMonth(new Date()).toISOString(),
          $lte: endOfMonth(new Date()).toISOString(),
        },
        $limit: 5,
        $sort: {
          completedAt: -1,
        },
      },
    });
  }, []);

  return (
    <LoadingSpinner size="md" center loading={isLoading}>
      {achievements && (
        <>
          {achievements.total > 0 ? (
            <Timeline>
              <TimelineItem marker colour="primary">
                This month
              </TimelineItem>
              {achievements?.data
                .filter(achievement => !!achievement.completedAt)
                .map(achievement => (
                  <TimelineItem key={achievement.id}>
                    <span>{achievement.name}</span>

                    <p>
                      <time>
                        <VisuallyHidden>Achieved on: </VisuallyHidden>
                        {format(
                          new Date(achievement.completedAt as string),
                          'EEEE do',
                        )}
                      </time>
                    </p>
                  </TimelineItem>
                ))}
            </Timeline>
          ) : (
            <p>You have no recent achievements.</p>
          )}
        </>
      )}
    </LoadingSpinner>
  );
};

export default RecentAchievements;
