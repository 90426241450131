import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { smallBoxShadow } from '@frontend/styles/shadows';
import { spacings } from '@frontend/styles/variables';
import React, { ReactNode } from 'react';

const style = css`
  ${smallBoxShadow()};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: ${spacings.lg};
`;

interface Props {
  children: ReactNode;
  className?: string;
}

const Card = ({ children, className }: Props) => {
  const theme = useTheme();
  const themedStyle = css`
    ${style};
    background-color: ${theme.background1};
  `;

  return <div className={cx(themedStyle, className)}>{children}</div>;
};

export default Card;
