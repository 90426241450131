import { ServiceResource } from '@common/types/apiResources';
import apiClient from '@frontend/services/client/apiClient';
import { FrontendCrudService } from '@frontend/types/service';
import { SmhgFindParams } from '@common/types/smhg';

const serviceService: FrontendCrudService<
  ServiceResource,
  { query: SmhgFindParams }
> = apiClient.service('/api/services');

export default serviceService;
