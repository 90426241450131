import { breakpoints, spacings } from '@frontend/styles/variables';
import { css, cx } from '@emotion/css';
import React, { ReactNode } from 'react';

const style = css`
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-bottom: ${spacings.md};
    width: 100%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    & > * {
      margin-bottom: 0;
      width: auto;
    }

    & > :not(:last-child) {
      margin-right: ${spacings.sm};
    }
  }
`;

interface Props {
  children: ReactNode;
  className?: string;
}

const ButtonGroup = ({ children, className }: Props) => {
  return <div className={cx(style, className)}>{children}</div>;
};

export default ButtonGroup;
