import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import Button from '@frontend/components/Button';
import DismissIcon from '@frontend/components/DismissButton';
import LinkPreview from '@frontend/components/LinkPreview';
import SuccessMessage from '@frontend/components/SuccessMessage';
import flexStyles from '@frontend/styles/flex';
import headingStyles from '@frontend/styles/headings';
import { positionStyles } from '@frontend/styles/position';
import {
  fontWeights,
  headingSizes,
  spacings,
} from '@frontend/styles/variables';
import { linearGradient } from 'polished';
import React, { useCallback } from 'react';

const cardStyle = css`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: ${spacings.lg};
  position: relative;
`;

const completedStyle = css`
  border: 0;
`;

const headingStyle = css`
  font-size: ${headingSizes.sm};
`;

const completedMessageStyle = css`
  font-size: 1.2rem;
  font-weight: ${fontWeights.semiBold};
  margin-bottom: 0.8rem;
`;

interface Props {
  id: string;
  name: string;
  link?: string;
  completed?: boolean;
  onComplete?: (id: string) => void;
  onDelete?: (id: string) => void;
}

const GoalCard = ({
  id,
  name,
  link,
  completed = false,
  onComplete,
  onDelete,
}: Props) => {
  const theme = useTheme();
  const themedCardStyle = css`
    ${cardStyle};
    background-color: ${theme.background1};
    border: 1px solid ${theme.border};
  `;
  const themedCompletedStyle = css`
    ${completedStyle}
    ${linearGradient({
      colorStops: [`${theme.secondaryHighlight} 0%`, `${theme.secondary} 100%`],
      fallback: theme.secondary,
      toDirection: '139deg',
    })};
  `;
  const themedHeadingStyle = css`
    ${headingStyle};
    color: ${theme.primaryAlternate};
  `;
  const themedCompletedMessageStyle = css`
    ${completedMessageStyle};
    color: ${theme.primary};
  `;

  const handleComplete = useCallback(() => {
    if (onComplete) {
      onComplete(id);
    }
  }, [id, onComplete]);

  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(id);
    }
  }, [id, onDelete]);

  return (
    <div className={cx(themedCardStyle, completed && themedCompletedStyle)}>
      <h3 className={themedHeadingStyle}>{name}</h3>

      {link && <LinkPreview url={link} showTitle={false} />}

      <DismissIcon
        onClick={handleDelete}
        className={cx(
          positionStyles.absolute,
          positionStyles.top(spacings.sm),
          positionStyles.right(spacings.sm),
        )}
      >
        Delete
      </DismissIcon>

      <div className={cx(flexStyles.flex, flexStyles.justifyCenter)}>
        {completed ? (
          <SuccessMessage
            className={themedCompletedMessageStyle}
            iconClassName={headingStyles.md}
          >
            Completed
          </SuccessMessage>
        ) : (
          <Button inverted onClick={handleComplete}>
            Press to Complete
          </Button>
        )}
      </div>
    </div>
  );
};

export default GoalCard;
