import { FavouriteResource } from '@common/types/apiResources';
import { FeathersError } from '@feathersjs/errors';
import LoadingPage from '@frontend/components/LoadingPage';
import favouriteService from '@frontend/services/favouriteService';
import tryAsyncToast from '@frontend/utils/tryAsyncToast';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

interface Params {
  typeId: string;
}

const favouriteTypes: {
  [key in Props['type']]: FavouriteResource['type'];
} = {
  activities: 'activity',
  services: 'service',
};

interface Props extends RouteComponentProps<Params> {
  type: 'activities' | 'services';
}
const FavouritesExternalPage = ({ history, match, type }: Props) => {
  const { typeId } = match.params;

  useEffect(() => {
    const addToFavourites = async () => {
      const existingFavourites = await favouriteService.find({
        query: {
          typeId,
          type: favouriteTypes[type],
          $limit: 0,
        },
      });

      try {
        await tryAsyncToast(
          async () => {
            if (existingFavourites.total === 0) {
              await favouriteService.create({
                type: favouriteTypes[type],
                typeId: parseInt(typeId, 10),
              });
            }
          },
          {
            success: `Added ${favouriteTypes[type]} to favourites.`,
            error: error => {
              if (
                error instanceof FeathersError &&
                error.errors?.typeId?.keyword === 'exists'
              ) {
                return `Could not find ${favouriteTypes[type]}`;
              }

              return `Could not add ${favouriteTypes[type]} to favourites`;
            },
          },
        );
      } finally {
        history.push('/favourites');
      }
    };

    addToFavourites();
  }, [history, type, typeId]);

  return <LoadingPage />;
};

export default FavouritesExternalPage;
