import { ThemeProvider, useTheme } from '@emotion/react';
import themes, { Themes } from '@frontend/themes';
import { themedGlobalTypographyStyles } from '@frontend/styles/typography';
import customInjectGlobal from '@frontend/utils/injectGlobal';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';

export const myToolkitThemeKey = 'my-toolkit-theme';

interface ThemeContextState {
  currentThemeKey: keyof Themes | undefined;
  setThemeKey: (key: keyof Themes) => void;
}

export const ThemeContext = createContext<ThemeContextState | undefined>(
  undefined,
);

export const useThemeContext = () => {
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error(
      'a component calling useThemeContext is not wrapped by ThemeContext',
    );
  }

  const { currentThemeKey, setThemeKey } = themeContext;
  return {
    setThemeKey,
    currentThemeKey,
    themeKeyOptions: Object.keys(themes),
  };
};

export const ThemedGlobalStyles = () => {
  const theme = useTheme();

  useEffect(() => {
    const themedGlobalStyles = themedGlobalTypographyStyles(theme);
    const removeStyles = customInjectGlobal(themedGlobalStyles);
    return () => removeStyles();
  }, [theme]);

  return null;
};

interface ThemeContextWrapperProps {
  theme?: keyof Themes;
  children: ReactNode;
}

const ThemeContextWrapper = ({ children, theme }: ThemeContextWrapperProps) => {
  const defaultTheme: keyof Themes =
    theme ||
    window.localStorage.getItem(myToolkitThemeKey) ||
    (window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'indigoDark'
      : 'default');

  const [currentThemeKey, setCurrentThemeKey] = useState(defaultTheme);

  return (
    <ThemeContext.Provider
      value={{
        currentThemeKey,
        setThemeKey: key => {
          setCurrentThemeKey(key as string);
          window.localStorage.setItem(myToolkitThemeKey, key as string);
        },
      }}
    >
      <ThemeProvider theme={themes[currentThemeKey]}>
        <ThemedGlobalStyles />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextWrapper;
