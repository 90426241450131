import { Theme } from '@emotion/react';
import { darken, lighten } from 'polished';
import { baseColours } from '../styles/variables';

const themeColours = {
  greenDark: '#379393',
  green: '#9AD3BC',
  red: '#DB5461',
  orange: '#F5A25D',
};

const watermelon: Theme = {
  background: baseColours.white,
  background1: baseColours.greyWhite,
  background2: baseColours.greyLight,
  border: baseColours.grey,
  border1: baseColours.greyLight,
  noticeBackground: lighten(0.1, themeColours.green),
  noticeLinkColor: darken(0.2, themeColours.red),
  disabledButtonBackground: baseColours.greyWhite,
  disabledButtonBorder: baseColours.grey,
  disabledButtonColor: baseColours.white,
  error: baseColours.red,
  feedbackBubbleColor: themeColours.orange,
  inputColor: baseColours.white,
  link: themeColours.red,
  linkHover: darken(0.2, themeColours.red),
  linkDisabled: baseColours.grey,
  modalDismissBackground: baseColours.greyDark,
  modalDismissColor: baseColours.white,
  primary: themeColours.green,
  primaryHighlight: lighten(0.1, themeColours.green),
  primaryAlternate: darken(0.1, themeColours.green),
  secondary: themeColours.orange,
  secondaryHighlight: themeColours.red,
  success: baseColours.green,
  tertiary: themeColours.greenDark,
  text: baseColours.black,
  text1: baseColours.greyDark,
  text2: baseColours.grey,
  textContrast: baseColours.white,
  textPrimary: themeColours.greenDark,
  warning: baseColours.orange,
};
export default watermelon;
