import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { fonts, headingSizes } from '@frontend/styles/variables';
import React, { ReactNode } from 'react';

const style = css`
  font-family: ${fonts.heading};
  font-size: ${headingSizes.xs};
  text-align: center;
`;

interface Props {
  children: ReactNode;
  className?: string;
}

const NoItemsMessage = ({ children, className }: Props) => {
  const theme = useTheme();
  const themedStyle = css`
    ${style};
    color: ${theme.tertiary};
  `;

  return <div className={cx(themedStyle, className)}>{children}</div>;
};

export default NoItemsMessage;
