import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { smallBoxShadow } from '@frontend/styles/shadows';
import { spacings } from '@frontend/styles/variables';
import React, { Children, cloneElement, ReactElement } from 'react';
import { Menu, MenuDisclosure, useMenuState } from 'reakit';

const style = css`
  ${smallBoxShadow()};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-right: ${spacings.md};
  margin-top: ${spacings.xs};
  outline: 0;
  z-index: 10;
`;

interface Props {
  'aria-label': string;
  button: ReactElement;
  children: ReactElement | ReactElement[];
}

const DropdownMenu = ({ 'aria-label': ariaLabel, button, children }: Props) => {
  const theme = useTheme();
  const themedStyle = css`
    ${style};
    background: ${theme.background};
    border: 1px solid ${theme.border};
  `;

  const menu = useMenuState();

  return (
    <>
      <MenuDisclosure {...menu}>
        {disclosureProps =>
          cloneElement(Children.only(button), disclosureProps)
        }
      </MenuDisclosure>

      <Menu {...menu} className={themedStyle} aria-label={ariaLabel}>
        {Children.map(children, child => cloneElement(child, menu))}
      </Menu>
    </>
  );
};

export default DropdownMenu;
