import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import flexStyles from '@frontend/styles/flex';
import { spacings } from '@frontend/styles/variables';
import React, { ComponentType, ReactNode } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const messageStyle = css`
  display: block;
  text-transform: uppercase;
`;

const iconStyle = css`
  font-size: 1.2rem;
  margin-right: ${spacings.xs};
`;

interface Props {
  children: ReactNode;
  className?: string;
  icon?: ComponentType<{ className: string }>;
  iconClassName?: string;
}

const SuccessMessage = ({
  children,
  className,
  icon: Icon = FaCheckCircle,
  iconClassName,
}: Props) => {
  const theme = useTheme();
  const themedMessageStyle = css`
    ${messageStyle};
    color: ${theme.success};
  `;

  return (
    <div className={cx(themedMessageStyle, className)}>
      <span className={cx(flexStyles.flex, flexStyles.alignItemsCenter)}>
        <Icon className={cx(iconStyle, iconClassName)} /> {children}
      </span>
    </div>
  );
};

export default SuccessMessage;
