import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { fontWeights, spacings } from '@frontend/styles/variables';
import React, { ReactNode } from 'react';

const style = css`
  display: block;
  font-weight: ${fontWeights.light};
  margin-bottom: ${spacings.sm};
`;

interface Props {
  children: ReactNode;
  className?: string;
  id: string;
}

const FormHint = ({ children, className, id }: Props) => {
  const theme = useTheme();
  const themedStyle = css`
    ${style};
    color: ${theme.text1};
  `;
  return (
    <span id={id} className={cx(themedStyle, className)}>
      {children}
    </span>
  );
};

export default FormHint;
