import { css, cx, keyframes } from '@emotion/css';
import { Theme, useTheme } from '@emotion/react';
import flexStyles from '@frontend/styles/flex';
import React, { ReactNode } from 'react';

const chase = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const chaseDot = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const chaseDotBefore = keyframes`
  60% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
`;

const spinnerStyle = css`
  animation: ${chase} 3s infinite linear both;
  position: relative;
`;

const spinnerDotStyle = css`
  animation: ${chaseDot} 3s infinite ease-in-out both;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::before {
    animation: ${chaseDotBefore} 3s infinite ease-in-out both;
    border-radius: 100%;
    content: '';
    display: block;
    height: 25%;
    width: 25%;
  }

  &:nth-child(1) {
    animation-delay: -1.2s;
  }
  &:nth-child(2) {
    animation-delay: -1.05s;
  }
  &:nth-child(3) {
    animation-delay: -0.9s;
  }
  &:nth-child(4) {
    animation-delay: -0.75s;
  }
  &:nth-child(5) {
    animation-delay: -0.6s;
  }
`;

const spinnerSizes = {
  xl: '3rem',
  lg: '2.5rem',
  md: '2rem',
  sm: '1.5rem',
  xs: '1rem',
};

interface Props {
  children?: ReactNode;
  className?: string;
  colour?: keyof Theme;
  center?: boolean;
  loading?: boolean;
  size?: string | keyof typeof spinnerSizes;
}

const LoadingSpinner = ({
  children,
  center = false,
  className,
  colour = 'primary',
  loading = true,
  size = 'sm',
}: Props) => {
  const theme = useTheme();
  const spinnerSize = spinnerSizes[size as keyof typeof spinnerSizes] ?? size;

  const dotStyle = css`
    ${spinnerDotStyle}
    &::before {
      animation: ${chaseDotBefore} 3s infinite ease-in-out both;
      background-color: ${theme[colour]};
    }
  `;

  return (
    <>
      {loading ? (
        <div className={cx(center && flexStyles.flexCenterAll)}>
          <div
            className={cx(
              spinnerStyle,
              css`
                height: ${spinnerSize};
                width: ${spinnerSize};
              `,
              className,
            )}
          >
            <div className={dotStyle} />
            <div className={dotStyle} />
            <div className={dotStyle} />
            <div className={dotStyle} />
            <div className={dotStyle} />
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default LoadingSpinner;
