import Modal, { ModalRenderProps } from '@frontend/components/Modal';
import React, { cloneElement, ReactElement, ReactNode } from 'react';
import { DialogDisclosure, useDialogState } from 'reakit';

interface ModalButtonProps {
  'aria-label': string;
  button: ReactElement;
  children: ReactNode | ((props: ModalRenderProps) => ReactNode);
  show?: boolean;
}

const ModalButton = ({
  button,
  children,
  show = false,
  ...props
}: ModalButtonProps) => {
  const dialog = useDialogState({
    visible: show,
  });

  const { visible, hide, toggle } = dialog;

  return (
    <>
      <DialogDisclosure {...dialog}>
        {disclosureProps => cloneElement(button, disclosureProps)}
      </DialogDisclosure>

      <Modal {...props} {...dialog}>
        {typeof children === 'function'
          ? children({ visible, hide, show: dialog.show, toggle })
          : children}
      </Modal>
    </>
  );
};

export default ModalButton;
