import minDelay from '@common/utils/minDelay';
import getFieldErrors from '@frontend/utils/getFieldErrors';
import tryAsyncToast, { ToastOptions } from '@frontend/utils/tryAsyncToast';
import { FormikErrors } from 'formik';
import { useCallback, useState } from 'react';

export default function useFormSubmit<Values>(
  onSubmit: (values: Values) => void,
  toastOptions: ToastOptions = {
    success: 'Success!',
  },
) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<FormikErrors<Values>>({});

  const handleSubmit = useCallback(
    async (values: Values) => {
      setSubmitting(true);
      setSubmitted(false);

      await tryAsyncToast(async () => {
        // Increase delay so that the user is able
        // to perceive that a state change has
        // happened more easily.
        await minDelay(async () => {
          try {
            await onSubmit(values);
          } catch (err) {
            setFieldErrors(getFieldErrors(err));
            throw err;
          }
        }, 600);

        setSubmitted(true);
        setSubmitting(false);
      }, toastOptions);
    },
    [onSubmit, toastOptions],
  );

  return {
    isSubmitted,
    isSubmitting,
    fieldErrors,
    handleSubmit,
  };
}
