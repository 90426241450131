import createClient from '@auth0/auth0-spa-js';
import env from '@frontend/env';

export interface Auth0Options {
  redirectUri?: string;
  scope?: string;
}

export default function createAuth0Client(options: Auth0Options = {}) {
  return createClient({
    /* eslint-disable @typescript-eslint/camelcase */
    audience: env.auth0ApiAudience,
    client_id: env.auth0ClientId,
    domain: env.auth0Domain,
    scope: options.scope,
    redirect_uri: options.redirectUri,

    /* eslint-enable @typescript-eslint/camelcase */
  });
}
