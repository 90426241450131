import validator from 'validator';
export default function isUrl() {
  var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var message = 'Must be a valid URL';

  if (url === '') {
    return '';
  }

  return validator.isURL(url, {
    // eslint-disable-next-line @typescript-eslint/camelcase
    require_protocol: true
  }) ? '' : message;
}