import { ActivityResource, EventResource } from '@common/types/apiResources';
import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import Button from '@frontend/components/Button';
import ButtonGroup from '@frontend/components/ButtonGroup';
import ButtonLink from '@frontend/components/ButtonLink';
import Modal from '@frontend/components/Modal';
import Page from '@frontend/components/Page';
import useAsyncRetry from '@frontend/hooks/useAsyncRetry';
import Calendar from '@frontend/pages/calendar/components/Calendar';
import CalendarEventModal from '@frontend/pages/calendar/components/CalendarEventModal';
import eventService from '@frontend/services/eventService';
import favouriteService from '@frontend/services/favouriteService';
import flexStyles from '@frontend/styles/flex';
import headingStyles from '@frontend/styles/headings';
import spacingStyles from '@frontend/styles/spacings';
import { widthStyles } from '@frontend/styles/utils';
import { spacings } from '@frontend/styles/variables';
import '@fullcalendar/core/main.css';
import { ExtendedEventSourceInput } from '@fullcalendar/core/structs/event-source';
import '@fullcalendar/daygrid/main.css';
import { isEqual, startOfMonth } from 'date-fns/esm';
import React, { useMemo, useState } from 'react';
import { FaCalendarAlt, FaTrashAlt } from 'react-icons/fa';
import { useDialogState } from 'reakit';

const CalendarPage = () => {
  const theme = useTheme();
  const addEventModal = useDialogState();
  const selectedEventModal = useDialogState();
  const selectedActivityModal = useDialogState();

  const [monthStart, setMonthStart] = useState(startOfMonth(new Date()));
  const [selectedActivity, setSelectedActivity] = useState<ActivityResource>();
  const [selectedEvent, setSelectedEvent] = useState<EventResource>();

  const { value: paginated, retry } = useAsyncRetry(
    () =>
      Promise.all([
        favouriteService.find({
          query: {
            $limit: 1000,
            $sort: {},
            type: 'activity',
          },
        }),
        eventService.find({
          query: {
            $limit: 1000,
            endDate: {
              $gte: monthStart.toISOString(),
            },
          },
        }),
      ]),
    [monthStart],
  );

  const eventSources: ExtendedEventSourceInput[] = useMemo(() => {
    const mapToEvent = (event: EventResource | ActivityResource) => ({
      id: event.id,
      title: event.title,
      start: `${event.startDate}T${event.startTime}`,
      end: `${event.endDate}T${event.endTime}`,
      rrule: event.rrule ? event.rrule : undefined,
    });

    if (paginated) {
      return [
        {
          id: 'activity',
          events: paginated?.[0].data
            .filter(({ data }) => !!data)
            .map(favourite => mapToEvent(favourite.data as ActivityResource)),
          color: theme.secondary,
          textColor: theme.inputColor,
        },
        {
          id: 'event',
          events: paginated?.[1].data.map(mapToEvent),
          color: theme.primary,
        },
      ] as ExtendedEventSourceInput[];
    }

    return [];
  }, [paginated, theme]);

  return (
    <Page
      title="Calendar"
      titleIcon={FaCalendarAlt}
      intro="All the activities you’ve favourited in one place. You can add your own activities too."
      easyreadPdf="My Toolkit - Calendar"
    >
      <Calendar
        eventSources={eventSources}
        onAddEvent={() => {
          addEventModal.show();
        }}
        onDatesRender={date => {
          const newMonthStart = startOfMonth(date);

          if (!isEqual(newMonthStart, monthStart)) {
            setMonthStart(startOfMonth(date));
          }
        }}
        onEventClick={event => {
          if (event.source?.id === 'activity') {
            const matchingActivity = paginated?.[0].data.find(
              favourite =>
                (favourite.data as ActivityResource).id.toString() === event.id,
            );

            if (matchingActivity) {
              setSelectedActivity(matchingActivity.data as ActivityResource);
              selectedActivityModal.show();
            }
          } else if (event.source?.id === 'event') {
            const matchingEvent = paginated?.[1].data.find(
              e => e.id === event.id,
            );

            if (matchingEvent) {
              setSelectedEvent(matchingEvent);
              selectedEventModal.show();
            }
          }
        }}
      />

      <Modal {...selectedActivityModal} aria-label="Activity details">
        {modal => {
          if (!selectedActivity) {
            return null;
          }

          const { title, excerpt, url, imageUrl } = selectedActivity;

          return (
            <>
              <div
                className={cx(
                  flexStyles.flex,
                  flexStyles.justifySpaceBetween,
                  spacingStyles.marginBottom.md,
                )}
              >
                <div>
                  <h3
                    className={cx(
                      headingStyles.xs,
                      spacingStyles.marginBottom.none,
                    )}
                  >
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {title}
                    </a>
                  </h3>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                </div>

                {imageUrl && (
                  <div
                    className={css`
                      margin-left: ${spacings.sm};
                      width: 25%;
                    `}
                  >
                    <img
                      className={widthStyles.full}
                      src={imageUrl}
                      alt={`${title} logo`}
                    />
                  </div>
                )}
              </div>

              <ButtonGroup className={spacingStyles.marginTop.md}>
                <ButtonLink to={url} target="_blank" rel="noopener noreferrer">
                  More details
                </ButtonLink>
                <Button
                  inverted
                  onClick={async () => {
                    if (selectedActivity?.favouriteId) {
                      await favouriteService.remove(
                        selectedActivity?.favouriteId,
                      );
                    }

                    modal.hide();
                    await retry();
                  }}
                >
                  <FaTrashAlt className={spacingStyles.marginRight.xs} />
                  Delete from favourites
                </Button>
              </ButtonGroup>
            </>
          );
        }}
      </Modal>

      <CalendarEventModal
        {...addEventModal}
        aria-label="Add event"
        id="addCalendarEventForm"
        onSubmit={() => retry()}
        buttons={<Button type="submit">Create event</Button>}
      />

      <CalendarEventModal
        {...selectedEventModal}
        aria-label="Update event"
        id="updateCalendarEventForm"
        event={selectedEvent}
        onSubmit={() => retry()}
        buttons={
          <ButtonGroup>
            <Button type="submit">Update event</Button>

            <Button
              inverted
              onClick={async () => {
                if (!selectedEvent) {
                  return;
                }

                await eventService.remove(selectedEvent.id);

                selectedEventModal.hide();
                await retry();
              }}
            >
              <FaTrashAlt className={spacingStyles.marginRight.xs} />
              Delete event
            </Button>
          </ButtonGroup>
        }
      />
    </Page>
  );
};

export default CalendarPage;
