import { ActivityResource, ServiceResource } from '@common/types/apiResources';
import { css, cx } from '@emotion/css';
import Button from '@frontend/components/Button';
import Card from '@frontend/components/Card';
import PageLink from '@frontend/components/PageLink';
import flexStyles from '@frontend/styles/flex';
import headingStyles from '@frontend/styles/headings';
import spacingStyles from '@frontend/styles/spacings';
import { widthStyles } from '@frontend/styles/utils';
import { spacings } from '@frontend/styles/variables';
import React, { ReactNode, useCallback } from 'react';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';

export interface FavouriteAddCardProps<
  T extends ActivityResource | ServiceResource
> {
  children?: ReactNode;
  favouriteId?: string;
  resource: T;
  onAdd: (resource: T) => void;
  onDelete: (resource: T) => void;
}

const FavouriteAddCard = <T extends ActivityResource | ServiceResource>({
  children,
  resource,
  onAdd,
  onDelete,
}: FavouriteAddCardProps<T>) => {
  const { title, url, imageUrl, favouriteId } = resource;

  const handleClick = useCallback(() => {
    if (favouriteId) {
      onDelete(resource);
    } else {
      onAdd(resource);
    }
  }, [favouriteId, onAdd, onDelete, resource]);

  return (
    <Card>
      <div
        className={cx(
          flexStyles.flex,
          flexStyles.justifySpaceBetween,
          spacingStyles.marginBottom.md,
        )}
      >
        <h3 className={cx(headingStyles.xs, spacingStyles.marginBottom.none)}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </h3>

        {imageUrl && (
          <div
            className={css`
              margin-left: ${spacings.sm};
              width: 25%;
            `}
          >
            <img
              className={widthStyles.full}
              src={imageUrl}
              alt={`${title} logo`}
            />
          </div>
        )}
      </div>

      {children}

      <PageLink to={url} aria-hidden target="_blank" rel="noopener noreferrer">
        More information
      </PageLink>

      <Button inverted={!!favouriteId} onClick={handleClick}>
        {favouriteId ? (
          <>
            <FaMinusCircle className={spacingStyles.marginRight.xs} />
            Delete favourite
          </>
        ) : (
          <>
            <FaPlusCircle className={spacingStyles.marginRight.xs} />
            Favourite
          </>
        )}
      </Button>
    </Card>
  );
};

export default FavouriteAddCard;
