import Tooltip from '@frontend/components/Tooltip';
import flexStyles from '@frontend/styles/flex';
import { themedFocusOutline } from '@frontend/styles/focus';
import spacingStyles from '@frontend/styles/spacings';
import { fonts, fontWeights } from '@frontend/styles/variables';
import { css, cx } from '@emotion/css';
import { lighten } from 'polished';
import React, { forwardRef, ReactNode, Ref } from 'react';
import { FaTimes } from 'react-icons/fa';
import {
  Button,
  ButtonProps,
  TooltipReference,
  useTooltipState,
  VisuallyHidden,
} from 'reakit';
import { Theme, useTheme } from '@emotion/react';

export const iconButtonStyle = css`
  ${flexStyles.flexCenterAll};
  background: none;
  border: 0;
  cursor: pointer;
  font-family: ${fonts.body};
  font-size: 1.2rem;
  font-weight: ${fontWeights.semiBold};
  outline: none;
  padding: 0;
  text-transform: uppercase;

  &:disabled {
    cursor: not-allowed;
  }
`;

interface Props extends ButtonProps {
  colour?: keyof Theme;
  backgroundColour?: keyof Theme;
  children: ReactNode;
  showText?: boolean;
}

const DismissButton = (
  {
    colour = 'text1',
    backgroundColour = 'modalDismissBackground',
    className,
    children,
    showText = false,
    ...props
  }: Props,
  ref: Ref<HTMLButtonElement>,
) => {
  const tooltip = useTooltipState();
  const theme = useTheme();

  const themedStyle = css`
    ${iconButtonStyle};
    ${themedFocusOutline(theme)};
    color: ${theme[colour]};

    &:hover {
      color: ${lighten(0.1, theme[colour])};
    }
  `;

  return (
    <>
      {showText ? (
        <Button {...props} className={cx(themedStyle, className)} ref={ref}>
          <span className={spacingStyles.marginRight.xs}>{children}</span>

          <FaTimes
            className={css`
              font-size: 1.4rem;
            `}
          />
        </Button>
      ) : (
        <>
          <TooltipReference
            {...props}
            {...tooltip}
            as={Button}
            className={cx(themedStyle, className)}
            ref={ref}
          >
            <VisuallyHidden>{children}</VisuallyHidden>

            <FaTimes
              className={css`
                font-size: 1.4rem;
              `}
            />
          </TooltipReference>
          <Tooltip {...tooltip} colour={backgroundColour}>
            {children}
          </Tooltip>
        </>
      )}
    </>
  );
};

export default forwardRef<HTMLButtonElement, Props>(DismissButton);
