import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import React from 'react';

interface Props {
  className?: string;
}

const SMHGLogo = ({ className }: Props) => {
  const theme = useTheme();

  const a = css`
    fill: ${theme.tertiary};
  `;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 793.6781 181.8914"
    >
      <title>Sheffield Mental Health Guide</title>
      <path
        className={a}
        d="M167.4781,163.381c-.0055,2.9433-2.2836,4.6184-4.7113,3.6987-2.2406-.8488-4.3953-1.9285-6.574-2.9364q-36.5484-16.91-73.0921-33.8291c-6.4278-2.9755-12.8259-6.0173-19.2981-8.8927a7.0588,7.0588,0,0,1-3.6146-3.8964Q47.4634,90.0027,34.7036,62.4962,25.1873,41.9675,15.6591,21.4445c-.3354-.7232-.6344-1.464-.9309-2.2043a3.248,3.248,0,0,1,.8386-3.7761,3.2557,3.2557,0,0,1,3.811-.6676c1.5582.7126,3.1285,1.3993,4.6829,2.12Q54.853,31.196,85.6431,45.48c10.6748,4.9467,21.3456,9.9027,32.0584,14.766a7.4807,7.4807,0,0,1,3.9612,3.9548q11.57,25.2294,23.2531,50.407,7.9277,17.1071,15.94,34.1746c1.981,4.2454,3.9691,8.4875,5.9328,12.7409C167.118,162.2369,167.339,163.0009,167.4781,163.381ZM90.8527,75.8718a15.5039,15.5039,0,0,0-15.1063,15.155c-.0848,7.8033,7.3455,15.1554,15.3364,14.9765a15.547,15.547,0,0,0,15.1424-14.8306A15.5732,15.5732,0,0,0,90.8527,75.8718Z"
      />
      <path
        className={a}
        d="M14.4967,163.2583a18.6354,18.6354,0,0,1,.6753-1.8368q7.802-16.77,15.6263-33.529c2.2617-4.8633,4.4778-9.7479,6.7142-14.623.7426-1.6187,1.5852-3.0671,3.734-2.96,2.063.103,2.9417,1.514,3.6818,3.1295,2.044,4.4618,4.15,8.8967,6.1042,13.3973a7.2366,7.2366,0,0,0,3.9727,4.0079c4.0848,1.7706,8.1187,3.6588,12.1713,5.5037a20.4758,20.4758,0,0,1,2.1622,1.04c3.1618,1.9173,3.1314,4.9394-.1888,6.5677-5.4373,2.6664-10.9515,5.1766-16.4436,7.7306q-13.8277,6.43-27.67,12.8292c-1.97.911-3.92,1.8893-5.9561,2.6242C16.6343,168.0229,14.4456,166.2959,14.4967,163.2583Z"
      />
      <path
        className={a}
        d="M167.4791,18.6133c-.1763.4505-.4947,1.419-.9232,2.3359-7.4466,15.9344-14.9445,31.845-22.3082,47.8175-.7777,1.6869-1.7682,2.6791-3.4728,2.8017-1.88.1351-2.8638-1.2319-3.5737-2.7394a114.8649,114.8649,0,0,1-4.82-10.6033,13.1779,13.1779,0,0,0-8.4894-8.6217c-3.5319-1.2789-6.8745-3.0787-10.3076-4.6331-1.62-.7337-3.1793-1.6043-3.2323-3.6153-.0561-2.1291,1.4168-3.1876,3.1927-4.0005q16.6626-7.6276,33.3014-15.3076c5.0847-2.3372,10.1556-4.7057,15.2716-6.9722C165.0188,13.79,167.4505,15.203,167.4791,18.6133Z"
      />
      <path
        className={a}
        d="M91.1461,181.8787c-3.67,0-7.3407.0353-11.01-.0114-2.9038-.037-3.9291-.7443-4.8873-3.3849q-2.9129-8.028-5.7294-16.0908c-1.2972-3.7235-.9885-4.4752,2.5208-6.159,4.9444-2.3724,9.9525-4.6141,14.866-7.0478a8.5068,8.5068,0,0,1,8.1473.03c5.0064,2.491,10.13,4.7462,15.1787,7.1531,3.067,1.4621,3.491,2.3967,2.321,5.7009-1.9777,5.5856-4.1946,11.0883-6.096,16.6986-.82,2.4194-2.3686,3.1334-4.6448,3.1175C98.257,181.8605,94.7015,181.8788,91.1461,181.8787Z"
      />
      <path
        className={a}
        d="M91.4166.02c3.6685.0006,7.3377.0451,11.0051-.0172a3.6771,3.6771,0,0,1,3.9176,2.6519c2.1484,5.8827,4.3832,11.7347,6.461,17.642.8335,2.37.1179,3.9537-2.1856,5.0448-5.7765,2.7362-11.603,5.3666-17.4091,8.04a5.1565,5.1565,0,0,1-4.6067-.0764c-5.5762-2.6141-11.2038-5.1188-16.7764-7.74-3.0164-1.419-3.5343-2.6408-2.4612-5.7045C71.3185,14.273,73.3805,8.7221,75.3525,3.14A4.362,4.362,0,0,1,80.0676.0014C83.85.0689,87.6335.02,91.4166.02Z"
      />
      <path
        className={a}
        d="M181.9214,91.1346c0,3.5545.021,7.1091-.0059,10.6634-.0238,3.1522-.1875,3.4409-3.07,4.5349-5.5393,2.1024-11.0881,4.1822-16.6633,6.187-3.0788,1.107-4.3832.5726-5.76-2.3531-2.6226-5.5728-5.1228-11.2032-7.7393-16.779a5.3476,5.3476,0,0,1,.0087-4.9067c2.6258-5.5746,5.1121-11.215,7.742-16.7877,1.38-2.9236,2.6267-3.3993,5.7833-2.2968,5.5927,1.9533,11.1553,3.9926,16.73,5.9962a4.0249,4.0249,0,0,1,3.0136,4.3919C181.8127,83.5627,181.9211,87.3508,181.9214,91.1346Z"
      />
      <path
        className={a}
        d="M22.0187,68.849A2.8832,2.8832,0,0,1,25.011,70.69c2.8127,6.1176,5.763,12.1746,8.423,18.357a5.9785,5.9785,0,0,1-.217,4.2285c-2.4254,5.6673-5.0478,11.2519-7.676,16.83-1.4094,2.9914-2.6271,3.5228-5.7223,2.4232-5.8-2.0607-11.5495-4.2634-17.3394-6.353a3.4425,3.4425,0,0,1-2.4726-3.6531Q.038,90.8194,0,79.1161A3.1639,3.1639,0,0,1,2.04,75.85c6.2969-2.36,12.6293-4.6259,18.9551-6.9086A4.0267,4.0267,0,0,1,22.0187,68.849Z"
      />
      <path
        className={a}
        d="M205.3116,142.0531c0-4.8153.0106-9.6306-.0073-14.4459-.0041-1.0829.0421-2.0106,1.3361-2.4384a2.4808,2.4808,0,0,1,3.0551.82,21.4121,21.4121,0,0,1,1.4415,2.3258c2.9042,5.05,5.8164,10.0951,8.6848,15.1652.6567,1.1608,1.0362,1.2061,1.7253-.0039q4.6623-8.188,9.4672-16.294a5.1894,5.1894,0,0,1,2.114-2.03,2.1726,2.1726,0,0,1,3.101,1.723,6.8,6.8,0,0,1,.0272,1.3728c.0008,9.516.0149,19.032-.0091,28.5479-.0061,2.42-.46,2.9307-2.2944,2.9547-1.8478.0241-2.4166-.5631-2.4318-2.8338-.0375-5.6176,0-11.2357-.0233-16.8535-.0045-1.1011-.1443-2.2016-.2217-3.3025a32.3056,32.3056,0,0,0-1.9322,2.6325c-2.043,3.4351-3.9776,6.9353-6.0506,10.3518-1.6452,2.7115-3.4091,2.7361-5.0333.0551-2.3673-3.9076-4.6052-7.8933-6.9264-11.8292a8.0261,8.0261,0,0,0-1.08-1.2043,14.4722,14.4722,0,0,0-.189,1.6774c.001,5.9616-.0011,11.9234.0528,17.8847.0134,1.4839-.1639,2.78-1.7793,3.3184-1.773.5908-2.9811-.3462-3.01-2.46-.0556-4.1266-.0173-8.2546-.0179-12.382Q205.311,143.4289,205.3116,142.0531Z"
      />
      <path
        className={a}
        d="M670.99,150.6863c.001,2.1786-.0261,4.3577.0107,6.5357.0245,1.4489-.3509,2.4915-2.0431,2.529-1.3445.03-2.641-.0493-2.6644-1.842s-.721-1.1208-1.5776-.5461c-6.5507,4.3938-17.877,3.2564-23.1008-2.4827-8.6574-9.5115-5.2389-26.3908,8.1962-29.7163,7.5028-1.8571,14.6464.2914,19.3392,6.4183.7815,1.02,2.0033,2.18.6907,3.6146-1.0218,1.117-3.1782.8093-4.4226-.7328a13.2009,13.2009,0,0,0-13.6675-4.97c-6.4907,1.2272-10.1892,6.8944-10.2,12.8331-.0114,6.29,4.3869,11.8409,10.1649,12.89a14.2245,14.2245,0,0,0,13.1488-3.9129,4.679,4.679,0,0,0,1.3544-2.2659c.6086-2.9962.07-3.5723-2.9965-3.5912-1.72-.0105-3.44-.0216-5.16.0009-1.4415.0188-2.0787-.6965-2.0923-2.0984s.5855-2.1239,2.0507-2.1138c3.5544.0245,7.11.05,10.6634-.0118,1.6169-.028,2.3148.6769,2.3074,2.2384C670.9805,145.87,670.9888,148.2783,670.99,150.6863Z"
      />
      <path
        className={a}
        d="M730.2624,142.12c0-4.8119.0153-9.6239-.01-14.4356-.007-1.3555.0893-2.7482,1.81-2.7082,6.0278.14,12.0634-.5694,18.0706.9541,9.8616,2.5011,15.0129,14.0714,11.0895,23.1885-2.9928,6.9547-8.5613,10.16-15.85,10.5682-4.1119.23-8.2469.1019-12.3706.0509-2.42-.03-2.7074-.3471-2.7274-2.8385C730.2346,151.973,730.2626,147.0463,730.2624,142.12Zm4.7682.0983c0,3.6705.0343,7.3415-.0181,11.0113-.0189,1.3244.4745,1.833,1.8046,1.8048,3.0955-.0656,6.2314.2719,9.2824-.1064,6.8558-.85,10.8364-4.61,11.66-11.0248.8426-6.564-3.8748-12.94-9.8029-13.6686-3.8364-.4714-7.7363-.4367-11.609-.5934-1.3658-.0552-1.3152.975-1.316,1.91Q735.0268,136.8844,735.0306,142.2181Z"
      />
      <path
        className={a}
        d="M279.0289,142.1672c0-4.7018-.016-9.4037.0125-14.1053.0065-1.0855-.4259-2.4726,1.0628-2.9394a3.2137,3.2137,0,0,1,3.6329,1.2818c1.6817,2.1669,3.2943,4.3873,4.939,6.5829q5.9656,7.9637,11.9421,15.9191a7.7282,7.7282,0,0,0,.94.8966,6.2457,6.2457,0,0,0,.2408-1.2086q.0234-9.6328.0117-19.2659,0-1.204,0-2.4082c.0013-1.5712,1.1879-1.9078,2.33-1.9337,1.1493-.0261,2.3377.2966,2.4225,1.8352.0379.686.0071,1.3758.0071,2.0639V155.72a17.2109,17.2109,0,0,1-.0139,2.0625c-.1849,1.5227-1.2014,2.4157-2.5866,1.8658a7.67,7.67,0,0,1-3.095-2.4249c-5.2538-6.92-10.4-13.9222-15.6046-20.88a6.6508,6.6508,0,0,0-1.2485-1.0228,11.1864,11.1864,0,0,0-.2358,1.6192c-.0171,6.5366-.0331,13.0734.0029,19.61.0084,1.5321-.3364,2.7466-1.9824,3.1529-1.7.42-2.7314-.4312-2.761-2.3982-.055-3.6689-.0165-7.3391-.0168-11.0088Q279.0286,144.2313,279.0289,142.1672Z"
      />
      <path
        className={a}
        d="M246.7631,142.4451c.0006-4.7008-.0245-9.4018.01-14.1024.021-2.8761.5127-3.3847,3.414-3.4075,5.5031-.0432,11.0074-.0554,16.5095.0279a4.2242,4.2242,0,0,1,2.7391.9466,2.754,2.754,0,0,1,.3049,2.517,2.7834,2.7834,0,0,1-2.1357,1.1421c-4.469.1053-8.9421.0232-13.4132.0659-2.6064.025-2.6809.0776-2.654,2.7622.02,2.0243.2639,4.0464.4081,6.0694,1.9342.0766,3.8678.1846,5.8027.2212,2.063.039,4.1314-.07,6.19.0338,2.0816.1046,2.6782.7241,2.6341,2.346-.0483,1.7764-.5015,2.2253-2.6484,2.2575-3.4388.0516-6.8815.0934-10.3175-.017-1.6888-.0543-2.1973.55-2.1237,2.1791.1136,2.5172.09,5.0454.008,7.565-.0479,1.4669.4459,2.0179,1.9608,1.9949,4.8143-.0731,9.6309-.06,14.4458-.0094,2.0076.0211,2.2956.4938,2.2534,2.4854-.0351,1.6558-.8983,2.1884-2.4,2.1848-6.1913-.0148-12.3828-.03-18.5738.0112-1.764.0118-2.4526-.7387-2.4336-2.4835C246.7974,152.3058,246.7626,147.3752,246.7631,142.4451Z"
      />
      <path
        className={a}
        d="M474.2711,159.7042c-2.8681,0-5.7371.0437-8.6041-.0124-2.6593-.052-3.2387-.5971-3.25-3.2635q-.0582-14.111-.0012-28.2223c.01-2.627.6183-3.2425,3.2455-3.2673,5.621-.0531,11.244-.073,16.8634.0356a4.2326,4.2326,0,0,1,2.9882,1.1551c1.1031,1.459-.1854,3.442-2.1586,3.47-4.4733.0646-8.9485-.0049-13.4225.0334-2.6814.0229-2.6963.0677-2.7524,2.6774-.0321,1.4907.0684,2.9873-.0243,4.4729-.0965,1.55.6167,1.9582,2.0373,1.9309,3.3258-.0638,6.656-.0877,9.9792.0271a4.1687,4.1687,0,0,1,2.7527.9726c1.1913,1.345.0021,3.5527-1.8927,3.5978q-5.3317.127-10.6682.0015c-1.5577-.0373-2.3213.3069-2.2255,2.0746a72.29,72.29,0,0,1,.0028,7.5683c-.0886,1.7448.6465,2.1189,2.2211,2.0915,4.5876-.08,9.1776-.0122,13.7665-.0367,1.4576-.0078,2.7692-.09,2.68,2.05-.09,2.1668-.3013,2.5755-2.5895,2.6267C480.238,159.7543,477.254,159.704,474.2711,159.7042Z"
      />
      <path
        className={a}
        d="M425.7237,142.2673c-.0007-4.9281.026-9.8565-.0171-14.7844-.0127-1.4536.3018-2.4864,1.9913-2.5562,1.64-.0678,2.6277.6518,2.6894,2.3957.1134,3.2051.1248,6.42.0021,9.6246-.07,1.8311.6162,2.3609,2.37,2.3193q6.1854-.1467,12.3761.0019c1.69.0418,2.176-.5659,2.1246-2.1854-.1018-3.2058-.0631-6.4176-.0162-9.626.0255-1.7442.8926-2.6274,2.4363-2.4913,1.3626.12,2.2981.6311,2.2879,2.344-.059,9.9706-.0085,19.9419-.0452,29.9127-.0075,2.0148-.6,2.5366-2.3709,2.5279-1.7588-.0087-2.2767-.51-2.3065-2.5835-.0528-3.6667-.0574-7.3355.0053-11.0019.0253-1.48-.1987-2.3484-2.0232-2.3-4.2375.1133-8.4815.1-12.72.0056-1.7262-.0383-2.1486.6834-2.1083,2.2509.0825,3.2069.0264,6.4174.0218,9.6265a9.63,9.63,0,0,1-.0618,2.0537,2.3543,2.3543,0,0,1-2.7968,1.9355c-1.454-.1173-1.8647-.9883-1.854-2.3417C425.7483,152.3531,425.7244,147.31,425.7237,142.2673Z"
      />
      <path
        className={a}
        d="M612.6088,142.276c0,4.9331.0249,9.8665-.0113,14.7994-.0153,2.0805-.5893,2.7035-2.1955,2.6679-1.56-.0346-2.4431-.8261-2.487-2.6379q-.1336-5.5035,0-11.0123c.0379-1.6069-.4532-2.2565-2.141-2.2211q-6.3643.1336-12.7332.0019c-1.5966-.0312-1.915.6873-1.8931,2.0743q.0867,5.5053.0047,11.013c-.03,2.2178-.6421,2.8306-2.4988,2.7875-1.72-.04-2.2584-.5619-2.2658-2.6609q-.0523-14.7993-.0006-29.5988c.0057-1.7441.9186-2.5851,2.46-2.5444,1.5314.04,2.2795.8414,2.3052,2.6813.0448,3.2117.07,6.4258-.0095,9.6361-.0365,1.4663.4312,2.0268,1.95,1.9992,4.2434-.0774,8.4909-.0986,12.7331.0077,1.7591.0441,2.1388-.7038,2.0853-2.2538-.087-2.5209-.0352-5.0471-.0153-7.5709a16.5091,16.5091,0,0,1,.1628-2.3876,2.2216,2.2216,0,0,1,2.5793-2.1369c1.6895.0946,2.0029,1.0963,1.9888,2.557C612.5788,132.4094,612.6084,137.3428,612.6088,142.276Z"
      />
      <path
        className={a}
        d="M770.2785,142.2244c0-4.701-.0211-9.4022.008-14.103.0169-2.7169.0528-2.7769,2.61-2.7932q8.943-.0571,17.8866,0c2.1072.0123,3.0313,1.3727,2.1738,3.336a2.4322,2.4322,0,0,1-1.88.914c-4.3556.0809-8.7134.0326-13.0705.0489-2.9464.011-2.9492.0276-2.9771,3.0034q-.0146,1.5479,0,3.0958c.0288,3.3211.0312,3.33,3.5178,3.3474,2.981.0146,5.9641.0679,8.9426-.02,1.62-.0479,2.2251.513,2.2118,2.1681-.0134,1.6728-.6843,2.1776-2.2721,2.1411-3.4379-.0788-6.8816.0587-10.3176-.0564-1.7134-.0573-2.1792.5923-2.1116,2.1971.1059,2.5181.091,5.0459.0059,7.5655-.05,1.4846.4849,1.9975,1.9755,1.9753,4.7-.07,9.4018-.01,14.1027-.0327,1.397-.0067,2.49.1938,2.5825,1.956.1032,1.9739-.4851,2.66-2.9662,2.7159-3.5527.08-7.1085.0209-10.6629.0208-2.2932,0-4.5871.0367-6.8793-.011-2.6928-.0562-2.8529-.2159-2.8712-3.0217C770.2558,151.8559,770.2785,147.04,770.2785,142.2244Z"
      />
      <path
        className={a}
        d="M705.5479,138.0737c0,2.98.0359,5.9608-.0065,8.94-.1417,9.9679-6.9358,13.9673-15.3852,13.1558-8.4893-.8153-12.1694-5.3053-12.2207-13.9432-.0361-6.0748.0747-12.1518-.0628-18.2237-.0483-2.1306.3489-3.0332,2.1874-3.0674,1.7987-.0333,2.5845.8692,2.6039,3.208.0446,5.3868.0115,10.7742.017,16.1614.0012,1.2605-.0253,2.5246.0568,3.7808.2423,3.7122,2.2764,6.0109,5.8356,7.1107a11.167,11.167,0,0,0,6.7156-.0959c3.3274-.9935,5.3917-3.7735,5.46-7.8171.1022-6.0732.0379-12.1492.053-18.224a9.591,9.591,0,0,1,.1138-2.3882c.39-1.4732,1.5622-1.8643,2.9525-1.7163,1.2515.1333,1.713.8841,1.7006,2.1153-.0367,3.6675-.0132,7.3356-.0132,11.0035Z"
      />
      <path
        className={a}
        d="M373.1107,158.0455c-.5294.549-1.0313,1.4937-1.5164,1.4852-1.0383-.0184-2.4533-.2164-3.0154-.9084a17.2958,17.2958,0,0,1-2.0755-4.28c-1.9921-4.7347-1.98-4.74-7.14-4.74-2.75,0-5.5023.0765-8.2478-.03a2.6,2.6,0,0,0-2.855,1.8614c-.7236,1.7969-1.58,3.54-2.3759,5.3079a15.0841,15.0841,0,0,1-.8424,1.8717,2.9869,2.9869,0,0,1-3.6525.8859c-1.3258-.7218-1.112-1.8139-.5964-2.9521q2.8939-6.3875,5.7738-12.7815c2.4412-5.4034,4.9051-10.7968,7.3108-16.2161.5774-1.3006,1.0873-2.6032,2.7834-2.5951,1.6388.0079,2.3072,1.2007,2.8955,2.5112q6.1629,13.7271,12.356,27.4406C372.3281,155.829,372.6389,156.7993,373.1107,158.0455ZM356.9048,145.32q2.0574,0,4.1149.0006c1.1214.0021,1.3845-.4086.88-1.47-1.1226-2.36-2.151-4.7646-3.2233-7.1486-.4206-.9353-.8078-1.89-1.2993-2.7875-.4177-.7624-.8332-.9672-1.2977.0825-1.4267,3.2242-2.94,6.41-4.4072,9.6163-.5182,1.1327-.19,1.7325,1.118,1.7116C354.1612,145.3032,355.5331,145.32,356.9048,145.32Z"
      />
      <path
        className={a}
        d="M520.999,159.6693a5.8474,5.8474,0,0,1-1.6263-1.5589,68.1328,68.1328,0,0,1-2.8147-6.2412c-.6213-1.5328-1.4221-2.3974-3.298-2.3147-3.6653.1616-7.3444.1084-11.0147.0193a2.6051,2.6051,0,0,0-2.97,2.1055,48.4768,48.4768,0,0,1-2.8945,6.2118c-.8694,1.72-2.0743,2.2252-3.553,1.63-1.3378-.5388-1.5867-1.5385-.7341-3.431q6.3315-14.0535,12.699-28.0907a10.73,10.73,0,0,0,.5764-1.2438A2.3076,2.3076,0,0,1,508,125.039a3.4634,3.4634,0,0,1,2.1732,1.7532c2.5394,5.3665,4.9372,10.8,7.3782,16.2131,2.0648,4.5789,4.1685,9.141,6.17,13.7473C524.637,158.8591,523.9147,159.81,520.999,159.6693Zm-13.2928-14.1218.028-.2268c1.3655,0,2.731.0062,4.0965-.0021.8826-.0054,1.6657-.081,1.11-1.3315-1.5169-3.4109-2.9762-6.8478-4.5325-10.24-.4566-.9952-.962-.6431-1.3234.1823-.9086,2.0749-1.8682,4.1279-2.7514,6.2133-.6546,1.5454-1.2214,3.1279-1.8274,4.6939Z"
      />
      <path
        className={a}
        d="M531.2438,142.0774c0-4.5851-.0117-9.17.0045-13.7555.0089-2.5149.5829-3.3294,2.2865-3.3753,1.73-.0467,2.403.816,2.4077,3.2428.0152,7.9094-.0016,15.8189.01,23.7283.0043,3.0811.0232,3.09,3.2185,3.0988,4.0121.01,8.0244.0314,12.036-.0105,1.5629-.0162,2.7161.2449,2.7213,2.18.005,1.93-.46,2.4867-2.7525,2.504q-8.5966.0649-17.1942-.0017c-2.45-.0195-2.7051-.2991-2.7254-2.8238C531.2159,151.9358,531.244,147.0065,531.2438,142.0774Z"
      />
      <path
        className={a}
        d="M380.2941,142.3782c0-4.926-.0242-9.8522.0114-14.7779.0152-2.0952.5676-2.7,2.1775-2.6711a2.2834,2.2834,0,0,1,2.52,2.5892c.0791,4.9247.0277,9.8515.0279,14.7775,0,3.4368-.0267,6.8738.0094,10.31.0246,2.3506.0634,2.3872,2.5256,2.4046,4.2384.03,8.4776-.0245,12.7157.0221,2.1607.0237,2.58.4569,2.6554,2.2377.0637,1.5-.4639,2.4356-2.1222,2.4338-6.0716-.0064-12.1433-.02-18.2147.01-1.7972.0088-2.3554-.8466-2.3307-2.5582C380.34,152.2309,380.2941,147.3042,380.2941,142.3782Z"
      />
      <path
        className={a}
        d="M325.1045,144.4587c0-3.8971.0185-7.7943-.0064-11.6912-.02-3.0913-.0436-3.1039-3.0185-3.14-2.0628-.025-4.1282-.0649-6.1887.0067-1.6635.0578-1.7924-.9129-1.81-2.1987-.0181-1.3465.2626-2.1474,1.8658-2.1373q11.3469.071,22.6945.0034c1.6168-.0084,1.8435.8647,1.8487,2.1837.0054,1.3645-.3481,2.1987-1.9121,2.147-1.7174-.0567-3.4382-.0116-5.1575-.0091-3.9947.0057-4.0009.0061-4.0032,3.8569q-.0071,11.6913,0,23.3825c.0007,1.1459.1744,2.3557-1.3046,2.8083-1.7861.5467-2.97-.3749-2.994-2.4895C325.0709,152.941,325.1048,148.7,325.1045,144.4587Z"
      />
      <path
        className={a}
        d="M563.495,144.1293c0-3.0942.01-6.1884-.0022-9.2826-.0231-5.8608.581-5.1874-5.0615-5.2227-1.3752-.0087-2.7527-.0562-4.125.0059-1.6493.0747-1.797-.9077-1.8162-2.2-.02-1.3585.2838-2.14,1.8751-2.13q11.345.0683,22.6905,0c1.6419-.0094,1.8143.8927,1.8238,2.1929.01,1.3453-.2774,2.2467-1.8764,2.1351a17.5208,17.5208,0,0,0-2.4053.0039c-2.1288.1454-4.2548.3324-6.3819.5028-.13,2.1436-.3574,4.2865-.3729,6.4309-.0489,6.7611-.0212,13.5227-.0157,20.2841.0009,1.1482.1672,2.3588-1.2964,2.8231-1.7692.5613-2.9942-.378-3.02-2.4788C563.458,152.8394,563.4951,148.4841,563.495,144.1293Z"
      />
      <path
        className={a}
        d="M715.0436,142.2889c0-4.807-.0261-9.6143.0107-14.4211.0184-2.4018.5707-2.9181,2.799-2.4823.5694.1114,1.06.8664,1.4751,1.4106.167.2189.0383.6661.0384,1.0094q.001,14.5929.0115,29.1858c.0028,1.3494-.0456,2.5507-1.7841,2.7431-1.7173.19-2.5193-.5644-2.5372-2.681C715.0154,152.1322,715.0437,147.2105,715.0436,142.2889Z"
      />
      <path
        className={a}
        d="M211.3546,85.6175a4.9532,4.9532,0,0,1,4.904,5.0769,5.0385,5.0385,0,1,1-10.0748-.2031A5.2046,5.2046,0,0,1,211.3546,85.6175Z"
      />
      <path
        className={a}
        d="M217.7551,35.124a4.9279,4.9279,0,0,1-4.7025-4.9208,5.4843,5.4843,0,0,1,5.0783-5.1232c2.3606.0427,4.9354,2.6276,4.8878,4.9068A5.0544,5.0544,0,0,1,217.7551,35.124Z"
      />
      <path
        className={a}
        d="M211.7322,55.1628a4.8143,4.8143,0,0,1-4.9468-4.6052A5.2814,5.2814,0,0,1,212.2,45.5143a4.9056,4.9056,0,0,1,4.7784,4.84A5.1848,5.1848,0,0,1,211.7322,55.1628Z"
      />
      <path
        className={a}
        d="M247.7333,88.4447c2.79.0077,4.5351,1.8562,4.5739,4.8457.04,3.0523-1.8174,5.1289-4.6476,5.1971a5.0215,5.0215,0,0,1,.0737-10.0428Z"
      />
      <path
        className={a}
        d="M258.1894,34.3356a5.0026,5.0026,0,0,1-10.0034-.0074,4.8162,4.8162,0,0,1,4.815-4.7766A4.9072,4.9072,0,0,1,258.1894,34.3356Z"
      />
      <path
        className={a}
        d="M260.559,84.27a4.58,4.58,0,0,1-5.1714,4.5888,4.8565,4.8565,0,0,1-4.54-5.0077c.0978-2.9852,2.3231-4.8757,5.5011-4.6735C259.1389,79.3552,260.7073,81.2521,260.559,84.27Z"
      />
      <path
        className={a}
        d="M215.0034,39.0351a5.0145,5.0145,0,0,1-5.0514,4.9567,4.7339,4.7339,0,0,1-4.626-4.9386,4.9052,4.9052,0,0,1,4.67-4.9685A4.8411,4.8411,0,0,1,215.0034,39.0351Z"
      />
      <path
        className={a}
        d="M240.4537,96.9567a5.1155,5.1155,0,0,1-5.139,4.9151,4.7449,4.7449,0,0,1-4.5563-5.0008,5.0908,5.0908,0,0,1,5.0782-4.9434A4.9289,4.9289,0,0,1,240.4537,96.9567Z"
      />
      <path
        className={a}
        d="M254.2125,77.2963c-2.9514-.0485-4.7219-1.7894-4.704-4.6256a4.9634,4.9634,0,0,1,4.8864-5.0745c2.8558-.0507,4.82,1.9985,4.8,5.0075C259.1744,75.6381,257.359,77.348,254.2125,77.2963Z"
      />
      <path
        className={a}
        d="M250.1116,64.9551a5.0285,5.0285,0,0,1-4.9753,5.04,4.7791,4.7791,0,0,1-4.7034-4.8664,4.8415,4.8415,0,1,1,9.6787-.1732Z"
      />
      <path
        className={a}
        d="M230.352,22.2105a4.95,4.95,0,0,1,4.5245,5.43c-.354,2.8144-2.5409,4.4228-5.6509,4.1562a4.7015,4.7015,0,0,1-4.2128-5.2342A5.3,5.3,0,0,1,230.352,22.2105Z"
      />
      <path
        className={a}
        d="M221.5365,52.9735c2.7371-.3359,4.7809,2.1686,4.8919,4.6024a4.9431,4.9431,0,0,1-4.9814,5.01,5.1991,5.1991,0,0,1-4.9244-4.7632A4.7216,4.7216,0,0,1,221.5365,52.9735Z"
      />
      <path
        className={a}
        d="M227.4006,95.7077a4.803,4.803,0,0,1-9.6045.1489c-.0266-2.7214,2.2783-5.2826,4.7938-5.3269A4.97,4.97,0,0,1,227.4006,95.7077Z"
      />
      <path
        className={a}
        d="M434.07,62.4462c.3762,2.6173-2.5437,4.8662-4.7376,4.83a4.9761,4.9761,0,0,1-4.85-4.82,5.0863,5.0863,0,0,1,5.0124-5.0256A4.8939,4.8939,0,0,1,434.07,62.4462Z"
      />
      <path
        className={a}
        d="M667.6778,28.4005a4.4453,4.4453,0,0,1-4.7206,4.73,4.512,4.512,0,0,1-4.8136-4.67,4.9726,4.9726,0,0,1,4.83-4.8544A4.5339,4.5339,0,0,1,667.6778,28.4005Z"
      />
      <path
        className={a}
        d="M494.0361,62.3547a5.0381,5.0381,0,0,1,4.7512-4.9119,4.9327,4.9327,0,0,1-.0453,9.8643A4.9987,4.9987,0,0,1,494.0361,62.3547Z"
      />
      <path
        className={a}
        d="M312.7035,57.3061a5.1281,5.1281,0,0,1,4.8053,5.2023,4.7689,4.7689,0,0,1-5.1021,4.42,4.376,4.376,0,0,1-4.4495-4.89A4.64,4.64,0,0,1,312.7035,57.3061Z"
      />
      <path
        className={a}
        d="M622.2675,95.3435a4.7823,4.7823,0,1,1,9.5469.4049,4.7783,4.7783,0,1,1-9.5469-.4049Z"
      />
      <path
        className={a}
        d="M434.0639,84.6845a4.7954,4.7954,0,0,1-9.5744.5122,4.9443,4.9443,0,0,1,4.7622-5.1944A4.5914,4.5914,0,0,1,434.0639,84.6845Z"
      />
      <path
        className={a}
        d="M503.6208,28.7884a4.8079,4.8079,0,1,1-9.5882-.5867,4.9554,4.9554,0,0,1,5.0634-4.5271A5.0065,5.0065,0,0,1,503.6208,28.7884Z"
      />
      <path
        className={a}
        d="M532.3516,66.86a4.318,4.318,0,0,1-4.6274-4.6852,4.7773,4.7773,0,1,1,9.5432.2162C537.2257,65.0957,535.2613,66.8816,532.3516,66.86Z"
      />
      <path
        className={a}
        d="M398.1987,95.482a4.7957,4.7957,0,1,1-9.59.1361,4.8912,4.8912,0,0,1,4.8819-5.0574A4.9555,4.9555,0,0,1,398.1987,95.482Z"
      />
      <path
        className={a}
        d="M359.713,89.6164a4.5346,4.5346,0,0,1-4.7728-4.6585,4.9442,4.9442,0,0,1,5.01-4.9355,4.8,4.8,0,0,1-.2373,9.594Z"
      />
      <path
        className={a}
        d="M499.0016,68.9075a4.504,4.504,0,0,1,4.6159,4.8188,4.821,4.821,0,0,1-4.7642,4.7913,4.9564,4.9564,0,0,1-4.8166-4.766A4.5514,4.5514,0,0,1,499.0016,68.9075Z"
      />
      <path
        className={a}
        d="M429.249,68.9082a4.4778,4.4778,0,0,1,4.8172,4.6127,4.5982,4.5982,0,0,1-4.8341,4.9962,4.98,4.98,0,0,1-4.7442-4.8771A4.5543,4.5543,0,0,1,429.249,68.9082Z"
      />
      <path
        className={a}
        d="M525.7765,28.4957A4.3385,4.3385,0,0,1,521.12,33.154a4.6719,4.6719,0,0,1-4.8417-4.9525,4.8907,4.8907,0,0,1,4.9818-4.5974C523.9663,23.6384,525.7924,25.6161,525.7765,28.4957Z"
      />
      <path
        className={a}
        d="M359.723,33.16c-2.89-.0032-4.7311-1.7529-4.7848-4.5485a4.995,4.995,0,0,1,4.6477-4.9363,4.8819,4.8819,0,0,1,4.918,4.982C364.4832,31.4808,362.6972,33.1629,359.723,33.16Z"
      />
      <path
        className={a}
        d="M246.6083,28.009a4.9227,4.9227,0,0,1-4.4687,5.1292,4.5847,4.5847,0,0,1-5.1164-4.7265,4.8983,4.8983,0,0,1,4.6335-5.0014A4.7066,4.7066,0,0,1,246.6083,28.009Z"
      />
      <path
        className={a}
        d="M767.0709,88.9543a4.7913,4.7913,0,1,1,.0687,9.5788,4.7434,4.7434,0,0,1-4.78-4.7242A4.8672,4.8672,0,0,1,767.0709,88.9543Z"
      />
      <path
        className={a}
        d="M782.0348,88.1456a4.5526,4.5526,0,0,1-4.6843,4.835,4.8469,4.8469,0,0,1-4.78-4.4761,4.9337,4.9337,0,0,1,4.8622-5.1274A4.3147,4.3147,0,0,1,782.0348,88.1456Z"
      />
      <path
        className={a}
        d="M727.497,84.6139a4.3842,4.3842,0,0,1,4.7418-4.5931,4.9037,4.9037,0,0,1,4.7,4.8961,4.7769,4.7769,0,0,1-4.7854,4.7018C729.2177,89.6423,727.4483,87.7407,727.497,84.6139Z"
      />
      <path
        className={a}
        d="M667.6924,84.9174a4.4938,4.4938,0,0,1-4.7723,4.6955,4.8066,4.8066,0,1,1,.2625-9.6089C665.9265,80.0211,667.7532,82.0114,667.6924,84.9174Z"
      />
      <path
        className={a}
        d="M503.617,84.7337a4.7953,4.7953,0,0,1-9.5766.447,4.9279,4.9279,0,0,1,4.756-5.179A4.8379,4.8379,0,0,1,503.617,84.7337Z"
      />
      <path
        className={a}
        d="M609.0708,95.35a4.8574,4.8574,0,0,1-4.4655,5.0436c-2.4142.0726-4.9254-2.4341-5.0463-5.0374a5.0356,5.0356,0,0,1,4.8051-4.7968A4.7983,4.7983,0,0,1,609.0708,95.35Z"
      />
      <path
        className={a}
        d="M789.0174,61.3207a4.74,4.74,0,0,1-4.907-4.58,4.6241,4.6241,0,0,1,4.4139-5.0389,4.9672,4.9672,0,0,1,5.1208,4.803A4.5569,4.5569,0,0,1,789.0174,61.3207Z"
      />
      <path
        className={a}
        d="M615.5417,90.4937c2.2826-.09,4.8578,2.2863,5.045,4.6562a5.1062,5.1062,0,0,1-4.7638,5.23,4.9458,4.9458,0,0,1-4.7765-4.7855C610.9106,92.5845,613.1635,90.9441,615.5417,90.4937Z"
      />
      <path
        className={a}
        d="M238.4283,60.9488a5.1639,5.1639,0,0,1-5.1748,4.8719,4.6867,4.6867,0,0,1-4.48-4.7134c.04-2.9895,2.1795-5.1961,4.888-5.04A4.9493,4.9493,0,0,1,238.4283,60.9488Z"
      />
      <path
        className={a}
        d="M434.064,95.4944a4.8968,4.8968,0,0,1-4.6111,4.96,4.9845,4.9845,0,0,1-4.9713-4.9887,4.4022,4.4022,0,0,1,4.484-4.5442C432.0827,90.8018,433.996,92.5178,434.064,95.4944Z"
      />
      <path
        className={a}
        d="M429.5547,35.28a4.8886,4.8886,0,0,1,4.5093,5.0315,4.39,4.39,0,0,1-4.81,4.5434,4.4775,4.4775,0,0,1-4.7689-4.6395A5.0809,5.0809,0,0,1,429.5547,35.28Z"
      />
      <path
        className={a}
        d="M334.6536,46.387A4.9047,4.9047,0,0,1,339.5,51.1749a4.649,4.649,0,0,1-4.8156,4.716,4.3569,4.3569,0,0,1-4.69-4.3432C329.8676,48.4814,331.6992,46.4524,334.6536,46.387Z"
      />
      <path
        className={a}
        d="M568.2289,68.8271a4.6041,4.6041,0,0,1,4.9849,4.7871A4.95,4.95,0,0,1,568.46,78.45a5.1013,5.1013,0,0,1-4.8257-5.1794A4.4839,4.4839,0,0,1,568.2289,68.8271Z"
      />
      <path
        className={a}
        d="M359.777,68.915a4.4935,4.4935,0,0,1,4.72,4.6834,4.79,4.79,0,0,1-4.6081,4.9142,4.9411,4.9411,0,0,1-4.9494-4.991A4.56,4.56,0,0,1,359.777,68.915Z"
      />
      <path
        className={a}
        d="M283.9631,73.6484a4.6073,4.6073,0,0,1-4.68,4.8031,5.2554,5.2554,0,0,1-4.9028-4.7927,4.8092,4.8092,0,0,1,5.0562-4.8352A4.3844,4.3844,0,0,1,283.9631,73.6484Z"
      />
      <path
        className={a}
        d="M568.57,90.9229a4.4055,4.4055,0,0,1,4.6409,4.6955,4.9365,4.9365,0,0,1-4.7135,4.84,5.0927,5.0927,0,0,1-4.8637-5.1322C563.685,92.6276,565.6509,90.8737,568.57,90.9229Z"
      />
      <path
        className={a}
        d="M537.2725,28.4246a4.51,4.51,0,0,1-4.6773,4.73c-2.9168.0874-4.8074-1.6284-4.87-4.42a4.78,4.78,0,1,1,9.5473-.3108Z"
      />
      <path
        className={a}
        d="M467.7409,28.62c-.0423,2.6979-2.0086,4.5266-4.8769,4.5353a4.3355,4.3355,0,0,1-4.6956-4.6145,4.79,4.79,0,1,1,9.5725.0792Z"
      />
      <path
        className={a}
        d="M434.0705,28.7644a4.9528,4.9528,0,0,1-4.8577,4.7522,4.9186,4.9186,0,0,1,.3375-9.8314A4.9936,4.9936,0,0,1,434.0705,28.7644Z"
      />
      <path
        className={a}
        d="M498.9619,90.9221a4.3863,4.3863,0,0,1,4.6543,4.6886,4.7944,4.7944,0,1,1-9.5784-.26C494.1251,92.5725,496,90.8858,498.9619,90.9221Z"
      />
      <path
        className={a}
        d="M521.1756,57.2968a4.5215,4.5215,0,0,1,4.6,4.8062c.0274,2.951-1.6633,4.5325-4.5361,4.8071-2.689.257-4.8586-2.2933-4.9433-4.5738A4.8061,4.8061,0,0,1,521.1756,57.2968Z"
      />
      <path
        className={a}
        d="M283.9656,39.9287a4.5669,4.5669,0,0,1-4.6524,4.8721,4.9812,4.9812,0,0,1-4.9311-4.7322,4.9178,4.9178,0,0,1,4.79-4.8491A4.5556,4.5556,0,0,1,283.9656,39.9287Z"
      />
      <path
        className={a}
        d="M604.02,66.859a4.7851,4.7851,0,0,1,.4867-9.5566c3.0226.1281,4.8647,2.401,4.56,5.6267C608.8276,65.4714,606.8059,67.0455,604.02,66.859Z"
      />
      <path
        className={a}
        d="M615.58,66.8607c-2.8631-.0481-4.5986-1.9393-4.5108-4.8651.0859-2.8623,1.74-4.501,4.842-4.6575a4.7606,4.7606,0,0,1,4.68,4.9376C620.2668,65.2525,617.8718,67.2684,615.58,66.8607Z"
      />
      <path
        className={a}
        d="M631.8227,62.3043a4.4474,4.4474,0,0,1-4.8862,4.5549A4.531,4.531,0,0,1,622.27,62.05a5.0522,5.0522,0,0,1,4.9421-4.7482A4.961,4.961,0,0,1,631.8227,62.3043Z"
      />
      <path
        className={a}
        d="M364.4992,40.254a4.78,4.78,0,0,1-9.5566-.1509,5.0631,5.0631,0,0,1,4.865-4.8158A4.94,4.94,0,0,1,364.4992,40.254Z"
      />
      <path
        className={a}
        d="M642.7576,62.2134a4.531,4.531,0,0,1-4.84,4.645,4.584,4.584,0,0,1-4.7339-4.793,4.9828,4.9828,0,0,1,4.8785-4.7629A4.8833,4.8833,0,0,1,642.7576,62.2134Z"
      />
      <path
        className={a}
        d="M498.8392,44.8544a4.3946,4.3946,0,0,1-4.8029-4.5535,5.0786,5.0786,0,0,1,4.6227-5.02,4.92,4.92,0,0,1,4.9584,4.9594A4.4543,4.4543,0,0,1,498.8392,44.8544Z"
      />
      <path
        className={a}
        d="M283.9645,28.519a4.4011,4.4011,0,0,1-4.7926,4.61,4.99,4.99,0,0,1-4.777-4.477,5.0346,5.0346,0,0,1,4.994-5.0462A4.5811,4.5811,0,0,1,283.9645,28.519Z"
      />
      <path
        className={a}
        d="M631.8143,28.4082a4.7744,4.7744,0,0,1-9.5457.1287,5.0306,5.0306,0,0,1,4.7394-4.9287A4.8469,4.8469,0,0,1,631.8143,28.4082Z"
      />
      <path
        className={a}
        d="M467.7331,62.3375a4.7857,4.7857,0,0,1-9.5626-.1416,4.7853,4.7853,0,1,1,9.5626.1416Z"
      />
      <path
        className={a}
        d="M375.4992,28.3179a4.5128,4.5128,0,0,1-4.61,4.8256,4.7208,4.7208,0,0,1-4.9859-4.5562,5.0526,5.0526,0,0,1,4.6808-4.982A4.9517,4.9517,0,0,1,375.4992,28.3179Z"
      />
      <path
        className={a}
        d="M456.22,62.2443c-.0448,2.9533-1.7266,4.6193-4.66,4.616a4.5929,4.5929,0,0,1-4.7349-4.6867,4.7261,4.7261,0,0,1,4.9831-4.875C454.588,57.3964,456.2653,59.2767,456.22,62.2443Z"
      />
      <path
        className={a}
        d="M354.94,95.5538c.0014-2.7713,1.9291-4.6147,4.8388-4.6272a4.3944,4.3944,0,0,1,4.7234,4.687,4.782,4.782,0,1,1-9.5622-.06Z"
      />
      <path
        className={a}
        d="M642.7564,95.5959a4.9329,4.9329,0,0,1-4.8336,4.7457,4.9925,4.9925,0,0,1-4.7387-5.2272,4.8334,4.8334,0,0,1,5-4.537A4.9524,4.9524,0,0,1,642.7564,95.5959Z"
      />
      <path
        className={a}
        d="M568.2981,44.7933a5.02,5.02,0,0,1-4.6631-5.3222,4.5343,4.5343,0,0,1,4.8674-4.2513,4.897,4.897,0,0,1,4.6616,4.9491A4.54,4.54,0,0,1,568.2981,44.7933Z"
      />
      <path
        className={a}
        d="M334.5259,68.8175a4.9664,4.9664,0,0,1,5.085,4.8048,5.2572,5.2572,0,0,1-4.85,4.8274,5.0174,5.0174,0,0,1-4.7546-4.8371A4.3468,4.3468,0,0,1,334.5259,68.8175Z"
      />
      <path
        className={a}
        d="M377.4977,62.3112c-.0391-3.0207,1.5958-4.8833,4.3975-5.01a4.9141,4.9141,0,0,1,5.0066,4.6417A4.7836,4.7836,0,0,1,382.24,66.86C379.26,66.9524,377.5363,65.2994,377.4977,62.3112Z"
      />
      <path
        className={a}
        d="M662.9126,35.2207a4.5255,4.5255,0,0,1,4.7653,4.7179,4.497,4.497,0,0,1-4.6016,4.8562,4.9389,4.9389,0,0,1-4.8847-4.7486A4.792,4.792,0,0,1,662.9126,35.2207Z"
      />
      <path
        className={a}
        d="M375.5057,95.35a4.7959,4.7959,0,1,1-9.5878.1693,4.9367,4.9367,0,0,1,4.684-4.9731A5.0654,5.0654,0,0,1,375.5057,95.35Z"
      />
      <path
        className={a}
        d="M784.7888,40.6072c2.9282.0145,4.7431,1.7769,4.7815,4.643a4.7942,4.7942,0,0,1-4.7658,4.8222,4.9387,4.9387,0,0,1-4.813-4.8578C779.9874,42.3793,781.8478,40.5926,784.7888,40.6072Z"
      />
      <path
        className={a}
        d="M440.316,33.1551a4.7143,4.7143,0,0,1-4.8431-5.0492,5.0423,5.0423,0,0,1,4.79-4.4978,4.9332,4.9332,0,0,1,4.8144,4.8138A4.5877,4.5877,0,0,1,440.316,33.1551Z"
      />
      <path
        className={a}
        d="M364.5,51.2968a4.78,4.78,0,1,1-9.5586.1525,4.9034,4.9034,0,0,1,4.7095-4.9287A4.8336,4.8336,0,0,1,364.5,51.2968Z"
      />
      <path
        className={a}
        d="M429.2278,55.9521c-2.8956-.0173-4.7883-1.8473-4.7488-4.5916a4.9315,4.9315,0,0,1,4.7463-4.85,4.7384,4.7384,0,0,1,4.84,4.998C434.0139,54.3271,432.2256,55.97,429.2278,55.9521Z"
      />
      <path
        className={a}
        d="M789.57,79.4076a4.4893,4.4893,0,0,1-4.8068,4.6661,4.5976,4.5976,0,0,1-4.7748-4.7544,4.9369,4.9369,0,0,1,4.8215-4.8222A5.0011,5.0011,0,0,1,789.57,79.4076Z"
      />
      <path
        className={a}
        d="M573.1615,51.2541c.0371,2.7477-1.8942,4.611-4.8031,4.6342a4.557,4.557,0,0,1-4.7225-4.7407,4.7155,4.7155,0,0,1,4.7775-4.7637A4.8563,4.8563,0,0,1,573.1615,51.2541Z"
      />
      <path
        className={a}
        d="M294.728,62.45c-.0491,2.78-2.0335,4.5695-4.97,4.4831a4.5305,4.5305,0,0,1-4.5362-4.94,4.9787,4.9787,0,0,1,4.9627-4.6818A5.0366,5.0366,0,0,1,294.728,62.45Z"
      />
      <path
        className={a}
        d="M279.307,89.6141a5.0109,5.0109,0,0,1-4.9256-4.7255A5.1646,5.1646,0,0,1,279.244,80.01a4.59,4.59,0,0,1,4.7181,4.7951A4.5134,4.5134,0,0,1,279.307,89.6141Z"
      />
      <path
        className={a}
        d="M334.94,89.6082a4.6508,4.6508,0,0,1-4.9337-4.6106,4.5932,4.5932,0,0,1,4.5175-4.9894,5.104,5.104,0,0,1,5.0952,4.6248A5.03,5.03,0,0,1,334.94,89.6082Z"
      />
      <path
        className={a}
        d="M283.95,62.4348a4.3844,4.3844,0,0,1-4.9127,4.4966,4.7979,4.7979,0,0,1-4.65-4.607,5.2131,5.2131,0,0,1,4.7325-5.0219A4.7934,4.7934,0,0,1,283.95,62.4348Z"
      />
      <path
        className={a}
        d="M509.9689,33.155a4.6477,4.6477,0,0,1-4.9317-4.9257,4.7988,4.7988,0,0,1,9.5937.0864A4.6311,4.6311,0,0,1,509.9689,33.155Z"
      />
      <path
        className={a}
        d="M662.9833,46.3839A4.8383,4.8383,0,0,1,667.6757,51.6a4.3084,4.3084,0,0,1-4.7034,4.2913,4.7978,4.7978,0,0,1-4.7906-5.0818A4.8484,4.8484,0,0,1,662.9833,46.3839Z"
      />
      <path
        className={a}
        d="M573.2134,84.788a4.6366,4.6366,0,0,1-4.7113,4.8241,5.0362,5.0362,0,0,1-4.8673-5.1262,4.9462,4.9462,0,0,1,4.7729-4.4811A4.8355,4.8355,0,0,1,573.2134,84.788Z"
      />
      <path
        className={a}
        d="M593.22,89.6132a4.8135,4.8135,0,0,1-4.589-4.6272,4.9009,4.9009,0,0,1,5.04-4.9666c2.7122.1025,4.4888,2.129,4.4306,5.0537A4.4148,4.4148,0,0,1,593.22,89.6132Z"
      />
      <path
        className={a}
        d="M543.4121,33.1552a4.3542,4.3542,0,0,1-4.71-4.6816,4.6693,4.6693,0,0,1,4.6558-4.87,5.076,5.076,0,0,1,4.9089,4.8108A4.9251,4.9251,0,0,1,543.4121,33.1552Z"
      />
      <path
        className={a}
        d="M306.0037,62.2308a4.4081,4.4081,0,0,1-4.7392,4.6977c-3.5385-.0738-4.5951-2.0876-4.7818-4.705a4.7629,4.7629,0,1,1,9.521.0073Z"
      />
      <path
        className={a}
        d="M573.1621,28.4212a4.7828,4.7828,0,0,1-9.5634.0443,4.9776,4.9776,0,0,1,4.7706-4.8593A4.8811,4.8811,0,0,1,573.1621,28.4212Z"
      />
      <path
        className={a}
        d="M473.8623,33.1543a4.382,4.382,0,0,1-4.7152-4.691,4.8862,4.8862,0,0,1,4.7292-4.8584c2.4674-.0385,5.002,2.3885,4.8467,4.8452C478.564,30.9633,476.6837,33.42,473.8623,33.1543Z"
      />
      <path
        className={a}
        d="M456.22,28.2905c.0639,2.9675-1.6417,4.8119-4.4971,4.8633a4.5544,4.5544,0,0,1-4.8863-4.59,4.6976,4.6976,0,0,1,4.6185-4.9539A4.4376,4.4376,0,0,1,456.22,28.2905Z"
      />
      <path
        className={a}
        d="M283.9669,51.2072a4.3832,4.3832,0,0,1-4.72,4.6846,4.739,4.739,0,0,1-4.8448-4.6932,4.9283,4.9283,0,0,1,4.8413-4.8132A4.6675,4.6675,0,0,1,283.9669,51.2072Z"
      />
      <path
        className={a}
        d="M404.3534,33.1436a4.3834,4.3834,0,0,1-4.7612-4.6329,4.9019,4.9019,0,0,1,4.6717-4.9039c2.428-.0775,5.06,2.3463,4.8865,4.7844C408.9376,31.3839,406.8208,33.3858,404.3534,33.1436Z"
      />
      <path
        className={a}
        d="M339.5038,62.3034a4.6966,4.6966,0,0,1-4.9176,4.6381,4.5036,4.5036,0,0,1-4.5964-4.8664,4.8987,4.8987,0,0,1,4.8066-4.7776A5.1626,5.1626,0,0,1,339.5038,62.3034Z"
      />
      <path
        className={a}
        d="M736.9478,40.2523a4.9045,4.9045,0,0,1-4.9649,4.6429c-2.7531-.2318-4.471-1.7868-4.5021-4.5372-.0343-3.02,1.6572-4.9779,4.3875-5.0781A5.0379,5.0379,0,0,1,736.9478,40.2523Z"
      />
      <path
        className={a}
        d="M393.4331,33.1424a4.4269,4.4269,0,0,1-4.8247-4.59,5.0475,5.0475,0,0,1,4.7461-4.9443,4.9006,4.9006,0,0,1,4.84,4.81A4.5376,4.5376,0,0,1,393.4331,33.1424Z"
      />
      <path
        className={a}
        d="M609.0712,28.5622a4.3024,4.3024,0,0,1-4.7442,4.5817,4.9855,4.9855,0,0,1-4.7643-4.8386c-.06-2.342,2.4305-4.7047,4.9556-4.702A4.7905,4.7905,0,0,1,609.0712,28.5622Z"
      />
      <path
        className={a}
        d="M398.1957,62.4254a4.5672,4.5672,0,0,1-5.044,4.4346,4.5,4.5,0,0,1-4.538-4.92,4.7979,4.7979,0,1,1,9.582.4853Z"
      />
      <path
        className={a}
        d="M382.0545,23.6044A4.99,4.99,0,0,1,386.9,28.3951a4.88,4.88,0,0,1-4.8295,4.749c-2.9062.0348-4.5867-1.6945-4.5722-4.7053C377.5125,25.4855,379.2741,23.6164,382.0545,23.6044Z"
      />
      <path
        className={a}
        d="M615.7314,33.1443a4.3459,4.3459,0,0,1-4.68-4.6951,4.6824,4.6824,0,0,1,4.7063-4.8454,4.9435,4.9435,0,0,1,4.8359,4.8185A4.9291,4.9291,0,0,1,615.7314,33.1443Z"
      />
      <path
        className={a}
        d="M334.5121,90.9245a4.6681,4.6681,0,0,1,4.9868,4.842,4.9376,4.9376,0,0,1-4.5812,4.6911,5.03,5.03,0,0,1-4.9193-5.0666A4.2862,4.2862,0,0,1,334.5121,90.9245Z"
      />
      <path
        className={a}
        d="M445.0791,62.2013a4.4653,4.4653,0,0,1-4.4507,4.6567,4.7546,4.7546,0,0,1-5.1308-4.7751,4.935,4.935,0,0,1,4.83-4.7827A4.9968,4.9968,0,0,1,445.0791,62.2013Z"
      />
      <path
        className={a}
        d="M339.4961,28.3585a4.834,4.834,0,0,1-4.8065,4.7679,4.3361,4.3361,0,0,1-4.6961-4.6466A4.8,4.8,0,0,1,335,23.6083,4.8755,4.8755,0,0,1,339.4961,28.3585Z"
      />
      <path
        className={a}
        d="M642.7569,28.6251a4.5037,4.5037,0,0,1-4.926,4.5179,4.6766,4.6766,0,0,1-4.6478-4.8848,4.9723,4.9723,0,0,1,4.97-4.65A4.9012,4.9012,0,0,1,642.7569,28.6251Z"
      />
      <path
        className={a}
        d="M364.5035,62.2608a4.7831,4.7831,0,1,1-9.5617.2535c-.0917-2.9169,1.8779-4.7553,5.028-4.6932C362.7518,57.876,364.5132,59.6009,364.5035,62.2608Z"
      />
      <path
        className={a}
        d="M514.6322,62.409a4.52,4.52,0,0,1-5.0164,4.45,4.662,4.662,0,0,1-4.576-4.9534,4.938,4.938,0,0,1,5.006-4.6023A5.0721,5.0721,0,0,1,514.6322,62.409Z"
      />
      <path
        className={a}
        d="M334.5805,44.7961a4.62,4.62,0,0,1-4.5853-4.9076,4.4357,4.4357,0,0,1,4.7695-4.6648,4.9191,4.9191,0,0,1,4.7316,4.8953A4.8335,4.8335,0,0,1,334.5805,44.7961Z"
      />
      <path
        className={a}
        d="M748.1846,28.331a4.5567,4.5567,0,0,1-4.6589,4.81,4.6607,4.6607,0,0,1-4.9076-4.5926,4.999,4.999,0,0,1,4.7145-4.9416A4.8646,4.8646,0,0,1,748.1846,28.331Z"
      />
      <path
        className={a}
        d="M771.78,31.0084a4.4468,4.4468,0,0,1-4.6942,4.697,4.9883,4.9883,0,0,1-4.7285-4.9137,4.833,4.833,0,0,1,4.955-4.6129C770.19,26.2,771.8163,27.9578,771.78,31.0084Z"
      />
      <path
        className={a}
        d="M782.0342,36.6418a4.5947,4.5947,0,0,1-4.7712,4.7258,4.9863,4.9863,0,0,1-4.6777-4.9773,4.9123,4.9123,0,0,1,4.9045-4.6639A4.5188,4.5188,0,0,1,782.0342,36.6418Z"
      />
      <path
        className={a}
        d="M686.1268,90.9174a4.8392,4.8392,0,0,1,4.8526,4.7072,5.0641,5.0641,0,0,1-4.8682,4.8329,5.0063,5.0063,0,0,1-4.7316-4.9029A4.3618,4.3618,0,0,1,686.1268,90.9174Z"
      />
      <path
        className={a}
        d="M573.1635,62.22a4.5578,4.5578,0,0,1-4.7729,4.7079,4.6121,4.6121,0,0,1-4.7542-4.7637,4.99,4.99,0,0,1,4.7524-4.8652A5.1253,5.1253,0,0,1,573.1635,62.22Z"
      />
      <path
        className={a}
        d="M708.821,90.9184a4.5917,4.5917,0,0,1,4.7639,4.7281,5.1171,5.1171,0,0,1-4.9,4.8159,4.71,4.71,0,0,1-4.6041-4.9532C704.1026,92.5568,705.8239,90.89,708.821,90.9184Z"
      />
      <path
        className={a}
        d="M598.1042,40.2868a4.3065,4.3065,0,0,1-4.777,4.5646,4.8122,4.8122,0,0,1-4.7074-4.4891,4.9731,4.9731,0,0,1,4.935-5.0745A4.7186,4.7186,0,0,1,598.1042,40.2868Z"
      />
      <path
        className={a}
        d="M743.7018,90.9155a4.3672,4.3672,0,0,1,4.4851,4.5313,4.7869,4.7869,0,1,1-9.56-.2343C738.7964,92.452,740.7072,90.8342,743.7018,90.9155Z"
      />
      <path
        className={a}
        d="M760.0442,28.3261c.0352,2.9962-1.6386,4.7884-4.496,4.8139a4.8368,4.8368,0,0,1-4.9821-4.5869,5.366,5.366,0,0,1,5.1644-4.9436C758.2682,23.643,760.0117,25.5492,760.0442,28.3261Z"
      />
      <path
        className={a}
        d="M588.6323,95.389a4.8173,4.8173,0,0,1,5.0559-4.5033c2.8423.2561,4.53,1.9705,4.4171,4.9374a4.78,4.78,0,0,1-4.913,4.6327A4.8865,4.8865,0,0,1,588.6323,95.389Z"
      />
      <path
        className={a}
        d="M283.9633,95.5532a4.757,4.757,0,0,1-4.6593,4.9126,5.1319,5.1319,0,0,1-4.9028-4.8209,4.9573,4.9573,0,0,1,4.8474-4.7206A4.3011,4.3011,0,0,1,283.9633,95.5532Z"
      />
      <path
        className={a}
        d="M498.8029,55.9515c-2.94-.002-4.8272-1.8318-4.7687-4.6236a4.8823,4.8823,0,0,1,4.8349-4.8186,4.7624,4.7624,0,0,1,4.7485,4.8619C503.6262,54.2035,501.7865,55.9536,498.8029,55.9515Z"
      />
      <path
        className={a}
        d="M667.6925,73.6247a4.7112,4.7112,0,0,1-4.35,4.8218,5.087,5.087,0,0,1-5.1448-4.9444,4.945,4.945,0,0,1,4.9329-4.6857C665.9625,68.8185,667.7389,70.691,667.6925,73.6247Z"
      />
      <path
        className={a}
        d="M404.3019,57.3a5.1267,5.1267,0,0,1,4.8944,4.8218,5.0532,5.0532,0,0,1-5.2292,4.7358c-2.6754-.1-4.4839-2.19-4.3785-5.0589A4.8732,4.8732,0,0,1,404.3019,57.3Z"
      />
      <path
        className={a}
        d="M788.7767,73.0545a4.889,4.889,0,0,1-4.6626-4.9263,4.4718,4.4718,0,0,1,4.75-4.6948,4.9379,4.9379,0,0,1,4.7808,4.8223A5.03,5.03,0,0,1,788.7767,73.0545Z"
      />
      <path
        className={a}
        d="M667.6822,62.2571a4.4059,4.4059,0,0,1-4.7029,4.6767,4.9247,4.9247,0,0,1-4.7981-4.8188,4.7531,4.7531,0,1,1,9.501.1421Z"
      />
      <path
        className={a}
        d="M663.0472,90.9252c2.9837-.0336,4.655,1.63,4.6465,4.6248a4.6948,4.6948,0,0,1-4.6149,4.9131c-2.3034.0835-4.7277-2.3375-4.8684-4.8616A4.766,4.766,0,0,1,663.0472,90.9252Z"
      />
      <path
        className={a}
        d="M593.4571,33.1586a4.6554,4.6554,0,0,1-4.8546-4.9625,5.1329,5.1329,0,0,1,4.8029-4.5036,4.8665,4.8665,0,0,1,4.7,4.8348C598.1173,31.485,596.4288,33.1672,593.4571,33.1586Z"
      />
      <path
        className={a}
        d="M736.8948,73.8478a4.7637,4.7637,0,0,1-4.9111,4.67,4.62,4.62,0,0,1-4.4868-5.0111,4.4444,4.4444,0,0,1,4.818-4.5961A4.6807,4.6807,0,0,1,736.8948,73.8478Z"
      />
      <path
        className={a}
        d="M598.1017,73.5144a4.6061,4.6061,0,0,1-4.4912,4.9992,4.8229,4.8229,0,0,1-4.9329-4.6709,4.7057,4.7057,0,0,1,4.611-4.9276A4.4106,4.4106,0,0,1,598.1017,73.5144Z"
      />
      <path
        className={a}
        d="M370.723,66.86a4.9985,4.9985,0,0,1-4.8152-4.7632,4.7946,4.7946,0,1,1,9.5884.09A4.5448,4.5448,0,0,1,370.723,66.86Z"
      />
      <path
        className={a}
        d="M674.7507,90.9177c2.9661-.0237,4.648,1.6431,4.6561,4.6139.0078,2.914-1.8413,4.9288-4.5264,4.9319a5.4554,5.4554,0,0,1-4.9424-4.8564A4.7244,4.7244,0,0,1,674.7507,90.9177Z"
      />
      <path
        className={a}
        d="M593.4574,55.9557c-2.8717.2206-4.5464-1.3968-4.8-4.5489-.1983-2.4674,2.3252-4.91,4.7347-4.8921a4.7224,4.7224,0,0,1,4.7126,4.799C598.1238,54.22,596.3983,55.9433,593.4574,55.9557Z"
      />
      <path
        className={a}
        d="M328.062,62.2469c.4938,2.7091-2.2658,4.8606-4.5812,4.7232a4.4574,4.4574,0,0,1-4.5411-4.8467,4.5456,4.5456,0,0,1,4.6219-4.8209C326.227,57.3569,328.0378,59.3463,328.062,62.2469Z"
      />
      <path
        className={a}
        d="M697.6731,90.9183c2.9122.0457,4.5772,1.8136,4.5007,4.7785a4.7783,4.7783,0,0,1-4.7619,4.7452,5.3064,5.3064,0,0,1-4.6992-5.0188C692.7746,92.985,695.058,90.4732,697.6731,90.9183Z"
      />
      <path
        className={a}
        d="M399.5909,95.5209a4.2673,4.2673,0,0,1,4.2907-4.7,4.9312,4.9312,0,0,1,5.3162,4.62,5.4782,5.4782,0,0,1-4.8446,4.8988A4.812,4.812,0,0,1,399.5909,95.5209Z"
      />
      <path
        className={a}
        d="M727.4969,95.5032a4.1555,4.1555,0,0,1,4.3264-4.57,4.8179,4.8179,0,0,1,5.1255,4.7057,5.3989,5.3989,0,0,1-4.8975,4.82A4.7506,4.7506,0,0,1,727.4969,95.5032Z"
      />
      <path
        className={a}
        d="M593.5023,57.82c2.9869.0021,4.6251,1.6483,4.6042,4.6266a4.7945,4.7945,0,0,1-4.7177,4.7964c-2.35.0212-4.9056-2.4877-4.741-4.89C588.8081,60.0134,590.3332,57.4647,593.5023,57.82Z"
      />
      <path
        className={a}
        d="M377.4979,95.3855c.0257-3.0258,1.6957-4.6461,4.7125-4.5723,2.6853.0658,4.354,1.887,4.4771,4.6109a4.8319,4.8319,0,0,1-4.6833,4.942C379.3665,100.472,377.4734,98.2668,377.4979,95.3855Z"
      />
      <path
        className={a}
        d="M736.6241,28.6065c-.0295,2.9525-1.755,4.6078-4.7421,4.5491a4.1266,4.1266,0,0,1-4.4025-4.5148c0-2.885,1.8732-4.9866,4.5278-4.9435C734.3486,23.7353,737.06,26.0326,736.6241,28.6065Z"
      />
      <path
        className={a}
        d="M731.9377,55.9562c-2.9252-.05-4.5683-1.8305-4.4574-4.83.1046-2.828,1.7526-4.4081,4.5747-4.386,3.02.0235,4.6261,1.6616,4.6028,4.6938C736.6344,54.483,735.0413,56.0094,731.9377,55.9562Z"
      />
      <path
        className={a}
        d="M760.0432,95.6983c-.057,2.9582-1.776,4.5124-4.8416,4.3776a4.2592,4.2592,0,0,1-4.345-4.6339c.0152-2.8922,1.7637-4.5594,4.746-4.5257C758.5267,90.9494,760.102,92.6458,760.0432,95.6983Z"
      />
      <path
        className={a}
        d="M732.1015,57.8227c2.9505-.0052,4.596,1.6761,4.5571,4.6563a4.5892,4.5892,0,0,1-9.1777-.0441C727.4505,59.5129,729.1386,57.8279,732.1015,57.8227Z"
      />
    </svg>
  );
};

export default SMHGLogo;
