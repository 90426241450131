import { headingSizes } from '@frontend/styles/variables';
import { css } from '@emotion/css';

const headingStyles = {
  xl: css`
    font-size: ${headingSizes.xl};
  `,
  lg: css`
    font-size: ${headingSizes.lg};
  `,
  md: css`
    font-size: ${headingSizes.md};
  `,
  sm: css`
    font-size: ${headingSizes.sm};
  `,
  xs: css`
    font-size: ${headingSizes.xs};
  `,
  xxs: css`
    font-size: 1rem;
  `,
};

export default headingStyles;
