import { RainyDayResource } from '@common/types/apiResources';
import AsideBox from '@frontend/components/AsideBox';
import ButtonLink from '@frontend/components/ButtonLink';
import Column from '@frontend/components/grid/Column';
import Row from '@frontend/components/grid/Row';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import Page from '@frontend/components/Page';
import { useUserContext } from '@frontend/context/UserContext';
import useAsyncRetry from '@frontend/hooks/useAsyncRetry';
import GratitudesForm from '@frontend/pages/rainy-day/components/GratitudesForm';
import HelpfulWordsForm from '@frontend/pages/rainy-day/components/HelpfulWordsForm';
import RainyDayFavourites from '@frontend/pages/rainy-day/components/RainyDayFavourites';
import rainyDayService from '@frontend/services/rainyDayService';
import flexStyles from '@frontend/styles/flex';
import spacingStyles from '@frontend/styles/spacings';
import { cx } from '@emotion/css';
import React from 'react';
import MediaReferenceForm from '@frontend/pages/rainy-day/components/MediaReferenceForm';
import HelpfulDocumentsForm from '@frontend/pages/rainy-day/components/HelpfulDocumentsForm';
import { IoIosJournal, IoMdRainy } from 'react-icons/all';
import { spacings } from '@frontend/styles/variables';

const RainyDayPage = () => {
  const { user } = useUserContext();

  const { value: rainyDay, setValue: setRainyDay, isLoading } = useAsyncRetry<
    RainyDayResource | undefined
  >(async () => {
    if (!user) {
      return undefined;
    }

    const { data } = await rainyDayService.find({
      query: {
        $limit: 1,
        userId: user?.id,
        $eager: 'mediaReferences',
      },
    });

    if (data.length === 1) {
      return data[0];
    }

    return undefined;
  }, [user]);

  return (
    <Page
      title="Rainy day"
      titleIcon={IoMdRainy}
      intro="Some days can feel too much, it can help to have some resources you can go back to on these days. Have a think and add what might be useful to you below."
      aside={
        <AsideBox>
          <RainyDayFavourites />
        </AsideBox>
      }
      mobileAsidePosition="top"
      easyreadPdf="My Toolkit - Rainy day"
    >
      <LoadingSpinner size="xl" loading={isLoading} center>
        <div className={spacingStyles.marginBottom.xl}>
          <HelpfulWordsForm rainyDay={rainyDay} onSubmit={setRainyDay} />
        </div>

        <Row className={spacingStyles.marginBottom.lg}>
          <Column size={12}>
            <MediaReferenceForm
              userId={user?.id ?? ''}
              rainyDay={rainyDay}
              onChange={setRainyDay}
            />
          </Column>
        </Row>

        <Row className={spacingStyles.marginBottom.lg}>
          <Column size={6}>
            <HelpfulDocumentsForm
              userId={user?.id ?? ''}
              rainyDay={rainyDay}
              onChange={setRainyDay}
            />
          </Column>
          <Column size={6}>
            <GratitudesForm rainyDay={rainyDay} onSubmit={setRainyDay} />
          </Column>
        </Row>

        <div className={cx(flexStyles.flex, flexStyles.justifyCenter)}>
          <ButtonLink to="/journal" colour="secondary" inverted>
            See your journal
            <IoIosJournal
              className={spacingStyles.marginLeft.xs}
              size={spacings.md}
            />
          </ButtonLink>
        </div>
      </LoadingSpinner>
    </Page>
  );
};

export default RainyDayPage;
