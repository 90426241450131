import AsideBox from '@frontend/components/AsideBox';
import Link from '@frontend/components/Link';
import Page from '@frontend/components/Page';
import Separator from '@frontend/components/Separator';
import FavouriteCard from '@frontend/pages/favourites/components/FavouriteCard';
import FavouriteFiltersForm, {
  FavouriteFiltersFormValues,
} from '@frontend/pages/favourites/components/FavouriteFiltersForm';
import FavouriteLinksGroup from '@frontend/pages/favourites/components/FavouriteLinksGroup';
import FavouritesGroup from '@frontend/pages/favourites/components/FavouritesGroup';
import MentalHealthGuideAside from '@frontend/pages/favourites/components/MentalHealthGuideAside';
import spacingStyles from '@frontend/styles/spacings';
import React, { useState } from 'react';
import { FaConciergeBell, FaHeart } from 'react-icons/fa';
import { MdLocalActivity } from 'react-icons/all';
import FavouriteNotFoundCard from './components/FavouriteNotFoundCard';

const FavouritesPage = () => {
  const [filters, setFilters] = useState<FavouriteFiltersFormValues>({
    showServices: true,
    showActivities: true,
    showLinks: true,
  });

  return (
    <Page
      title="Favourites"
      titleIcon={FaHeart}
      intro={
        <p>
          ’Favourite’ the support services and activities listed on the
          Sheffield Mental Health Guide and see them appear below. ‘Favourited’
          services/activities can then be added to your{' '}
          <Link to="/toolkit">‘toolkit’</Link>. Can't find what you are looking
          for? You can add links to your favourite services/activities that
          aren't listed on the Sheffield Mental Health Guide in the 'links'
          section below.
        </p>
      }
      aside={
        <>
          <AsideBox>
            <FavouriteFiltersForm onSubmit={setFilters} />
          </AsideBox>

          <MentalHealthGuideAside />
        </>
      }
      mobileAsidePosition="top"
      easyreadPdf="My Toolkit - Favourites"
    >
      {filters.showServices && (
        <>
          <FavouritesGroup
            title="Services"
            titleIcon={FaConciergeBell}
            addPageLink="/favourites/services"
            type="service"
            introduction="Add services below. This could be a service you already go to, or one you would like to contact or go to."
          >
            {({ item, handleDelete }) => {
              const service = item.data;

              if (!service) {
                return (
                  <FavouriteNotFoundCard id={item.id} onDelete={handleDelete} />
                );
              }

              return (
                <FavouriteCard
                  id={item.id}
                  title={service.title}
                  url={service.url}
                  onDelete={handleDelete}
                >
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: service.excerpt,
                    }}
                  />
                </FavouriteCard>
              );
            }}
          </FavouritesGroup>
          <Separator
            variant="dashed"
            className={spacingStyles.marginBottom.lg}
          />
        </>
      )}
      {filters.showActivities && (
        <>
          <FavouritesGroup
            title="Activities"
            titleIcon={MdLocalActivity}
            addPageLink="/favourites/activities"
            type="activity"
            introduction="Add activities below. This could be an activity you already go to, or one you would like to go to."
          >
            {({ item, handleDelete }) => {
              const activity = item.data;

              if (!activity) {
                return (
                  <FavouriteNotFoundCard id={item.id} onDelete={handleDelete} />
                );
              }

              return (
                <FavouriteCard
                  id={item.id}
                  title={activity.title}
                  url={activity.url}
                  onDelete={handleDelete}
                >
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: activity.excerpt,
                    }}
                  />
                </FavouriteCard>
              );
            }}
          </FavouritesGroup>
          <Separator
            variant="dashed"
            className={spacingStyles.marginBottom.lg}
          />
        </>
      )}
      {filters.showLinks && <FavouriteLinksGroup />}
    </Page>
  );
};

export default FavouritesPage;
