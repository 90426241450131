import { columnStyle, pageStyle } from '@frontend/components/Page';
import PageLink from '@frontend/components/PageLink';
import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
  children: ReactNode;
  title: string;
}

const ErrorPage = ({ title, children }: Props) => {
  return (
    <div className={pageStyle}>
      <div className={columnStyle}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <h1>{title}</h1>

        {children}

        <PageLink to="/">Back to Home</PageLink>
      </div>
    </div>
  );
};

export default ErrorPage;
