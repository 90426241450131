import {
  MediaReferenceResource,
  RainyDayResource,
} from '@common/types/apiResources';
import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@emotion/react';
import DismissButton from '@frontend/components/DismissButton';
import { themedInputStyle } from '@frontend/components/form/FormInput';
import Link from '@frontend/components/Link';
import FileUploadButton from '@frontend/pages/rainy-day/components/FileUploadButton';
import documentService from '@frontend/services/documentService';
import mediaReferenceService, {
  updateRainyDayMediaReferences,
  uploadReferencedFile,
} from '@frontend/services/mediaReferenceService';
import flexStyles from '@frontend/styles/flex';
import headingStyles from '@frontend/styles/headings';
import tryAsyncToast from '@frontend/utils/tryAsyncToast';
import { last } from 'lodash';
import React from 'react';

interface Props {
  userId: string;
  rainyDay: RainyDayResource | undefined;
  onChange: (rainyDay: RainyDayResource) => void;
}

const textAlignLeft = css`
  text-align: left;
`;

interface DocumentItemProps {
  document: MediaReferenceResource;
  theme: Theme;
  deleteDocument: (mediaReference: MediaReferenceResource) => Promise<void>;
}

const DocumentItem = ({
  document,
  theme,
  deleteDocument,
}: DocumentItemProps) => {
  const [isDeleting, setIsDeleting] = React.useState(false);

  return (
    <div key={document.id}>
      <div className={cx(themedInputStyle(theme), flexStyles.flex)}>
        <Link
          aria-label={
            document.title?.length > 0 ? document.title : document.url
          }
          to={document.url}
          className={cx(
            textAlignLeft,
            css`
              flex-grow: 1;
              overflow: hidden;
              white-space: nowrap;
            `,
          )}
          target="_blank"
        >
          {document.title}
        </Link>
        <DismissButton
          disabled={isDeleting}
          color={theme.textContrast}
          onClick={async e => {
            setIsDeleting(true);
            e.preventDefault();
            await deleteDocument(document);
          }}
        >
          Delete
        </DismissButton>
      </div>
    </div>
  );
};

const HelpfulDocumentsForm = ({ userId, rainyDay, onChange }: Props) => {
  const documents = React.useMemo(() => {
    return rainyDay?.mediaReferences?.filter(mr => mr.type === 'document');
  }, [rainyDay]);

  const theme = useTheme();

  const addDocument = React.useCallback(
    async (mediaReference: MediaReferenceResource) => {
      const newRainyDay = await updateRainyDayMediaReferences(rainyDay, [
        ...(rainyDay?.mediaReferences ?? []),
        mediaReference,
      ]);
      onChange(newRainyDay);
    },
    [onChange, rainyDay],
  );

  const deleteDocument = React.useCallback(
    async (mediaReference: MediaReferenceResource) => {
      if (documents === undefined) return;

      await mediaReferenceService.remove(mediaReference.id);

      const parts = mediaReference.url.split('/');
      await documentService.remove(last(parts) as string);

      const newRainyDay = await updateRainyDayMediaReferences(
        rainyDay,
        rainyDay?.mediaReferences?.filter(mr => mr.id !== mediaReference.id),
      );
      onChange(newRainyDay);
    },
    [documents, onChange, rainyDay],
  );

  const onFileUpload = async (file: File) => {
    await tryAsyncToast(
      async () => {
        const newMediaReference = await uploadReferencedFile(
          file,
          'document',
          userId,
          documentService,
        );

        await addDocument(newMediaReference);
      },
      {
        success: 'document uploaded',
        error: err => {
          if (err.name === 'GeneralError' || err.name === 'BadRequest') {
            return err.message;
          }
          return 'An error occurred, could not upload file';
        },
      },
    );
  };

  return (
    <>
      <h2 className={headingStyles.sm}>Add files that you find helpful</h2>

      <p>
        It could be your favourite wellbeing resource, a care plan or something
        you enjoy writing for example.
      </p>

      <div className={cx(flexStyles.flex, flexStyles.column)}>
        {documents?.map(document => (
          <DocumentItem
            key={document.id}
            document={document}
            theme={theme}
            deleteDocument={deleteDocument}
          />
        ))}

        <div className={cx(flexStyles.flex, flexStyles.justifyCenter)}>
          <FileUploadButton
            onFileUpload={(url: File) => onFileUpload(url)}
            title="Add File"
          />
        </div>
      </div>
    </>
  );
};

export default HelpfulDocumentsForm;
