import { GoalResource } from '@common/types/apiResources';
import ButtonCard from '@frontend/components/ButtonCard';
import ModalButton from '@frontend/components/ModalButton';
import Separator from '@frontend/components/Separator';
import Tabs from '@frontend/components/Tabs';
import TabsPanel from '@frontend/components/TabsPanel';
import GoalAddForm from '@frontend/pages/toolkit/components/GoalAddForm';
import GoalFavouriteTable from '@frontend/pages/toolkit/components/GoalFavouriteTable';
import headingStyles from '@frontend/styles/headings';
import spacingStyles from '@frontend/styles/spacings';
import React, { ReactNode } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import Link from '@frontend/components/Link';
import { css } from '@emotion/css';
import NoItemsMessage from '@frontend/components/NoItemsMessage';
import GoalLinkTable from './GoalLinkTable';

interface Props {
  children: ReactNode;
  categoryId: number;
  onCreate: (
    goal: Partial<GoalResource>,
    options?: { createLink: boolean },
  ) => void;
}

type SelectedTab = 'services-tab' | 'activities-tab' | 'links-tab' | '';

const tabPriority = {
  'services-tab': 0,
  'activities-tab': 1,
  'links-tab': 2,
  '': 100,
};

const hide = css`
  display: none;
`;

const GoalAddButton = ({ children, categoryId, onCreate }: Props) => {
  const [hasFavourite, setHasFavourite] = React.useState(true);

  const selectedTabId = React.useRef<SelectedTab>('');

  const numberOfTablesWithoutItems = React.useRef<number>(0);

  const tableLoaded = React.useCallback(
    (tabName: SelectedTab, total: number) => {
      if (total === 0) {
        numberOfTablesWithoutItems.current += 1;
        if (numberOfTablesWithoutItems.current === 3) {
          setHasFavourite(false);
        }
        return;
      }

      if (tabPriority[tabName] < tabPriority[selectedTabId.current]) {
        selectedTabId.current = tabName;
      }
    },
    [],
  );

  return (
    <ModalButton
      aria-label="Add a new goal"
      button={
        <ButtonCard>
          <span className={spacingStyles.marginBottom.sm}>{children}</span>
          <FaPlusCircle className={headingStyles.xl} />
        </ButtonCard>
      }
    >
      {modal => (
        <>
          <h1 className={headingStyles.md}>Create a new goal</h1>

          <GoalAddForm
            categoryId={categoryId}
            onSubmit={async ({ name, link }) => {
              await onCreate(
                {
                  name,
                  link,
                  categoryId,
                },
                { createLink: true },
              );

              modal.hide();
            }}
          />

          <Separator variant="dashed" />

          <h1 className={headingStyles.md}>
            Or pick a goal from your favourites
          </h1>

          <Tabs
            aria-label="Favourite tabs"
            selectedTabId={selectedTabId.current}
            className={hasFavourite ? '' : hide}
          >
            <TabsPanel id="services-tab" title="Services">
              <GoalFavouriteTable
                type="service"
                onSelect={async favourite => {
                  await onCreate({
                    name: favourite.data?.title,
                    categoryId,
                  });

                  modal.hide();
                }}
                onTableLoad={({ total }) => tableLoaded('services-tab', total)}
              />
            </TabsPanel>
            <TabsPanel id="activities-tab" title="Activities">
              <GoalFavouriteTable
                type="activity"
                onSelect={async favourite => {
                  await onCreate({
                    name: favourite.data?.title,
                    categoryId,
                  });

                  modal.hide();
                }}
                onTableLoad={({ total }) =>
                  tableLoaded('activities-tab', total)
                }
              />
            </TabsPanel>
            <TabsPanel id="links-tab" title="Links">
              <GoalLinkTable
                onSelect={async ({ title, url }) => {
                  await onCreate(
                    {
                      name: title,
                      link: url,
                      categoryId,
                    },
                    { createLink: false },
                  );

                  modal.hide();
                }}
                onTableLoad={({ total }) => tableLoaded('links-tab', total)}
              />
            </TabsPanel>
          </Tabs>

          <div className={hasFavourite ? hide : ''}>
            <NoItemsMessage>
              <p>You do not have any favourites set</p>
              <p>
                You can do this in the <Link to="/favourites">Favourites</Link>{' '}
                section of the Toolkit.
              </p>
            </NoItemsMessage>
          </div>
        </>
      )}
    </ModalButton>
  );
};

export default GoalAddButton;
