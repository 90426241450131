import { JournalResource } from '@common/types/apiResources';
import { Query } from '@common/types/service';
import minDelay from '@common/utils/minDelay';
import { Paginated } from '@feathersjs/feathers';
import AsideBox from '@frontend/components/AsideBox';
import Column from '@frontend/components/grid/Column';
import Row from '@frontend/components/grid/Row';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import NoItemsMessage from '@frontend/components/NoItemsMessage';
import Page from '@frontend/components/Page';
import Pagination from '@frontend/components/Pagination';
import SortDropdown from '@frontend/components/SortDropdown';
import useAsyncRetry from '@frontend/hooks/useAsyncRetry';
import usePaginationQuery from '@frontend/hooks/usePaginationQuery';
import useQuery from '@frontend/hooks/useQuery';
import JournalAchievements from '@frontend/pages/journal/components/JournalAchievements';
import JournalFiltersForm, {
  FormValues,
} from '@frontend/pages/journal/components/JournalFiltersForm';
import JournalGratitudes, {
  JournalGratitudesProps,
} from '@frontend/pages/journal/components/JournalGratitudes';
import goalService from '@frontend/services/goalService';
import gratitudeService from '@frontend/services/gratitudeService';
import journalService from '@frontend/services/journalService';
import flexStyles from '@frontend/styles/flex';
import spacingStyles from '@frontend/styles/spacings';
import { endOfDay, startOfDay } from 'date-fns/esm';
import { cx } from '@emotion/css';
import produce from 'immer';
import React, { useCallback, useState } from 'react';
import { IoIosJournal } from 'react-icons/all';

const JournalPage = () => {
  const [filters, setFilters] = useState<FormValues>({
    startDate: null,
    endDate: null,
    showAchievements: true,
    showGratitudes: true,
  });
  const { startDate, endDate, showAchievements, showGratitudes } = filters;

  const { sort } = useQuery();
  const { $limit, $skip } = usePaginationQuery();

  const {
    value: journals,
    isLoading,
    setValue,
    retry,
  } = useAsyncRetry(async () => {
    const query: Query<{
      createdAt: string;
      achievements: string[];
      gratitudes: string[];
    }> = {
      $limit,
      $skip,
      $sort: {
        createdAt: sort === 'asc' ? 1 : -1,
      },
    };

    if (startDate || endDate) {
      query.createdAt = {};

      if (startDate) {
        query.createdAt.$gte = startOfDay(startDate).toISOString();
      }

      if (endDate) {
        query.createdAt.$lte = endOfDay(endDate).toISOString();
      }
    }

    if (!showAchievements) {
      query.achievements = '[]';
    }

    if (!showGratitudes) {
      query.gratitudes = '[]';
    }

    return minDelay(journalService.find({ query }), 400);
  }, [
    startDate,
    endDate,
    showAchievements,
    showGratitudes,
    sort,
    $limit,
    $skip,
  ]);

  const setJournals = useCallback(
    async (nextJournals: Paginated<JournalResource>) => {
      // Try and reduce requests by refreshing only when we know
      // there are journals that have no achievements/gratitudes
      if (
        nextJournals.data.length === 0 ||
        nextJournals.data.some(
          journal => !journal.achievements.length && !journal.gratitudes,
        )
      ) {
        await retry();
      } else {
        setValue(nextJournals);
      }
    },
    [retry, setValue],
  );

  const handleGratitudeDelete: JournalGratitudesProps['onDelete'] = useCallback(
    async (id, entry) => {
      const nextJournals = await produce(
        journals as Paginated<JournalResource>,
        async draft => {
          const matchingIndex = draft.data.findIndex(
            journal => journal.gratitudes?.id === id,
          );

          const matchingJournal = draft.data[matchingIndex];

          if (matchingJournal?.gratitudes) {
            matchingJournal.gratitudes[entry] = '';

            const { entry1, entry2, entry3 } = matchingJournal.gratitudes;

            if (!entry1 && !entry2 && !entry3) {
              await gratitudeService.remove(id);
              matchingJournal.gratitudes = undefined;

              return;
            }

            await gratitudeService.patch(id, {
              [entry]: '',
            });
          }
        },
      );

      await setJournals(nextJournals);
    },
    [journals, setJournals],
  );

  const handleAchievementDelete = useCallback(
    async id => {
      await goalService.remove(id);

      const nextJournals = produce(
        journals as Paginated<JournalResource>,
        draft => {
          draft.data.forEach(journal => {
            const found = journal.achievements.findIndex(
              achievement => achievement.id === id,
            );

            if (found > -1) {
              journal.achievements.splice(found, 1);
            }
          });
        },
      );

      await setJournals(nextJournals);
    },
    [journals, setJournals],
  );

  return (
    <Page
      title="Journal"
      titleIcon={IoIosJournal}
      intro="Here’s a summary of the positives you’ve recognised and goals you’ve achieved."
      aside={
        <AsideBox>
          <JournalFiltersForm initialValues={filters} onSubmit={setFilters} />
        </AsideBox>
      }
      mobileAsidePosition="top"
      easyreadPdf="My Toolkit - Journal"
    >
      <div className={cx(flexStyles.flex, flexStyles.justifyEnd)}>
        <SortDropdown
          aria-label="Sort journals"
          className={spacingStyles.marginBottom.md}
          options={[
            {
              value: 'desc',
              label: 'Sort by date (desc)',
            },
            {
              value: 'asc',
              label: 'Sort by date (asc)',
            },
          ]}
        />
      </div>

      <LoadingSpinner center size="xl" loading={isLoading}>
        {journals && (
          <>
            {journals.data.length > 0 ? (
              <Row className={spacingStyles.marginBottom.md}>
                {journals.data.map((journal, index) => {
                  const date = new Date(journal.createdAt);
                  const key = `${journal.createdAt}-${index}`;

                  return (
                    <React.Fragment key={key}>
                      {journal.gratitudes && (
                        <Column
                          className={spacingStyles.marginBottom.md}
                          size={6}
                          grow={false}
                        >
                          <JournalGratitudes
                            date={date}
                            gratitudes={journal.gratitudes}
                            onDelete={handleGratitudeDelete}
                          />
                        </Column>
                      )}

                      {journal.achievements.length > 0 && (
                        <Column
                          className={spacingStyles.marginBottom.md}
                          size={6}
                          grow={false}
                        >
                          <JournalAchievements
                            date={date}
                            achievements={journal.achievements}
                            onDelete={handleAchievementDelete}
                          />
                        </Column>
                      )}
                    </React.Fragment>
                  );
                })}
              </Row>
            ) : (
              <NoItemsMessage>No journal entries found</NoItemsMessage>
            )}

            <Pagination aria-label="Journal pages" {...journals} />
          </>
        )}
      </LoadingSpinner>
    </Page>
  );
};

export default JournalPage;
