import React from 'react';
import { useTheme } from '@emotion/react';
import { css } from '@emotion/css';
import { VisuallyHidden } from 'reakit';

interface Props {
  showTitle?: boolean;
  title?: string;
  url: string;
}

const LinkPreview = ({ url, title = 'Link', showTitle = true }: Props) => {
  const theme = useTheme();

  const themedLinkPreviewHeaderStyle = css`
    color: ${theme.primary};
    font-weight: bold;
  `;

  const themedLinkPreviewStyle = css`
    height: 2rem;
    position: relative;
    width: 100%;
    > a {
      max-width: 100%;
      overflow: hidden;
      position: absolute;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `;

  return (
    <dl className={themedLinkPreviewStyle} aria-label="Link preview">
      <dt className={themedLinkPreviewHeaderStyle}>
        {showTitle ? title : <VisuallyHidden>{title}</VisuallyHidden>}
      </dt>
      <dd className={themedLinkPreviewStyle}>
        <a href={url} target="blank" title={url} rel="noopener noreferrer">
          {url}
        </a>
      </dd>
    </dl>
  );
};

export default LinkPreview;
