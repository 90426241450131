import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@emotion/react';
import { spacings } from '@frontend/styles/variables';
import React from 'react';
import { Separator as BaseSeparator } from 'reakit';

interface Props {
  className?: string;
  colour?: keyof Theme;
  variant?: 'dashed' | 'dotted' | 'solid';
}

const style = css`
  margin-bottom: ${spacings.md};
  margin-top: ${spacings.md};
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Separator = ({
  className,
  colour = 'border1',
  variant = 'solid',
}: Props) => {
  const theme = useTheme();
  const themedStyle = css`
    ${style};
    border: 1px ${variant} ${theme[colour]};
  `;

  return (
    <BaseSeparator
      orientation="horizontal"
      className={cx(themedStyle, className)}
    />
  );
};

export default Separator;
